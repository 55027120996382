import _ from 'lodash';
import { createSelector } from 'reselect';
import appStore from '../../../mobx-stores/appStore';
import { mapToFinanceNavigatorResultQuote } from '~/components/FinanceNavigator/utils/financeNavigatorUtils';

export const getCustomer = (state) => state.entities.customers[state.ui.customer.Id];
export const getCustomerUi = (state) => state.ui.customer;

export const denormaliseCustomer = createSelector([getCustomer, getCustomerUi], (customer, customerUi) => {
  if (!customer) {
    return {};
  }

  let {
    AssignedTo,
    VehiclesOfInterest,
    CfcProspectResultSets,
    FinanceApplications,
    SavedQuotes,
    SelfServiceCodes,
    DealerNotes,
    PushDeals,
    FinanceScans,
    ...customerDetails
  } = customer;

  const vehicles = _.map(VehiclesOfInterest, (vehicle) => {
    const sortFilters = _.get(customerUi, `VehicleSortFilters[${vehicle.VehicleId}]`, {});

    const financeApplicationFilter = _.get(sortFilters, 'FinanceApplications', {});
    const savedQuickQuoteFilter = _.get(sortFilters, 'SavedQuotes', {});
    const cfcprospectsFilter = _.get(sortFilters, 'CfcProspects', {});
    const pushDealFilter = _.get(sortFilters, 'PushDeals', {});

    let financeApplications = _.chain(FinanceApplications)
      .filter({ VehicleId: vehicle.VehicleId })
      .orderBy(financeApplicationFilter.sortField, financeApplicationFilter.sortDirection)
      .map((application) =>
        Object.assign({}, application, {
          isSelfService: application.ClientApp === 'motonovo-fass-consumer'
        })
      )
      .value();

    let PushDeals =
      _.chain(customer.PushDeals)
        .filter({ VehicleId: vehicle.VehicleId })
        .orderBy(pushDealFilter.sortField, pushDealFilter.sortDirection)
        .value() || [];

    let cfcResults = _.chain(CfcProspectResultSets)
      .cloneDeep()
      .filter({ VehicleId: vehicle.VehicleId })
      .orderBy('Created', 'desc')
      .tap((cfcResults) => {
        _.each(cfcResults, (resultSet) => {
          resultSet.Quotes = _.orderBy(
            resultSet.Quotes,
            cfcprospectsFilter.sortField,
            cfcprospectsFilter.sortDirection
          );
        });
      })
      .value();

    let savedQuotes = _.chain(SavedQuotes)
      .filter({ VehicleId: vehicle.VehicleId })
      .orderBy(savedQuickQuoteFilter.sortField, savedQuickQuoteFilter.sortDirection)
      .value();

    let selfServiceCodes = _.chain(SelfServiceCodes)
      .cloneDeep()
      .filter({ VehicleId: vehicle.VehicleId })
      .orderBy('SavedDate', 'desc')
      .map((deal) => {
        let firstQuote = _.get(deal, 'Quotes[0]', {});
        return {
          CashPrice: firstQuote.CashPrice,
          CashDeposit: firstQuote.Deposit - firstQuote.PartExchange + firstQuote.OutstandingSettlement,
          PartExchange: firstQuote.PartExchange,
          OutstandingSettlement: firstQuote.OutstandingSettlement,
          BalancePayable: firstQuote.BalancePayable,
          ...deal
        };
      })
      .value();

    let financeNavigatorResults = _.chain(FinanceScans)
      .cloneDeep()
      .filter({ VehicleId: vehicle.VehicleId })
      .orderBy('CreatedDate', 'desc')
      .tap((financeScans) => {
        _.each(financeScans, (resultSet) => {
          resultSet.Results = resultSet.Results.map(mapToFinanceNavigatorResultQuote);
        });
      })
      .value();

    return {
      ...vehicle,
      FinanceApplications: financeApplications,
      CfcProspects: cfcResults,
      FinanceScans: financeNavigatorResults,
      SavedQuotes: savedQuotes,
      PushDeals: PushDeals,
      DealerNotes: _.filter(DealerNotes, { VehicleId: vehicle.VehicleId }),
      SortFilters: sortFilters,
      SelfServiceCodes: selfServiceCodes
    };
  });

  return {
    VehiclesOfInterest: vehicles,
    ...customerDetails,
    assignedTo: AssignedTo ? AssignedTo.map((user) => appStore.userStore.updateUserFromJSON(user)) : []
  };
});

export const currentVehicleTabIndex = createSelector([getCustomer, getCustomerUi], (customer, customerUi) => {
  if (!customer) {
    return 0;
  }

  let index = _.findIndex(customer.VehiclesOfInterest, { VehicleId: customerUi.selectedVehicleId });

  return index < 0 ? 0 : index;
});
