import { CSSProperties } from 'react';
import gaugeBackgroundMedium from '../../styles/icons/dial.svg';
import gaugeBackgroundHigh from '../../styles/icons/dial_high.svg';
import gaugeBackgroundLow from '../../styles/icons/dial_low.svg';
import {
  EligibilityCategories,
  EligibilityCategoriesType,
  EligibilityStatusPropsType
} from '../Quoting/modals/FinanceNavigatorEligibilityModal/types';
import { getEligibilityCategoryFromCfcMatchRate } from '~/core/decisionMessages';
import { getEligibilityCategoryFromFnStatus } from '../Quoting/modals/FinanceNavigatorEligibilityModal/utils';
import styles from './gauge.module.scss';

export const getGaugeImgFromEligibilityCategory = (eligibilityCategory: EligibilityCategoriesType): string => {
  switch (eligibilityCategory) {
    case EligibilityCategories.high:
      return gaugeBackgroundHigh;
    case EligibilityCategories.medium:
      return gaugeBackgroundMedium;
    default:
      return gaugeBackgroundLow;
  }
};

export const getAngleFromEligibilityCategory = (eligibilityCategory: EligibilityCategoriesType): number => {
  switch (eligibilityCategory) {
    case EligibilityCategories.low:
      return -75;
    case EligibilityCategories.medium:
      return 0;
    case EligibilityCategories.high:
      return 75;
    default:
      return 0;
  }
};

type GaugeProps = EligibilityStatusPropsType & {
  width?: number;
  height?: number;
};

const Gauge = ({ cfcMatchRate, fnEligibilityStatus, width = 160, height = 104 }: GaugeProps) => {
  const eligibilityCategory = cfcMatchRate
    ? getEligibilityCategoryFromCfcMatchRate(cfcMatchRate)
    : fnEligibilityStatus
    ? getEligibilityCategoryFromFnStatus(fnEligibilityStatus)
    : null;

  if (!eligibilityCategory) {
    return null;
  }
  const gaugeImg = getGaugeImgFromEligibilityCategory(eligibilityCategory);
  const angle = getAngleFromEligibilityCategory(eligibilityCategory);

  return (
    <div style={{ width, height }} data-th={`gauge-${eligibilityCategory}`}>
      <svg className={styles.gauge} width="100%" height="100%" viewBox="0 0 160 104">
        <image href={gaugeImg} width="100%" height="100%" />
        <g transform="translate(80, 52)">
          <g
            style={
              {
                '--needle': `${angle}deg`
              } as CSSProperties
            }
            className={styles.needle}
          >
            <polygon points="-3,10 3,10 0.5,-30 -0.5,-30" fill="#ccc" />
            <polygon points="-3,10 3,10 0.5,-30 -0.5,-30" fill="#000000" />
            <circle cx="0" cy="0" r="5" fill="black" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Gauge;
