import { useTranslation } from 'react-i18next';
import { Tag } from '~/components/Common/Tags';
import { DisplayQuoteFormat, QuoteCardVariantType, QuoteTypes } from '../../types';
import { useGetQuoteChecks } from '../../hooks';
import { CustomerFinanceApplication } from '~/types/application';

export type QuoteTagsProps = {
  quote: QuoteTypes;
  displayQuote: DisplayQuoteFormat;
  variant: QuoteCardVariantType;
  application?: CustomerFinanceApplication;
};
const QuoteTags = ({ quote, displayQuote, variant, application }: QuoteTagsProps) => {
  const { t } = useTranslation('QuoteCard');

  const {
    supportsPreApproval,
    isRequote,
    isFinanceApplication,
    isFnEligibilityQuote,
    isFnRateForRiskQuote,
    canShowEligibilityResult
  } = useGetQuoteChecks({ quote, variant, application });

  const canShowPreApprovalTags = supportsPreApproval && !isRequote && !isFinanceApplication;
  const canShowFnEligibilityTags = isFnEligibilityQuote && !isFinanceApplication && !canShowEligibilityResult;
  const canShowFnRateForRiskTags = isFnRateForRiskQuote && !isFinanceApplication && !canShowEligibilityResult;

  const hasTags = canShowFnEligibilityTags || canShowFnRateForRiskTags || canShowPreApprovalTags;

  return hasTags ? (
    <div className="quoteCard__tags">
      {canShowPreApprovalTags && (
        <>
          <Tag
            dataTh="quoteCard__tags--soft-search"
            text={t('QuoteCardTags.soft_check')}
            startIconName="information"
            level="info"
            bold={true}
          />
          <Tag
            dataTh="quoteCard__tags--pre-approval"
            text={t('QuoteCardTags.pre_approval_available')}
            startIconName="validation-tick"
            level="success"
          />
        </>
      )}
      {canShowFnRateForRiskTags && (
        <Tag
          dataTh="quoteCard__tags--soft-check-fn-r4r"
          text={t('QuoteCardTags.soft_check_fn_r4r')}
          startIconName="information"
          level="info"
          bold={true}
        />
      )}
      {canShowFnEligibilityTags && (
        <Tag
          dataTh="quoteCard__tags--soft-check-fn-eligibility"
          text={t('QuoteCardTags.soft_check_fn_eligibility')}
          startIconName="information"
          level="info"
          bold={true}
        />
      )}
    </div>
  ) : null;
};

export default QuoteTags;
