import { FeaturesReady } from '@growthbook/growthbook-react';
import { inject } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { AppStore } from '~/modules/Stock/types/Types';
import Card from '../Common/Card/Card';
import LoadingDots from '../Common/Loading/LoadingDots';
import Page from '../Common/Page';
import MonthlyFinanceConversion from './MonthlyFinanceConversion/MonthlyFinanceConversion';
import './homePage.scss';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

interface HomeProps {
  session?: any;
  appStore?: AppStore;
}

const HomePage = (props: HomeProps) => {
  const { dealershipId } = useParams();
  const { t } = useTranslation('Home');
  const { session, appStore } = props;
  const { canViewHomePage, countryCode } = appStore?.uiState || {};
  const isFindAVehiclePage = useFeatureIsOn('rtl-findavehicle-page');

  if (!canViewHomePage) return <Navigate to={`/d/${dealershipId}`} replace />;

  return (
    <FeaturesReady fallback={LoadingDots} timeout={500}>
      <Page className="homePage">
        <h1>{t('HomePage.hello_name', { name: session.Firstname })}</h1>
        <p>{t('HomePage.use_the_finance_conversion_summary_below_to_gain_insights')}</p>
        <div className="grid">
          <Card className="funnelReport">
            <MonthlyFinanceConversion dealershipId={dealershipId!} countryCode={countryCode!} />
          </Card>
          {isFindAVehiclePage ? (
            <a href="https://www.ivendi.com/lenderbenefits" target="_blank" rel="noreferrer">
              <img
                className="funnelReport__image"
                src={process.env.PUBLIC_URL + '/static/images/Lender_USP_Platform_Update.gif'}
                alt="Streamlining the Quick Quote Journey"
              />
            </a>
          ) : (
            <a href="https://www.ivendi.com/lender-status-updates" target="_blank" rel="noreferrer">
              <img
                className="funnelReport__image"
                src={process.env.PUBLIC_URL + '/static/images/important_update_commission_disclosure.png'}
                alt="Lenders pausing lending due to commission disclosure"
              />
            </a>
          )}
        </div>
      </Page>
    </FeaturesReady>
  );
};

function mapStateToProps(state: any) {
  return { session: state.session };
}

export default connect(mapStateToProps)(inject('appStore')(HomePage));
