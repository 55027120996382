import { useTranslation } from 'react-i18next';
import ConsumerName from '~/components/Common/ConsumerName';
import MoneyFormat from '~/components/Common/MoneyFormat';
import DateFormat from '~/components/Common/DateFormat';
import { FixLater } from '~/types/basic';

const FinanceNavigatorResultTab = ({ financeScan }: { financeScan: FixLater }) => {
  const { t } = useTranslation('Customer');
  const names = financeScan.Identity.Names;
  const fullName = `${names.Firstname} ${names.Surname}`;
  const createdDate = financeScan.CreatedDate;
  const firstResult = financeScan.Results[0];
  const deposit = firstResult?.Deposit;
  const term = firstResult?.Term;
  return (
    <div className="carFinanceChecksTabsItem" data-th="finance-navigator-scan-tab-item">
      <div className="carFinanceChecksTabsItem__consumer">
        <ConsumerName name={fullName} />
      </div>
      <div className="carFinanceChecksTabsItem__table">
        <div className="carFinanceChecksTabsItem__row" key="row1">
          <div className="carFinanceChecksTabsItem__tableLeft" key="tl1">
            {t('FinanceNavigatorResultTab.deposit')}
          </div>
          <div className="carFinanceChecksTabsItem__tableRight" key="tr1">
            <MoneyFormat value={deposit} />
          </div>
        </div>
        <div className="carFinanceChecksTabsItem__row carFinanceChecksTabsItem__rowBottom" key="row2">
          <div className="carFinanceChecksTabsItem__tableLeft" key="tl2">
            {t('FinanceNavigatorResultTab.term')}
          </div>
          <div className="carFinanceChecksTabsItem__tableRight" key="tr2">
            {term}
          </div>
        </div>
      </div>
      <div className="carFinanceChecksTabsItem__bottomLine">
        <div className="carFinanceChecksTabsItem__date">
          <DateFormat parse="utc" value={createdDate} format="DD MMM YYYY HH:mm" />
        </div>
      </div>
    </div>
  );
};

export default FinanceNavigatorResultTab;
