import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as applicationActions from '../../../redux/application/applicationActions';
import * as consumerActions from '../../../redux/consumer/consumerActions';
import { getLenderConfig } from '../../../core/lenders';
import ErrorApology from '../../Common/ErrorApology';
import * as componentActions from '../../../redux/component/componentActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { observer, inject } from 'mobx-react';
import { fetchApplication } from '../../../api/application';
import PermissionDeniedMessage from '../../Permissions/PermissionDeniedMessage';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css';
import _ from 'lodash';
import { push } from 'routerHistory';
import { withParams } from 'hocs/router';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';

class ApplicationContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      wasPrefilled: false
    };
  }

  setPrefilled = () => {
    this.setState({
      wasPrefilled: true
    });
  };

  UNSAFE_componentWillMount() {
    this.props.load('ApplicationContainer');

    if (
      this.props.appStore.uiState.hasDefaultTheme &&
      !this.props.appStore.uiState.isMotonovo &&
      document.getElementById('fc_frame')
    ) {
      document.getElementById('fc_frame').style.display = 'initial';
    }
  }

  componentWillUnmount() {
    this.props.unload('ApplicationContainer');

    if (document.getElementById('fc_frame')) {
      document.getElementById('fc_frame').style.display = 'none';
    }
  }

  render() {
    const { children, ...remainingProps } = this.props;

    if (this.props.appStore.uiState.canViewConsumerApplications) {
      if (this.props.components.ApplicationContainer && this.props.components.ApplicationContainer.error) {
        return <ErrorApology>{this.props.components.ApplicationContainer.errorMessage}</ErrorApology>;
      }

      if (!(this.props.components.ApplicationContainer && this.props.components.ApplicationContainer.hasLoaded)) {
        return null;
      }
    } else {
      return (
        <PermissionDeniedMessage
          buttonLabel={this.props.t('ApplicationContainer.go_to_customer_record')}
          buttonTo={`/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/`}
        >
          {this.props.t('ApplicationContainer.you_dont_have_the_required_permissions_to_view_customer_applications')}
        </PermissionDeniedMessage>
      );
    }

    const outletContext = {
      wasPrefilled: this.state.wasPrefilled,
      setPrefilled: this.setPrefilled,
      ...remainingProps
    };

    return (
      <>
        <Outlet context={outletContext} />
        {this.props.appStore.uiState.hasDefaultTheme && !this.props.appStore.uiState.isMotonovo && (
          <Freshchat
            token={process.env.REACT_APP_FRESH_CHAT_TOKEN}
            externalId={`${
              _.find(this.props.session.Dealerships, {
                Id: this.props.params.dealershipId
              }).Name
            } (id: ${this.props.params.dealershipId}) - (${this.props.session.Firstname} ${
              this.props.session.LastName
            })`}
            firstName={this.props.session.Firstname}
            lastName={this.props.session.LastName}
            email={this.props.session.Email}
          />
        )}
      </>
    );
  }
}

ApplicationContainer.propTypes = {
  children: PropTypes.any,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  load: PropTypes.func,
  unload: PropTypes.func,
  components: PropTypes.object,
  params: PropTypes.object,
  appStore: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const customer = getCustomer(state, ownProps.params.consumerId.toLowerCase());
  const canUseShowroomCfc = ownProps.appStore.uiState.canUseCfcIvendiv2; // todo check
  return {
    consumer: customer,
    dealership: state.dealership,
    application: state.application,
    options: state.options,
    session: state.session,
    vehicle: state.application.Vehicle,
    validationRules: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'validationRules'
    ),
    applicationFields: getLenderConfig(
      state.application.Quote && state.application.Quote.FunderCode,
      state.application.CustomerType,
      'applicationFields'
    ),
    quotes: state.quotes,
    funders: state.funders,
    products: state.products,
    enableShowroomCfc:
      canUseShowroomCfc && customer && customer.CustomerType && customer.CustomerType.toLowerCase() === 'consumer',
    assignees: customer ? customer.assignedTo : [],
    components: state.components
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: (componentName) => {
      dispatch(componentActions.componentLoad(componentName, ownProps.params));
    },
    unload: (componentName) => {
      dispatch(componentActions.componentUnload(componentName, ownProps.params));
    },
    fetchApplicationUpdates: (applicantId) => {
      dispatch(applicationActions.fetchApplicationUpdates(applicantId));
    },
    changeApplicationQuotePreSubmission: ({ quote, quoteRequest }) => {
      let applicantId = ownProps.params.applicantId;
      dispatch(applicationActions.changeApplicationQuotePreSubmission(applicantId, quote, quoteRequest));
    },
    cloneApplication: (dealershipId, consumer, vehicle, quote, existingApplicationId, customerType) => {
      dispatch(
        applicationActions.cloneApplication(dealershipId, consumer, vehicle, quote, existingApplicationId, customerType)
      );
    },
    updateDecision: (applicantId, customerType) => {
      dispatch(applicationActions.updateDecision(applicantId, customerType));
    },
    submitApplication: (
      applicationId,
      nextPage,
      customerType,
      customerLocation,
      marketingPreference,
      marketingVersion,
      quoteId,
      distanceSale
    ) => {
      dispatch(
        applicationActions.submitApplication(
          applicationId,
          nextPage,
          customerType,
          customerLocation,
          marketingPreference,
          marketingVersion,
          quoteId,
          distanceSale
        )
      );
    },
    submitApplicationSection: (sectionName, applicantId, formData, nextPage) => {
      dispatch(applicationActions.submitApplicationSection(sectionName, applicantId, formData, nextPage));
    },
    saveApplicationSection: (sectionName, applicantId, formData) => {
      dispatch(applicationActions.saveApplicationSection(sectionName, applicantId, formData));
    },
    saveBankDetails: (applicantId, data, customerType) => {
      dispatch(applicationActions.saveBankDetails(applicantId, data, customerType));
    },
    submitBankDetails: (data, nextPage) => {
      dispatch(applicationActions.submitBankDetails(data, nextPage));
    },
    onChangeVehicle: (vehicle) => {
      const { dealershipId, consumerId, applicantId } = ownProps.params;
      dispatch(applicationActions.changeApplicationVehicle(vehicle));
      push(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit/quote/costs`);
    },
    onProceedWithChangeQuote: (applicationId, vehicle, quote, customerType, dealershipId, consumerId, quoteRequest) => {
      dispatch(
        applicationActions.changeApplicationQuote(
          applicationId,
          vehicle,
          quote,
          customerType,
          dealershipId,
          consumerId,
          quoteRequest
        )
      );
    },
    resetEditVehicle: () => {
      dispatch(applicationActions.resetEditVehicle());
    },
    showHideLeadOnDashboard: (value) => {
      dispatch(consumerActions.showHideLeadOnDashboard(value));
    },
    changeApplication: (id) => {
      dispatch(applicationActions.readyChangeApplication);
      return new Promise((res) => {
        fetchApplication(id)
          .then((application) => dispatch(applicationActions.changeApplication(application)))
          .then(res);
      });
    },
    prefillApplication: (currentApplicationId, prefillFromId) => {
      dispatch(applicationActions.readyChangeApplication);
      return new Promise((res) => {
        fetchApplication(currentApplicationId).then((currentApplication) => {
          fetchApplication(prefillFromId)
            .then((application) => {
              let prefilledApp = currentApplication;
              prefilledApp.PersonalDetails = application.PersonalDetails;
              prefilledApp.AddressHistory = application.AddressHistory;
              prefilledApp.EmploymentHistory = application.EmploymentHistory;
              prefilledApp.BankDetails = application.BankDetails;
              prefilledApp.AffordabilityDetails = application.AffordabilityDetails;
              prefilledApp.PrivacyPreferences = application.PrivacyPreferences;
              return prefilledApp;
            })
            .then((application) => dispatch(applicationActions.changeApplication(application)))
            .then(res);
        });
      });
    },
    fullSubmitApplication: (
      application,
      redirectUrl,
      selectedBenefitsPageRadio,
      marketingPreference,
      marketingVersion,
      distanceSale
    ) => {
      dispatch(
        applicationActions.fullSubmit(
          application,
          redirectUrl,
          selectedBenefitsPageRadio,
          marketingPreference,
          marketingVersion,
          distanceSale
        )
      );
    }
  };
}

export default compose(
  withParams,
  withTranslation('Application'),
  inject('appStore'),
  connect(mapStateToProps, mapDispatchToProps),
  observer
)(ApplicationContainer);
