import { Component } from 'react';
import PropTypes from 'prop-types';
import QuotesListTable from './QuotesListTable';
import './quotesList.scss';

// TODO: This component is not used - remove it and move the CSS
const QuotesList = (props) => {
  return (
    <QuotesListTable
      quotes={props.quotes}
      hasAdjustedBalloon={props.hasAdjustedBalloon}
      hasCheckedEligibility={props.hasCheckedEligibility}
      onQuoteSelect={props.onQuoteSelect}
      onProductNameClick={props.onProductNameClick}
      schemesVisible={props.schemesVisible}
      onProceed={props.onProceed}
      onSave={props.onSave}
      isQuickQuote={props.isQuickQuote}
      isCustomerQuote={props.isCustomerQuote}
      onEligibilityMatchClick={props.onEligibilityMatchClick}
      onSortQuotes={props.onSortQuotes}
      sortField={props.sortField}
      sortDirection={props.sortDirection}
      proceedingState={props.proceedingState}
      chosenQuoteId={props.chosenQuoteId}
      vehicleClass={props.vehicleClass}
      showCombinedQuoteClick={props.showCombinedQuoteClick}
      proceedToBreakDown={props.proceedToBreakDown}
      showFullQuote={props.showFullQuote}
      newCarOffers={props.newCarOffers}
      vehicleCondition={props.vehicleCondition}
      onProceedToFinanceNavigator={props.onProceedToFinanceNavigator}
    />
  );
};

QuotesList.propTypes = {
  quotes: PropTypes.array,
  hasAdjustedBalloon: PropTypes.bool,
  hasCheckedEligibility: PropTypes.bool,
  onProceed: PropTypes.func,
  schemesVisible: PropTypes.bool,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  enableShowroomCfc: PropTypes.bool,
  proceedingState: PropTypes.string,
  chosenQuoteId: PropTypes.string,
  onSave: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  onSortQuotes: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  vehicleClass: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func,
  showFullQuote: PropTypes.bool,
  newCarOffers: PropTypes.array,
  onProceedToFinanceNavigator: PropTypes.func
};

export default QuotesList;
