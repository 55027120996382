import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Validator from '../../../validators/Validator';
import FormFooter from '../../Common/Form/FormFooter';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import YearsMonthsField from '../../Common/Form/YearsMonthsField';
import ApplicationCardCorporate from '../components/ApplicationCardCorporate';
import { observer } from 'mobx-react';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import ExclamationWarningWithButton from '../../Common/ExclamationWarningWithButton';
import Button from '../../Common/Button/Button';
import SelectInput from '../../Common/Form/SelectInput';
import DateInput from '../../Common/Form/DateInput';
import './organisationContactsForm.scss';
import {
  calculateTotalMonths,
  calculateTotalMonthsFromCollection,
  isDateMoreThanXMonthsAgo
} from '../../../core/helpers';
import ExclamationWarning from '~/components/Common/ExclamationWarning';
import { canValidateContactAgainstDob } from '~/features';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';
import { getYearsAndMonthsFromTotalMonths } from '~/utils/application';

class OrganisationContactsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        Items: [
          {
            Items: [{}]
          }
        ]
      }
    };
  }

  UNSAFE_componentWillMount() {
    this.setInitialFormData(this.props.defaultValues);
    this.setupValidation(this.props.validationRules.Items[0], this.props.validationRules.Items[0].Items[0]);
  }

  componentWillUnmount() {
    this.setAllNotEditing();
  }

  setInitialFormData = (data) => {
    this.setState(
      () => {
        const newState = {
          formData: data,
          isFormSubmitted: false
        };
        newState.formData.Items = newState.formData.Items.map((item) => ({ ...item, isEditing: false }));
        return { ...newState };
      },
      () => (this.props.progress.organisationContacts < 100 ? this.handleEditForm(0) : this.setAllNotEditing())
    );
  };

  setupValidation(contactRules, addressRules) {
    this.validator = new Validator();
    this.validator.setRules(contactRules);
    this.addressValidator = new Validator();
    this.addressValidator.setRules(addressRules);
  }

  getEditingIndex = () => {
    let currentlyEditingItem = {
      contactEditingIndex: null,
      addressEditingIndex: null
    };
    this.state.formData.Items.forEach((item, i) => {
      if (item.isEditing === true) {
        currentlyEditingItem.contactEditingIndex = i;
      } else {
        item.Items.forEach((subItem, j) => {
          if (subItem.isEditing === true) {
            currentlyEditingItem.contactEditingIndex = i;
            currentlyEditingItem.addressEditingIndex = j;
          }
        });
      }
    });
    return currentlyEditingItem;
  };
  handleFieldChange = (id, value, contactIndex, addressIndex) => {
    this.setState((prevState) => {
      const newArray = [...prevState.formData.Items];
      const currentState =
        addressIndex >= 0
          ? prevState.formData.Items[contactIndex].Items[addressIndex]
          : prevState.formData.Items[contactIndex];
      const updatedItem = { ...currentState, [id]: value };
      addressIndex >= 0
        ? (newArray[contactIndex].Items[addressIndex] = updatedItem)
        : (newArray[contactIndex] = updatedItem);
      addressIndex >= 0 ? this.addressValidator.validate(updatedItem) : this.validator.validate(updatedItem);
      return { ...prevState, formData: { ...prevState.formData, Items: newArray } };
    });
  };
  handleRemoveItem = (contactIndex, addressIndex) => {
    this.setState((prevState) => {
      const newArray = [...prevState.formData.Items];
      addressIndex >= 0 ? newArray[contactIndex].Items.splice(addressIndex, 1) : newArray.splice(contactIndex, 1);
      return { ...prevState, formData: { ...prevState.formData, Items: newArray } };
    });
  };
  handleAddItem = (contactIndex) => {
    const defaultAddressFormData = {
      BuildingName: '',
      BuildingNumber: '',
      Country: '',
      County: '',
      District: '',
      Organisation: '',
      PostTown: '',
      Postcode: '',
      Residency: '',
      Street: '',
      SubBuilding: '',
      TimeAtAddress: {
        Months: '',
        TotalMonths: '',
        Years: ''
      },
      isEditing: true
    };
    const defaultContactFormData = {
      Title: '',
      FirstName: '',
      MiddleNames: '',
      LastName: '',
      Position: '',
      DateOfBirth: '',
      CountryOfBirth: '',
      Nationality: '',
      Gender: '',
      NoOfDependants: '',
      MaritalStatus: '',
      Email: '',
      HomeTelephone: '',
      WorkTelephone: '',
      MobileTelephone: '',
      Items: [],
      isEditing: true
    };
    this.setState(
      (prevState) => {
        const newArray = [...prevState.formData.Items.map((item) => ({ ...item, isEditing: false }))];
        const addressArray = newArray.map((item) => item.Items.map((item) => ({ ...item, isEditing: false })));
        newArray.forEach((Item, i) => (Item.Items = addressArray[i]));
        contactIndex >= 0
          ? newArray[contactIndex].Items.push(defaultAddressFormData)
          : newArray.push(defaultContactFormData);
        contactIndex >= 0 ? this.addressValidator.validate(addressArray) : this.validator.validate(newArray);
        return {
          ...prevState,
          formData: { ...prevState.formData, Items: newArray },
          showTimeAtAddressWarning: false,
          isFormSubmitted: false
        };
      },
      () => this.setErrorsToState()
    );
  };
  handleEditForm = (contactIndex, addressIndex) => {
    this.setAllNotEditing();
    this.setState((prevState) => {
      const newArray = [...prevState.formData.Items];
      const currentState =
        addressIndex >= 0
          ? prevState.formData.Items[contactIndex].Items[addressIndex]
          : prevState.formData.Items[contactIndex];
      const updatedItem = { ...currentState, isEditing: true };
      addressIndex >= 0
        ? (newArray[contactIndex].Items[addressIndex] = updatedItem)
        : (newArray[contactIndex] = updatedItem);
      addressIndex >= 0 ? this.addressValidator.validate(updatedItem) : this.validator.validate(updatedItem);
      return {
        ...prevState,
        formData: { ...prevState.formData, Items: newArray },
        showTimeAtAddressWarning: false,
        isFormSubmitted: false
      };
    });
  };
  validateContactDuration = () => {
    const formData = _.cloneDeep(this.state.formData);

    let validatedContactDurations = [];
    validatedContactDurations = formData.Items.map((item) => {
      return (() => {
        let curr = 0;
        item.Items.filter((subItem) => (curr += subItem.TimeAtAddress.TotalMonths));
        return curr < 36;
      })();
    });
    return validatedContactDurations;
  };
  validateContactDurationAgainstDOB = () => {
    const formData = _.cloneDeep(this.state.formData);
    return formData.Items.map((item) => {
      return (() => {
        const dob = item.DateOfBirth;
        const totalMonths = item.Items.reduce((a, b) => a + b.TimeAtAddress.TotalMonths, 0);
        if (canValidateContactAgainstDob()) {
          return isDateMoreThanXMonthsAgo(dob, totalMonths) === false;
        }
        return false;
      })();
    });
  };
  handlePostCodeLookup = (address, contactIndex, addressIndex) => {
    this.setState((prevState) => {
      const newArray = [...prevState.formData.Items];
      const updatedItem = Object.assign({}, newArray[contactIndex].Items[addressIndex], address);
      newArray[contactIndex].Items[addressIndex] = updatedItem;
      this.validator.validate(newArray[contactIndex]);
      this.addressValidator.validate(updatedItem);
      return { ...prevState, formData: { ...prevState.formData, Items: newArray } };
    });
  };
  handleFieldChangeYM = (id, value, contactIndex, AddressIndex) => {
    const { years, months } = getYearsAndMonthsFromTotalMonths(value);

    this.setState(
      (prevState) => {
        const currentAddressState = prevState.formData.Items[contactIndex].Items[AddressIndex];
        const newArray = [...prevState.formData.Items];
        const updatedItem = {
          ...currentAddressState,
          TimeAtAddress: {
            [id]: value,
            Years: years,
            Months: months,
            TotalMonths: calculateTotalMonths({
              Years: years,
              Months: months
            })
          }
        };
        newArray[contactIndex].Items[AddressIndex] = updatedItem;
        newArray[contactIndex].totalMonths = calculateTotalMonthsFromCollection(
          prevState.formData.Items[contactIndex].Items,
          'TimeAtAddress'
        );
        return { ...prevState, formData: { ...prevState.formData, Items: newArray } };
      },
      () => this.setErrorsToState()
    );
  };
  setAllNotEditing = () => {
    const formData = this.state.formData;

    _.forEach(formData.Items, (item) => {
      _.forEach(item.Items, (subItem) => {
        subItem.isEditing = false;
      });

      item.isEditing = false;
    });

    this.setState((prevState) => {
      return { ...prevState, formData: { ...formData } };
    });
  };
  setErrorsToState = () => {
    this.setState((prevState) => {
      const formData = prevState.formData;

      _.forEach(formData.Items, (item, i) => {
        this.validator.validate(item);
        const updatedItem = Object.assign(item, {
          validationErrors: this.validator.getErrors()
        });
        formData.Items.splice(i, 1, updatedItem);

        _.forEach(item.Items, (subItem, j) => {
          this.addressValidator.validate(subItem);
          const updatedItem = Object.assign(subItem, {
            validationErrors: this.addressValidator.getErrors()
          });
          formData.Items[i].Items.splice(j, 1, updatedItem);
        });
      });

      return { ...prevState, formData: { ...formData } };
    });
  };
  countErrors = (contactIndex, addressIndex, count = 0) => {
    const filteredValidationErrors = Object.assign({}, this.state.formData.Items[contactIndex].validationErrors);
    delete filteredValidationErrors.totalMonths;
    delete filteredValidationErrors.Items;
    const validationErrors =
      addressIndex >= 0
        ? this.state.formData.Items[contactIndex].Items[addressIndex].validationErrors
        : filteredValidationErrors;

    if (typeof validationErrors === 'object') {
      return (count = Object.keys(validationErrors).length);
    } else {
      return 0;
    }
  };
  onSave = () => {
    this.setErrorsToState();
    const validatedContactDurationAgainstDOB = this.validateContactDurationAgainstDOB();
    const contactsWithHistoryBeforeDOB = validatedContactDurationAgainstDOB.filter((item) => item === true);
    if (contactsWithHistoryBeforeDOB.length === 0) {
      this.props.onSave(this.state.formData);
    }
  };
  submitForm = () => {
    this.setErrorsToState();
    const validatedContactDurations = this.validateContactDuration();
    const contactsWithoutHistory = validatedContactDurations.filter((item) => item === true);
    const validatedContactDurationAgainstDOB = this.validateContactDurationAgainstDOB();
    const contactsWithHistoryBeforeDOB = validatedContactDurationAgainstDOB.filter((item) => item === true);

    if (
      !this.validator.errorCount &&
      !this.addressValidator.errorCount &&
      contactsWithoutHistory.length === 0 &&
      contactsWithHistoryBeforeDOB.length === 0
    ) {
      this.props.onSubmit(this.state.formData);
    }

    this.setState({
      isFormSubmitted: true
    });
  };

  render() {
    const errors = this.validator.getErrors();
    const errorsAddress = this.addressValidator.getErrors();
    const validatedContactDurations = this.validateContactDuration();
    const validatedContactDurationAgainstDOB = this.validateContactDurationAgainstDOB();
    const applicationFields = this.props.applicationFields;
    return (
      <MobxForm onSubmit={this.submitForm} autoComplete="off">
        {this.state.formData.Items.map((contact, contactIndex) => {
          return (
            <div key={'mini_wrapper' + contactIndex} className="miniPanel__wrapper">
              <MiniPanel key={'mini_' + contactIndex}>
                <MiniPanelHeader
                  title={this.props.t('OrganisationContactsForm.contact_index', {
                    contactIndex: contactIndex + 1
                  })}
                >
                  {this.state.formData.Items.length > 1 && (
                    <div className="organisationContactsForm__removeContact">
                      <Button
                        type="button"
                        buttonStyle="cancel"
                        size="small"
                        key={'remove_contact' + contactIndex}
                        onClick={() => this.handleRemoveItem(contactIndex)}
                      >
                        {this.props.t('OrganisationContactsForm.remove_contact')}
                      </Button>
                    </div>
                  )}
                </MiniPanelHeader>

                {contact.isEditing ? (
                  <div key={'editForm' + contactIndex} ref={'formInner' + contactIndex}>
                    <div className="organisationContactsForm__form" key={'formContainer' + contactIndex}>
                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Title}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.title')}</div>
                        </MobxFormLabel>

                        <SelectInput
                          id="Title"
                          options="FinanceTitles"
                          funderCode={this.props.funderCode}
                          value={contact.Title}
                          onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                        />
                      </MobxFieldGroup>

                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.FirstName}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.first_name')}</div>
                        </MobxFormLabel>
                        <TextInputWrappingInput
                          id="FirstName"
                          value={contact.FirstName}
                          onChange={(val) => this.handleFieldChange('FirstName', val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.MiddleNames}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.middle_name')}</div>
                        </MobxFormLabel>
                        <TextInputWrappingInput
                          value={contact.MiddleNames}
                          onChange={(val) => this.handleFieldChange('MiddleNames', val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.LastName}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.last_name')}</div>
                        </MobxFormLabel>
                        <TextInputWrappingInput
                          id="LastName"
                          value={contact.LastName}
                          onChange={(val) => this.handleFieldChange('LastName', val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Position}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.position')}</div>
                        </MobxFormLabel>

                        <SelectInput
                          id="Position"
                          options="Position"
                          value={contact.Position}
                          onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup isInline error={errors.DateOfBirth}>
                        <MobxFormLabel>{this.props.t('OrganisationContactsForm.date_of_birth')}</MobxFormLabel>
                        <DateInput
                          id="DateOfBirth"
                          value={contact.DateOfBirth}
                          onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      {applicationFields && applicationFields.CountryOfBirth && (
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errors.CountryOfBirth}
                        >
                          <MobxFormLabel htmlFor="CountryOfBirth">
                            {this.props.t('OrganisationContactsForm.please_select_your_country_of_birth')}
                          </MobxFormLabel>
                          <SelectInput
                            id="CountryOfBirth"
                            options="Nationality"
                            value={contact.CountryOfBirth}
                            onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                          />
                        </MobxFieldGroup>
                      )}
                      {applicationFields && applicationFields.Nationality && (
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errors.Nationality}
                        >
                          <MobxFormLabel htmlFor="Nationality">
                            {this.props.t('OrganisationContactsForm.nationality')}
                          </MobxFormLabel>
                          <SelectInput
                            id="Nationality"
                            options="Nationality"
                            value={contact.Nationality}
                            onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                          />
                        </MobxFieldGroup>
                      )}
                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Gender}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.gender')}</div>
                        </MobxFormLabel>

                        <SelectInput
                          id="Gender"
                          options="Gender"
                          value={contact.Gender}
                          funderCode={this.props.funderCode}
                          onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Dependants}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.no_dependants')}</div>
                        </MobxFormLabel>

                        <SelectInput
                          id="NoOfDependants"
                          options="Dependants"
                          value={contact.Dependants}
                          onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup
                        isInline
                        isFormSubmitted={this.state.isFormSubmitted}
                        error={errors.MaritalStatus}
                      >
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.marital_status')}</div>
                        </MobxFormLabel>

                        <SelectInput
                          id="MaritalStatus"
                          options="MaritalStatus"
                          value={contact.MaritalStatus}
                          onChange={(id, val) => this.handleFieldChange(id, val, contactIndex)}
                        />
                      </MobxFieldGroup>

                      <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Email}>
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.email')}</div>
                        </MobxFormLabel>
                        <TextInputWrappingInput
                          id="Email"
                          value={contact.Email}
                          onChange={(val) => this.handleFieldChange('Email', val, contactIndex)}
                          autoComplete="none"
                        />
                      </MobxFieldGroup>

                      <MobxFieldGroup
                        isInline
                        isFormSubmitted={this.state.isFormSubmitted}
                        error={errors.HomeTelephone}
                      >
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.home_telephone')}</div>
                        </MobxFormLabel>
                        <PhoneNumberInput
                          id="HomeTelephone"
                          value={contact.HomeTelephone}
                          onChange={(val) => this.handleFieldChange('HomeTelephone', val, contactIndex)}
                        />
                      </MobxFieldGroup>
                      <MobxFieldGroup
                        isInline
                        isFormSubmitted={this.state.isFormSubmitted}
                        error={errors.MobileTelephone}
                      >
                        <MobxFormLabel>
                          <div>{this.props.t('OrganisationContactsForm.mobile_telephone')}</div>
                        </MobxFormLabel>
                        <PhoneNumberInput
                          id="MobileTelephone"
                          value={contact.MobileTelephone}
                          onChange={(val) => this.handleFieldChange('MobileTelephone', val, contactIndex)}
                        />
                      </MobxFieldGroup>
                    </div>

                    <div className="organisationContactsForm__removeContainer">
                      <div className="organisationContactsForm__remove">
                        {this.state.formData.Items.length > 1 && (
                          <Button
                            type="button"
                            buttonStyle="cancel"
                            size="small"
                            key={'remove_' + contactIndex}
                            onClick={() => this.handleRemoveItem(contactIndex)}
                          >
                            {this.props.t('OrganisationContactsForm.remove')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <ApplicationCardCorporate
                    type="contact"
                    data={contact}
                    ref={'formInner' + contactIndex}
                    edit={() => this.handleEditForm(contactIndex)}
                    remove={() => this.handleRemoveItem(contactIndex)}
                    showRemove={false}
                    errorCount={this.countErrors(contactIndex)}
                    submitted={this.state.formSubmitted}
                    key={'card' + contactIndex}
                    sectionHeading={this.props.t('OrganisationContactsForm.contact_details')}
                  />
                )}

                {contact.Items.map((address, addressIndex) => {
                  return address.isEditing ? (
                    <div key={'contact' + contactIndex + 'Address' + addressIndex} ref={'formInner' + addressIndex}>
                      <div
                        className="organisationContactsForm__form"
                        key={'formContainer' + contactIndex + '_' + addressIndex}
                      >
                        <PostcodeLookup
                          onSubmit={(val) => this.handlePostCodeLookup(val, contactIndex, addressIndex)}
                          defaultValue={address.Postcode}
                          inline
                        />
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.SubBuilding}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.flat_room_floor')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.SubBuilding}
                            onChange={(val) => this.handleFieldChange('SubBuilding', val, contactIndex, addressIndex)}
                            placeholder={this.props.t('OrganisationContactsForm.flat_room_floor_placeholder')}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.BuildingName}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.building_name')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.BuildingName}
                            onChange={(val) => this.handleFieldChange('BuildingName', val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.BuildingNumber}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.building_number')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.BuildingNumber}
                            onChange={(val) =>
                              this.handleFieldChange('BuildingNumber', val, contactIndex, addressIndex)
                            }
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.Street}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.street')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.Street}
                            onChange={(val) => this.handleFieldChange('Street', val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.District}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.district')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.District}
                            onChange={(val) => this.handleFieldChange('District', val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.PostTown}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.city_town')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.PostTown}
                            onChange={(val) => this.handleFieldChange('PostTown', val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.Postcode}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.postcode')}</div>
                          </MobxFormLabel>
                          <TextInputWrappingInput
                            value={address.Postcode}
                            onChange={(val) => this.handleFieldChange('Postcode', val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={errorsAddress.Residency}
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.residency')}</div>
                          </MobxFormLabel>
                          <SelectInput
                            options="Residency"
                            id="Residency"
                            value={address.Residency}
                            onChange={(id, val) => this.handleFieldChange(id, val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                        <MobxFieldGroup
                          isInline
                          isFormSubmitted={this.state.isFormSubmitted}
                          error={
                            errorsAddress.TimeAtAddress &&
                            errorsAddress.TimeAtAddress.Years &&
                            errorsAddress.TimeAtAddress.Months
                          }
                        >
                          <MobxFormLabel>
                            <div>{this.props.t('OrganisationContactsForm.time_at_address')}</div>
                          </MobxFormLabel>
                          <YearsMonthsField
                            id="TimeAtAddress"
                            name="TotalMonths"
                            value={address.TimeAtAddress.TotalMonths}
                            onChange={(val) => this.handleFieldChangeYM('TotalMonths', val, contactIndex, addressIndex)}
                          />
                        </MobxFieldGroup>
                      </div>

                      <div className="organisationContactsForm__removeContainer">
                        <div className="organisationContactsForm__remove">
                          {this.state.formData.Items[contactIndex].Items > 1 ? (
                            <Button
                              type="button"
                              buttonStyle="cancel"
                              size="small"
                              key={'remove_address' + contactIndex + '_' + addressIndex}
                              onClick={() => this.handleRemoveItem(contactIndex, addressIndex)}
                            >
                              {this.props.t('OrganisationContactsForm.remove')}
                            </Button>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ApplicationCardCorporate
                      type="address"
                      data={address}
                      ref={'formInner' + addressIndex}
                      edit={() => this.handleEditForm(contactIndex, addressIndex)}
                      remove={() => this.handleRemoveItem(contactIndex, addressIndex)}
                      showRemove={addressIndex >= 0 ? true : false}
                      errorCount={this.countErrors(contactIndex, addressIndex)}
                      submitted={this.state.formSubmitted}
                      key={'card' + addressIndex}
                      sectionHeading={this.props.t('OrganisationContactsForm.address_index', {
                        addressIndex: addressIndex + 1
                      })}
                    />
                  );
                })}
              </MiniPanel>

              {validatedContactDurations && validatedContactDurations[contactIndex] && (
                <ExclamationWarningWithButton
                  key={'address_warning' + contactIndex}
                  title={this.props.t('OrganisationContactsForm.each_contact_needs_at_least_3_years_address_history')}
                  onClick={() => this.handleAddItem(contactIndex)}
                  buttonText={this.props.t('OrganisationContactsForm.add_address')}
                />
              )}

              {validatedContactDurationAgainstDOB && validatedContactDurationAgainstDOB[contactIndex] && (
                <ExclamationWarning
                  key={'address_dob_warning' + contactIndex}
                  message={this.props.t('OrganisationContactsForm.address_history_starts_prior_to_dob')}
                />
              )}
            </div>
          );
        })}

        {JSON.stringify(errors) === '{}' && (
          <div className="organisationContactsForm__buttonWrapper">
            <div className="organisationContactsForm__addContactButton">
              <Button
                type="button"
                buttonStyle="secondary"
                size="small"
                key={'addContact'}
                onClick={() => this.handleAddItem()}
              >
                {this.props.t('OrganisationContactsForm.add_another_contact')}
              </Button>
            </div>
          </div>
        )}

        <FormFooter
          onSave={this.onSave}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />
      </MobxForm>
    );
  }
}

OrganisationContactsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  applicationFields: PropTypes.object.isRequired,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string.isRequired,
  submittingState: PropTypes.string,
  dealershipId: PropTypes.string,
  quoteId: PropTypes.string,
  funderCode: PropTypes.string
};
export default withTranslation('Application')(observer(OrganisationContactsForm));
