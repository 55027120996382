const PageNames = {
  'Home Page': {
    tags: ['dashboard']
  },
  'Home Page V2': {
    tags: ['home']
  },
  'Sign In': {
    tags: ['auth', 'signin']
  },
  'Forgot Password': {
    tags: ['auth', 'forgot-password']
  },
  'Forgot Password Requested': {
    tags: ['auth', 'forgot-password-requested']
  },
  'Reset Password': {
    tags: ['auth', 'reset-password']
  },
  'Reset Password Success': {
    tags: ['auth', 'reset-password-success']
  },
  'List - Customer': {
    tags: ['customer', 'customer-list']
  },
  'Add - Customer': {
    tags: ['customer', 'customer-add']
  },
  'Edit - Customer': {
    tags: ['customer', 'customer-edit']
  },
  'Delete - Customer': {
    tags: ['customer', 'customer-delete']
  },
  Customer: {
    tags: ['customer']
  },
  'Add Vehicle - Customer': {
    tags: ['customer', 'customer-add-vehicle']
  },
  'Assign Lead - Customer': {
    tags: ['customer', 'customer-assign-lead']
  },
  'View map - Customer': {
    tags: ['customer', 'customer-view-map']
  },
  'Close deal - Customer': {
    tags: ['customer', 'customer-close-deal']
  },
  'CFC Prospect Quote - Customer': {
    tags: ['customer', 'customer-cfc-prospect-quote']
  },
  'Saved Quote - Customer': {
    tags: ['customer', 'customer-saved-quote']
  },
  'Quote - Customer': {
    tags: ['customer', 'customer-quote']
  },
  'Vehicle Self Service Quote - Customer': {
    tags: ['customer', 'customer-vehicle-self-service-quote']
  },
  'Deal - Customer': {
    tags: ['customer', 'customer-deal']
  },
  'Sold Finance - Close Deal': {
    tags: ['close-deal', 'close-deal-sold-finance']
  },
  'Not Available - Close Deal': {
    tags: ['close-deal', 'close-deal-not-available']
  },
  'Sold Cash - Close Deal': {
    tags: ['close-deal', 'close-deal-sold-cash']
  },
  'Not Of Interest - Close Deal': {
    tags: ['close-deal', 'close-deal-not-of-interest']
  },
  'Paid Out': {
    tags: ['paid-out']
  },
  'Select Vehicle - Send Deal': {
    tags: ['send-deal', 'send-deal-select-vehicle']
  },
  'Pricing - Send Deal': {
    tags: ['send-deal', 'send-deal-pricing']
  },
  'Select Quotes - Send Deal': {
    tags: ['send-deal', 'send-deal-select-quotes']
  },
  'Select Customer - Send Deal': {
    tags: ['send-deal', 'send-deal-select-customer']
  },
  'Confirm and Send - Send Deal': {
    tags: ['send-deal', 'send-deal-confirm-and-send']
  },
  'Lender Content': {
    tags: ['consumer-duty', 'consumer-duty-lender-content']
  },
  'Lender Product Understanding Questions': {
    tags: ['consumer-duty', 'consumer-duty-lender-product-understanding-questions']
  },
  'Product Understanding and Suitability': {
    tags: ['consumer-duty', 'consumer-duty-product-understanding-and-suitability']
  },
  'Lender Privacy Policy': {
    tags: ['lender-privacy-policy']
  },
  'Motonovo data usage policy - Application': {
    tags: ['finance-application', 'data-usage-policy']
  },
  'Personal Details - Application': {
    tags: ['finance-application', 'finance-application-personal-details']
  },
  'Address - Application': {
    tags: ['finance-application', 'finance-application-address']
  },
  'Employment - Application': {
    tags: ['finance-application', 'finance-application-employment']
  },
  'Bank Details - Application': {
    tags: ['finance-application', 'finance-application-bank-details']
  },
  'Affordability - Application': {
    tags: ['finance-application', 'finance-application-affordability']
  },
  'Summary - Application': {
    tags: ['finance-application', 'finance-application-summary']
  },
  'Business Details - Application': {
    tags: ['finance-application', 'finance-application-business-details']
  },
  'Business Address - Application': {
    tags: ['finance-application', 'finance-application-business-address']
  },
  'Organisation Contacts - Application': {
    tags: ['finance-application', 'finance-application-organisation-contacts']
  },
  'Status - Application': {
    tags: ['finance-application', 'finance-application-status']
  },
  'Repropose - Application': {
    tags: ['finance-application', 'finance-application-repropose']
  },
  'Intercept Quote Result': {
    tags: ['finance-application', 'finance-application-requote', 'requote-results']
  },
  'ApplicationVolumes - Insights': {
    tags: ['application-volumes', 'insights', 'finance-report']
  },
  'Lender Notes - Application': {
    tags: ['finance-application', 'finance-application-lender-notes']
  },
  'View Summary - Application': {
    tags: ['finance-application', 'finance-application-view-summary']
  },
  'Quote Pre Submission - Application': {
    tags: ['finance-application', 'finance-application-quote-pre-submission']
  },
  'Edit - Application': {
    tags: ['finance-application', 'finance-application-edit']
  },
  'Edit Employment - Application': {
    tags: ['finance-application', 'finance-application-edit-employment']
  },
  'Edit Summary - Application': {
    tags: ['finance-application', 'finance-application-edit-summary']
  },
  'Edit Quote - Application': {
    tags: ['finance-application', 'finance-application-edit-quote']
  },
  'Edit Quote Vehicle - Application': {
    tags: ['finance-application', 'finance-application-edit-quote-vehicle']
  },
  'Edit Quote Costs - Application': {
    tags: ['finance-application', 'finance-application-edit-quote-costs']
  },
  'Edit Bank Details - Application': {
    tags: ['finance-application', 'finance-application-edit-bank-details']
  },
  'Edit Personal Details - Application': {
    tags: ['finance-application', 'finance-application-edit-personal-details']
  },
  'Edit Affordability - Application': {
    tags: ['finance-application', 'finance-application-edit-affordability']
  },
  'Vehicle Details - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-vehicle-details']
  },
  'Quote Details - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-quote-details']
  },
  'Quote Results - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-quote-results']
  },
  'Compare Quotes - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-compare-quotes']
  },
  'CFC Check - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-cfc-check']
  },
  'Loans Breakdown - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-loans-breakdown']
  },
  'Apply - Quick Quote': {
    tags: ['quick-quote', 'quick-quote-apply']
  },
  Error: {
    tags: ['error']
  },
  'Motonovo data usage policy - Eligibility': {
    tags: ['eligibility', 'eligibility-data-usage-policy']
  },
  'Personal Details - Eligibility': {
    tags: ['eligibility', 'eligibility-personal-details']
  },
  'Address - Eligibility': {
    tags: ['eligibility', 'eligibility-address']
  },
  'Employment - Eligibility': {
    tags: ['eligibility', 'eligibility-employment']
  },
  'Bank Details - Eligibility': {
    tags: ['eligibility', 'eligibility-bank-details']
  },
  'Affordability - Eligibility': {
    tags: ['eligibility', 'eligibility-affordability']
  },
  'Summary - Eligibility': {
    tags: ['eligibility', 'eligibility-summary']
  },
  'Data usage statement - Eligibility': {
    tags: ['eligibility', 'eligibility-data-usage-statement']
  },
  'Results - Eligibility': {
    tags: ['eligibility', 'eligibility-results']
  },
  'Vehicle Details - Quote': {
    tags: ['quoting', 'quoting-vehicle-details']
  },
  'Pricing - Quote': {
    tags: ['quoting', 'quoting-pricing']
  },
  'Add Value Added Product - Quote': {
    tags: ['quoting', 'add-value-added-product']
  },
  'Results - Quote': {
    tags: ['quoting', 'results']
  },
  'Find a vehicle - Quick Quote': {
    tags: ['quoting', 'find-a-vehicle']
  },
  'Price Position - Quote': {
    tags: ['quoting', 'price-position']
  },
  'Saved Quote Details - Quote': {
    tags: ['quoting', 'saved-quote-details']
  },
  Stock: {
    tags: ['stock']
  },
  'Stock - List': {
    tags: ['stock', 'stock-list']
  },
  'Stock - Vehicle Summary': {
    tags: ['stock', 'stock-vehicle-summary']
  },
  'Stock - Add Vehicle Record': {
    tags: ['stock', 'stock-add-vehicle-record']
  },
  'Stock - Fix issues': {
    tags: ['stock', 'stock-vehicle-fix-issues']
  },
  'Stock - Refine Vehicle Record': {
    tags: ['stock', 'stock-refine-vehicle-record']
  },
  'Stock - Edit Vehicle Record': {
    tags: ['stock', 'stock-edit-vehicle-record']
  },
  'Stock - Edit Vehicle Media': {
    tags: ['stock', 'stock-edit-vehicle-media']
  },
  'Stock - Lookup': {
    tags: ['stock', 'stock-vehicle-lookup']
  },
  Reservations: {
    tags: ['reservations']
  },
  'Orders & Deals': {
    tags: ['orders-and-deals']
  },
  'Order Summary - Orders & Deals': {
    tags: ['orders-and-deals', 'orders-and-deals-deal-summary']
  },
  Webshop: {
    tags: ['webshop']
  },
  Reporting: {
    tags: ['reporting']
  },
  'Export Closed Leads - Reporting': {
    tags: ['reporting', 'export-closed-leads']
  },
  'Online Report - Reporting': {
    tags: ['reporting', 'online-report']
  },
  'Group Report - Reporting': {
    tags: ['reporting', 'group-report']
  },
  'League Table - Reporting': {
    tags: ['reporting', 'league-table']
  },
  Help: {
    tags: ['help']
  },
  'Platform Updates': {
    tags: ['platform-updates']
  },
  'Reserve Now': {
    tags: ['reserve-now']
  },
  'Finance Applications': {
    tags: ['finance-applications']
  },
  'Cfc Prospects': {
    tags: ['cfc-prospects']
  },
  'Lender Updates': {
    tags: ['lender-updates']
  },
  'Send Deal': {
    tags: ['send-deal']
  },
  'Latest Deals': {
    tags: ['latest-deals']
  },
  Impressum: {
    tags: ['impressum']
  },
  Settings: {
    tags: ['settings']
  },
  'Global Defaults - Settings': {
    tags: ['settings', 'global-defaults']
  },
  'Finance Products - Settings': {
    tags: ['settings', 'finance-products']
  },
  'Value Added Products - Settings': {
    tags: ['settings', 'value-added-products']
  },
  'Add Value Added Products - Settings': {
    tags: ['settings', 'add-value-added-products']
  },
  'Edit Value Added Products - Settings': {
    tags: ['settings', 'edit-value-added-products']
  },
  'My Account - Settings': {
    tags: ['settings', 'my-account']
  },
  'Change Password - Settings': {
    tags: ['settings', 'change-password']
  },
  'Manage Users - Settings': {
    tags: ['settings', 'manage-users']
  },
  'Add User - Settings': {
    tags: ['settings', 'add-user']
  },
  'Edit User - Settings': {
    tags: ['settings', 'edit-user']
  },
  'Rep Example - Settings': {
    tags: ['settings', 'rep-example']
  },
  'Vehicle Reservation - Settings': {
    tags: ['settings', 'vehicle-reservation']
  },
  'Initial Disclosure Document - Settings': {
    tags: ['settings', 'idd']
  },
  'Stock - Settings': {
    tags: ['settings', 'stock-settings']
  },
  'Broadcast - Settings': {
    tags: ['settings', 'stock-broadcast']
  },
  'Broadcast Edit - Settings': {
    tags: ['settings', 'edit-stock-broadcast']
  },
  'Broadcast New - Settings': {
    tags: ['settings', 'add-stock-broadcast']
  },
  'Finance Navigator - Personal Details': {
    tags: ['finance_navigator', 'personal_details']
  },
  'Finance Navigator - Address History': {
    tags: ['finance_navigator', 'address_history']
  },
  'Finance Navigator - Employment History': {
    tags: ['finance_navigator', 'employment_history']
  },
  'Finance Navigator - Affordability Details': {
    tags: ['finance_navigator', 'affordability_details']
  },
  'Finance Navigator - Summary': {
    tags: ['finance_navigator', 'summary']
  },
  'Finance Navigator - Results': {
    tags: ['finance_navigator', 'results']
  }
} as const;

export type PageName = keyof typeof PageNames;

const getPageTags = (page: string) => {
  const pageObj = PageNames[page as PageName];

  if (!pageObj) {
    return [];
  }

  return pageObj.tags;
};

export { getPageTags };
