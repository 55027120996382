import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { inject } from 'mobx-react';
import cn from 'classnames';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

import { updateImages, deleteImage } from '~/api/stockModule';

import Button from '~Common/Button/Button';
import { AppStore } from '~/modules/Stock/types/Types';
import { DragItem } from './components';

import './stockUploadedImages.scss';
import { useGallery } from './hooks/useGallery';

type Tag = 'main' | 'imperfection';

interface StockUploadedImagesProps {
  imgPrfx: string;
  imgUrls: string[];
  imprfImgUrls: string[];
  appStore: AppStore;
  showUploadModal: boolean;
}

const StockUploadedImages = (props: StockUploadedImagesProps) => {
  const { t } = useTranslation('Stock');
  const stockEditStore = props.appStore.stockEditStore;
  const { pushDangerNotification } = props.appStore.notificationStore;
  const { dealershipId, vehicleId } = useParams();

  const remoteMainGallery = useMemo(() => {
    return toJS(props.imgUrls)?.map((url) => ({ fileName: url, tag: 'main' }));
  }, [props.imgUrls]);
  const remoteImperfectionGallery = useMemo(() => {
    return toJS(props.imprfImgUrls)?.map((url) => ({ fileName: url, tag: 'imperfection' }));
  }, [props.imprfImgUrls]);

  const { imageCategory, hasOrderChanged, setGallery, discardGalleryOrder, handleMoveItem } = useGallery(
    remoteMainGallery,
    remoteImperfectionGallery
  );
  const isMainGallery = (tag: string) => (tag === 'main' || tag === 'null' ? 'main' : 'imperfection');

  // If re-ordering is not in progress, reset galleries
  useEffect(() => {
    !hasOrderChanged.main && setGallery([...remoteMainGallery], 'main');
    !hasOrderChanged.imperfection && setGallery([...remoteImperfectionGallery], 'imperfection');
  }, [setGallery, remoteImperfectionGallery, remoteMainGallery, hasOrderChanged]);

  const handleDiscardOrder = (tag: Tag) => {
    if (isMainGallery(tag)) {
      setGallery([...remoteMainGallery], tag);
      discardGalleryOrder(tag);
    } else {
      setGallery([...remoteImperfectionGallery], tag);
      discardGalleryOrder(tag);
    }
  };

  const handleUpdateOrder = async (gallery: Tag) => {
    // updating a gallery use the remaining gallery's old order instead of new order.
    // and ensure imperfection images are at the end of the list sent to BE
    let updatedOrder =
      gallery === 'imperfection'
        ? [...remoteMainGallery, ...imageCategory.imperfection]
        : [...imageCategory.main, ...remoteImperfectionGallery];

    const status = await updateImages(dealershipId, vehicleId, updatedOrder);

    if (status === 200) {
      discardGalleryOrder(gallery);
      const updatedGallery = gallery === 'main' ? imageCategory.main : imageCategory.imperfection;

      stockEditStore.setVehicleImages(updatedGallery, gallery);
    } else {
      pushDangerNotification('Something went wrong, please try again later', 'Danger');
    }
  };

  const handleDeleteImage = async (imageName: string, gallery = 'main') => {
    const status = await deleteImage(dealershipId, vehicleId, imageName);

    if (status === 200) {
      const newLocalGalleryOrder =
        gallery === 'imperfection'
          ? remoteImperfectionGallery.filter((item) => item.fileName !== imageName)
          : remoteMainGallery.filter((item) => item.fileName !== imageName);

      discardGalleryOrder('main');
      discardGalleryOrder('imperfection');
      stockEditStore.setVehicleImages(newLocalGalleryOrder, gallery);
    } else {
      pushDangerNotification('Something went wrong, please try again later', 'Danger');
    }
  };

  const hideUploadButton = !hasOrderChanged.main;
  const showImperfections = imageCategory.imperfection.length > 0;

  return (
    <>
      <div className="stockUploadedImages__actionBar">
        {hideUploadButton && (
          <Button className="stockUploadedImages__uploadButton" onClick={props.showUploadModal}>
            {t('StockUploadedImages.upload_images')}
          </Button>
        )}

        {hasOrderChanged.main && (
          <div className="stockUploadedImages__sortButtons">
            <Button
              className="stockUploadedImages__discardButton"
              buttonStyle="cancel"
              onClick={() => handleDiscardOrder('main')}
            >
              {t('StockUploadedImages.discard_order')}
            </Button>
            <Button buttonStyle="secondary" onClick={() => handleUpdateOrder('main')}>
              {t('StockUploadedImages.update_order')}
            </Button>
          </div>
        )}
      </div>
      <div className="stockUploadedImages__editImageGallery">
        {imageCategory.main.map(({ fileName, tag }, index) => {
          const displayHeroLabel = index === 0;
          return (
            <DragItem
              className="stockUploadedImages__dragWrapper"
              key={fileName}
              id={fileName}
              gallery="main"
              onMoveItem={(sourceId: string, destinationId: string) =>
                handleMoveItem({ sourceId, destinationId, gallery: 'main' })
              }
            >
              <div>
                <div onClick={() => handleDeleteImage(fileName, tag)} className="stockUploadedImages__dragNumber">
                  {index + 1}
                </div>
                <div
                  className="stockUploadedImages__dragImage"
                  style={{ backgroundImage: `url("${props.imgPrfx + fileName}")` }}
                />
                {displayHeroLabel && (
                  <div className={cn(displayHeroLabel && 'stockUploadedImages__dragHeroLabel')}>
                    {t('StockUploadedImages.primary_image')}
                  </div>
                )}
              </div>
            </DragItem>
          );
        })}
      </div>
      {showImperfections && (
        <>
          <div className="stockUploadedImages__actionBar">
            <div>
              <span
                className={cn('stockUploadedImages__imperfectionTitle', {
                  'stockUploadedImages__imperfectionTitle--hiddenMobile': hasOrderChanged.imperfection
                })}
              >
                {t('StockUploadedImages.imperfection')}
              </span>
            </div>
            {hasOrderChanged.imperfection && (
              <div className="stockUploadedImages__sortButtons">
                <Button
                  className="stockUploadedImages__discardButton"
                  buttonStyle="cancel"
                  onClick={() => handleDiscardOrder('imperfection')}
                >
                  {t('StockUploadedImages.discard_order')}
                </Button>
                <Button buttonStyle="secondary" onClick={() => handleUpdateOrder('imperfection')}>
                  {t('StockUploadedImages.update_order')}
                </Button>
              </div>
            )}
          </div>

          <div className="stockUploadedImages__editImageGallery">
            {imageCategory.imperfection.map(({ fileName, tag }, index) => {
              return (
                <DragItem
                  className="stockUploadedImages__dragWrapper"
                  key={fileName}
                  id={fileName}
                  gallery="imperfection"
                  onMoveItem={(sourceId: string, destinationId: string) =>
                    handleMoveItem({ sourceId, destinationId, gallery: 'imperfection' })
                  }
                >
                  <div>
                    <div onClick={() => handleDeleteImage(fileName, tag)} className="stockUploadedImages__dragNumber">
                      {index + 1}
                    </div>
                    <div
                      className="stockUploadedImages__dragImage"
                      style={{ backgroundImage: `url("${props.imgPrfx + fileName}")` }}
                    />
                  </div>
                </DragItem>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default inject('appStore')(StockUploadedImages);
