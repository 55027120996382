import { useRef, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '~/components/Common/Breadcrumbs';
import Page from '~/components/Common/Page';
import Panel from '~/components/Common/Panel';
import PanelHeader from '~/components/Common/PanelHeader';
import './quickQuoteFindVehicle.scss';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import RadioButtonGroup from '../../Common/Form/RadioButtonGroup';
import VrmLookup from '~/components/VrmLookup/VrmLookup';
import VisLookUp from '~/components/Common/VisLookup/context/VisLookupContext';
import { SearchBox, Hits, Pagination } from '~/components/Common/VisLookup/components';
import {
  useSelectedVehicle,
  useMetaData,
  useSearchState,
  useSearchQuery,
  useSearchCapIds
} from '~/components/Common/VisLookup/hooks';
import PanelContent from '~/components/Common/PanelContent';
import { useTranslation } from 'react-i18next';
import { withRouter } from '~/hocs/router';
import { formatVehicleObject, VisVehicle, Vehicle, VehicleCondition } from './utils';
import { useNavigate } from '~/hooks/useNavigate';
import appStore from '~/mobx-stores/appStore';
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import VisQuotePage from '~/components/VisQuoting/VisQuotePage';
import { VehicleClass } from '~/types/vehicle';
import moment from 'moment';

export const formatVehicleOnFetchSuccess = (
  vehicle: any,
  vrm: string,
  editedVehicle: Vehicle,
  hasMultipleDerivatives: boolean
) => {
  if (vrm) {
    if (vrm.length > 7) {
      vehicle.Vin = vrm;
    } else {
      vehicle.Vrm = vrm;
    }
  }
  const RegistrationDate = editedVehicle?.RegistrationDate
    ? moment(editedVehicle.RegistrationDate).format('DD/MM/YYYY')
    : vehicle.RegistrationDate
    ? moment(vehicle.RegistrationDate).format('DD/MM/YYYY')
    : '';

  return {
    ...vehicle,
    Mileage: editedVehicle?.Mileage || vehicle.Mileage,
    RegistrationDate,
    Price: editedVehicle?.Price || vehicle.Price,
    Vin: editedVehicle?.Vin || vehicle.Vin,
    Vrm: vehicle.Vrm || '',
    IsEdited: hasMultipleDerivatives ? false : Object.keys(editedVehicle).length > 0
  };
};

const QuickQuoteFindVehicle = (props: any) => {
  const { t } = useTranslation('QuickQuote');
  const { selectedVehicleId } = useSelectedVehicle();
  const { refine: setSearchQuery, query } = useSearchQuery();
  const { refine: setSearchCapIds } = useSearchCapIds();
  const navigate = useNavigate();
  const searchBarRef = useRef(null);
  const { isDe } = appStore.uiState;

  const [error, setError] = useState(false);
  const [vehicleClass, setVehicleClass] = useState('car');
  const [condition, setCondition] = useState<VehicleCondition>(VehicleCondition.Used);
  const [vrm, setVrm] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [hasMultipleDerivatives, setHasMultipleDerivatives] = useState(false);
  const [vrmError, setVrmError] = useState(false);
  const [editedVehicle, setEditedVehicle] = useState<Vehicle>({});

  const VrmOptions = [
    { key: 'used', value: t('FindAVehicle.used') },
    { key: 'new', value: t('FindAVehicle.new') }
  ];

  const searchState = useSearchState();
  const { vehicles } = useMetaData();
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const displayPagination = !selectedVehicleId && vehicles.length > 0 && searchState === 'idle';

  // Set initial values from query params
  useEffect(() => {
    if (props.query.vehicleType?.toLowerCase() === 'new' || props.query.vehicleType?.toLowerCase() === 'used') {
      setCondition(decodeURIComponent(props.query.vehicleType?.toLowerCase()) as VehicleCondition);
    }
    if (props.query.vrm) {
      setVrm(decodeURIComponent(props.query.vrm).toUpperCase());
    }
    if (props.query.search) {
      setSearchQuery(decodeURIComponent(props.query.search));
    } else if (props.query.searchTerms) {
      setSearchQuery(decodeURIComponent(props.query.searchTerms));
    }
    if (props.query.multipleDerivatives) {
      setHasMultipleDerivatives(decodeURIComponent(props.query.multipleDerivatives) === 'true');
      prepareVehicleData(props.capVehicle);
    }
    if (props.query.class) {
      setVehicleClass(decodeURIComponent(props.query.class));
    }
    if (props.quickQuote?.editingVehicle) {
      setEditedVehicle(props.quickQuote?.editingVehicle);
      props.onResetEditedVehicle();
    }
  }, [
    props.query.vehicleType,
    props.query.vrm,
    props.query.search,
    props.query.searchTerms,
    props.query.multipleDerivatives,
    props.query.class,
    props.derivatives,
    props.quickQuote?.editingVehicle,
    props.onResetEditedVehicle
  ]);

  // Update query params with the algolia search input values
  useEffect(() => {
    if (!hasMultipleDerivatives) {
      navigate({
        pathname: props.location.pathname,
        query: { ...props.query, search: encodeURIComponent(query) }
      });
    }
  }, [hasMultipleDerivatives, props.location.pathname, navigate, query]);

  const handleFetchVehicleSuccess = useCallback(
    (vehicle) => {
      const updatedVehicle = formatVehicleOnFetchSuccess(vehicle, vrm, editedVehicle, hasMultipleDerivatives);
      props.onVehicleChange(updatedVehicle);
    },
    [props, vrm, editedVehicle, hasMultipleDerivatives]
  );

  // Selecting vehicle from taxonomy
  useEffect(() => {
    if (selectedVehicleId) {
      const vehicle = vehicles.find((vehicle: any) => vehicle.objectID === selectedVehicleId);
      const formattedVehicle = formatVehicleObject(vehicle, condition);
      handleFetchVehicleSuccess(formattedVehicle);
    }
  }, [selectedVehicleId, vehicles, editedVehicle, handleFetchVehicleSuccess, condition]);

  const handleChangeCondition = (value: string) => {
    setCondition(value as VehicleCondition);

    if (value === 'new') {
      setVrmError(false);
    }

    navigate({
      pathname: props.location.pathname,
      query: { ...props.query, vehicleType: encodeURIComponent(value) }
    });
  };

  const handleVrmBlur = (name: string, value: string) => {
    navigate({
      pathname: props.location.pathname,
      query: { ...props.query, vrm: encodeURIComponent(value.toLowerCase()) }
    });
  };

  const handleChangeVrm = (_: string, value: string) => {
    setVrm(value.toUpperCase());
    setVrmError(condition === 'used' && !value);
  };

  // AlgoliaVehicle | VehicleVrmLookupV2Response
  const prepareVehicleData = (capVehicle: any) => {
    if (capVehicle.AccuracyScore === 2 || capVehicle.AccuracyScore === 3) {
      navigate({
        pathname: `/d/${props.params.dealershipId}/stock/${capVehicle.Id}/fix-issues`
      });
      return;
    }

    let updatedVehicle = capVehicle.CapData
      ? { ...capVehicle.CapData[0], Vin: capVehicle.Vin, DerivativeId: capVehicle.CapData[0].CapId }
      : { ...capVehicle };

    if (capVehicle.CapData?.length > 1) {
      setEditedVehicle(updatedVehicle);
      setHasMultipleDerivatives(true);
      const vehicleCapIds: string[] = capVehicle.CapData.map((capData: { CapId: string }) => capData.CapId);
      const vehicleClass: string = capVehicle.CapData[0]?.VehicleClass?.toLowerCase();
      setSearchCapIds(vehicleCapIds, vehicleClass);
    } else {
      updatedVehicle = formatVehicleObject(updatedVehicle, condition, vrm);
      handleFetchVehicleSuccess(updatedVehicle);
    }
  };

  const handleSelectMotorhomeOrCaravan = (visFormData: VisVehicle) => {
    const updatedVehicle = formatVehicleObject(
      {
        ...visFormData,
        Class: vehicleClass,
        Mileage: Number(visFormData.Mileage) || 0
      },
      visFormData.Condition as VehicleCondition,
      visFormData.Vrm
    );
    handleFetchVehicleSuccess(updatedVehicle);
  };

  const changeVehicleClass = (value: string) => {
    setVehicleClass(value);
    navigate({
      pathname: props.location.pathname,
      query: { ...props.query, class: value.toLowerCase() }
    });
  };

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('FindAVehicle.home'),
            path: `/d/${props.params.dealershipId}`
          },
          {
            name: t('FindAVehicle.find_a_vehicle'),
            path: `/d/${props.params.dealershipId}/findavehicle`
          }
        ]}
      />
      <Panel>
        <PanelHeader>{t('FindAVehicle.find_a_vehicle')}</PanelHeader>
        <PanelContent>
          {vehicleClass === VehicleClass.motorhome || vehicleClass === VehicleClass.touringCaravan ? (
            <VisQuotePage
              onSubmit={handleSelectMotorhomeOrCaravan}
              onUpdateVehicleClass={(value: string) => changeVehicleClass(value)}
              initialData={{
                Class: vehicleClass,
                Condition: editedVehicle?.Condition || condition,
                Mileage: editedVehicle?.Mileage || '',
                Vrm: editedVehicle?.Vrm || '',
                RegistrationDate: editedVehicle?.RegistrationDate || '',
                Vin: editedVehicle?.Vin || ''
              }}
              dealershipId={props.params.dealershipId}
              searchTerms={query}
            />
          ) : (
            <MobxForm onSubmit={() => {}} focusOnFirstElement className="vehicleForm">
              <div className={!props.fullWidth ? 'vehicleForm__inner' : ''}>
                {appStore.uiState.canQuoteMotorhomesAndCaravans && (
                  <MobxFormFieldGroup isInlineWide>
                    <MobxFormLabel>{t('FindAVehicle.class')}</MobxFormLabel>
                    <RadioButtonGroup
                      options={[
                        { label: t('FindAVehicle.car'), value: VehicleClass.car },
                        { label: t('FindAVehicle.lcv'), value: VehicleClass.lcv },
                        { label: t('FindAVehicle.bike'), value: VehicleClass.bike },
                        { label: t('FindAVehicle.motorhome'), value: VehicleClass.motorhome },
                        { label: t('FindAVehicle.caravan'), value: VehicleClass.touringCaravan }
                      ]}
                      name="Class"
                      isGroupDisabled={false}
                      onChange={(e) => changeVehicleClass(e.target.value)}
                      checkedValue={vehicleClass.toLowerCase()}
                      variant="inline"
                    />
                  </MobxFormFieldGroup>
                )}
                <MobxFormFieldGroup isInlineWide>
                  <MobxFormLabel>{t('FindAVehicle.what_is_the_condition_of_the_vehicle')}</MobxFormLabel>

                  <RadioButtonGroup
                    options={VrmOptions}
                    name="Condition"
                    onChange={(e) => handleChangeCondition(e.target.value)}
                    checkedValue={condition}
                    isGroupDisabled={false}
                    variant="inline"
                  />
                </MobxFormFieldGroup>

                <MobxFormFieldGroup isInlineWide error={vrmError}>
                  <MobxFormLabel htmlFor="Vrm">
                    <Tooltip
                      content={t(`FindAVehicle.you_don't_need a_vehicle_identifier`)}
                      open={isTooltipOpen}
                      defaultOpen={false}
                      onOpenChange={(e: boolean) => (condition === 'new' ? setIsTooltipOpen(e) : null)}
                      variant="message"
                    >
                      <div>
                        {isDe ? (
                          <div>{t('FindAVehicle.enter_dealer_reference')}</div>
                        ) : (
                          <div>{t('FindAVehicle.enter_the_vrm_or_vin')}</div>
                        )}
                      </div>
                    </Tooltip>
                  </MobxFormLabel>
                  <VrmLookup
                    id="Vrm"
                    onChange={handleChangeVrm}
                    onFetchVehicle={() => setError(false)}
                    onFetchVehicleError={() => setError(true)}
                    onFetchVehicleSuccess={prepareVehicleData}
                    onVrmBlur={handleVrmBlur}
                    shouldUseQuickQuoteLogic={true}
                    value={vrm}
                    dealershipId={props.params.dealershipId}
                    showLookupButton={true}
                    tryAlgoliaLookup={true}
                    disabled={false}
                  />
                  {vrmError && condition === 'used' && (
                    <div className="quickQuoteFindVehicle__vrmMandatory">
                      {isDe
                        ? t('FindAVehicle.dealer_reference_is_mandatory_for_used_vehicles')
                        : t('FindAVehicle.vrm_is_mandatory_for_a_used_vehicle')}
                    </div>
                  )}
                </MobxFormFieldGroup>
                {((vrm && (error || hasMultipleDerivatives || editedVehicle)) || condition === 'new') && (
                  <>
                    {error && (
                      <div className="quickQuoteFindVehicle__vrmError">
                        {t('FindAVehicle.we_couldnt_find_the_exact_vehicle_match_from_your_stock')}
                      </div>
                    )}
                    {hasMultipleDerivatives && (
                      <div className="quickQuoteFindVehicle__vrmError">{t('FindAVehicle.confirm_derivative')}</div>
                    )}
                    {!hasMultipleDerivatives && <SearchBox ref={searchBarRef} />}
                    <Hits />
                    {displayPagination && <Pagination onChange={() => scrollToRef(searchBarRef)} />}
                  </>
                )}
              </div>
            </MobxForm>
          )}
        </PanelContent>
      </Panel>
    </Page>
  );
};

const QuickQuoteFindVehicleContainer = ({ children, ...props }: any) => {
  return (
    <VisLookUp dealershipId={props.params.dealershipId}>
      <QuickQuoteFindVehicle {...props} />
    </VisLookUp>
  );
};

export default withRouter(QuickQuoteFindVehicleContainer);
