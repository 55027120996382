import { DisplayQuoteFormat, FinanceNavigatorEligibilityLenderDataType, QuoteRow, QuoteTypes } from '../../types';
import { AdjustedFnQuoteFigureComparisonType } from './hooks';

// Labels for header figures
export const HeaderFigureLabel = {
  MonthlyPayment: 'MonthlyPayment',
  Apr: 'Apr',
  AgreementTerm: 'AgreementTerm',
  TotalAmountPayable: 'TotalAmountPayable'
} as const;

export type HeaderFigureLabelType = keyof typeof HeaderFigureLabel;

// Type for header figures data
export type HeaderFiguresDataType = Pick<DisplayQuoteFormat, 'Apr' | 'AgreementTerm' | 'TotalAmountPayable'> & {
  MonthlyPayment: QuoteRow;
};

// Type for OriginalFiguresNowDifferent obj in Finance Navigator Eligibility obj - with its unformatted key names
export type FNOriginalFiguresType = {
  FollowingPayments?: number | null; // 'Monthly Payment' in the UI
  RepresentativeApr?: number | null; // 'APR' "
  Term?: number | null; // 'Agreement Term' "
  TotalAmountPayable?: number | null;
};

// Type for above FNOriginalFigures once its keys have been formatted
export type FormattedFnOriginalFiguresType = Record<HeaderFigureLabelType, number | null>;

// Props for HeaderFigures component
export type HeaderFiguresProps = {
  displayQuote: DisplayQuoteFormat;
  quote: QuoteTypes;
};

// Config type for header figures
export type HeaderFiguresConfigType = {
  canShowEligibilityModal: boolean;
  canShowViewDetailsButton: boolean;
  canShowAlertTag: boolean;
  canShowIndicativeAprTag: boolean;
  hasMaxMonthlyBudgetContent: boolean;
  hasRateForRiskContent: boolean;
};

// type to define eligibilityContent returned from getHeaderFiguresConfig, post-processing
export type FNEligibilityContentType = {
  quoteHasEligibilityContent: boolean;
  fnOriginalFiguresComparisonData?: AdjustedFnQuoteFigureComparisonType | null;
  fnLenderData?: FinanceNavigatorEligibilityLenderDataType | null;
};
