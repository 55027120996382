import { useTranslation } from 'react-i18next';
import { AppStore } from '~/modules/Stock/types/Types';
import { inject } from 'mobx-react';
import './quoteCardStatus.scss';

export const CFCEligibilityStatusName = {
  expired: 'expired',
  unknown: 'unknown',
  high: 'high',
  medium: 'medium',
  low: 'low'
} as const;

export type CFCEligibilityStatusNameType = typeof CFCEligibilityStatusName[keyof typeof CFCEligibilityStatusName];

export const getCFCEligibilityStatus = (matchRate: number): CFCEligibilityStatusNameType => {
  if (!matchRate || (matchRate < 0 && matchRate !== -2)) {
    return CFCEligibilityStatusName.unknown;
  } else if (matchRate === -2) {
    return CFCEligibilityStatusName.expired;
  } else if (matchRate > 50) {
    return CFCEligibilityStatusName.high;
  } else if (matchRate === 50) {
    return CFCEligibilityStatusName.medium;
  } else if (matchRate < 50) {
    return CFCEligibilityStatusName.low;
  } else {
    return CFCEligibilityStatusName.unknown;
  }
};

interface CFCEligibilityStatusProps {
  handleClick?: () => void;
  matchRate: number;
  personalScorePercentage?: number;
  appStore?: AppStore;
}

const CFCEligibilityStatus = ({
  matchRate,
  personalScorePercentage,
  handleClick,
  appStore
}: CFCEligibilityStatusProps) => {
  const { t } = useTranslation('QuoteCard');

  const cfcEligibilityStatus = getCFCEligibilityStatus(matchRate);
  const shouldDisplayViewDetailsModal =
    matchRate !== -1 && appStore?.uiState.canUseCfcIvendiv2 && personalScorePercentage !== null && handleClick;

  return (
    <div
      className={`quoteCard__cell quoteCardStatus quoteCardStatus--${cfcEligibilityStatus}`}
      data-th="application-eligibility-status"
    >
      <span className="quoteCardStatus__statusText">{t(`ApplicationStatus.${cfcEligibilityStatus}`)}</span>
      {shouldDisplayViewDetailsModal && (
        <button className="quoteCardStatus__viewDetails" onClick={handleClick}>
          {t('ApplicationStatus.view_details')}
        </button>
      )}
    </div>
  );
};

export default inject('appStore')(CFCEligibilityStatus);
