import { get, post } from '@ivendi/platform-api-utils';
import { ZodError } from 'zod';
import {
  FinanceScan,
  FinanceScanSchema,
  PreFillFinanceScanForCustomerResponseSchema,
  StartFinanceScanRequest,
  StartFinanceScanResponse,
  StartFinanceScanResponseSchema
} from './types';

export const startFinanceScan = async (
  dealershipId: string = '',
  params: StartFinanceScanRequest
): Promise<StartFinanceScanResponse> => {
  try {
    const data: unknown = await post(`v1/financescan/${dealershipId}/start`, params);
    return StartFinanceScanResponseSchema.parse(data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw error;
    }
    throw new Error('An unknown error occurred');
  }
};

export const getFinanceScanById = async (
  dealershipId: string = '',
  financeScanId: string = ''
): Promise<FinanceScan> => {
  try {
    const data: unknown = await get(`v1/financescan/${dealershipId}/by-id/${financeScanId}`);
    return FinanceScanSchema.parse(data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw error;
    }
    throw new Error('An unknown error occurred');
  }
};

export const preFillFinanceScanForCustomer = async (dealershipId: string, customerId: string) => {
  try {
    const data: unknown = await get(`v1/financescan/${dealershipId}/pre-fill-for-customer/${customerId}`);
    return PreFillFinanceScanForCustomerResponseSchema.parse(data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw error;
    }
    throw new Error('An unknown error occurred');
  }
};
