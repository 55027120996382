import { canSeeCashback } from '~/features';
import {
  DisplayQuoteFormat,
  FormattedQuote,
  FormattedQuoteKey,
  LoanType,
  QuoteTypes,
  QuoteCardVariantTypeEnum,
  QuoteSummaryVariantTypeEnum,
  QuoteVariants
} from '../types';
import getPaymentTableData from './getPaymentTableData';
import { findCompanionQuoteByType, FormatQuoteOptionsProp } from './getQuoteDisplaySchema';
import { getQuoteRowFormatType } from './getQuoteRowFormatType';
import getVapsFromQuoteObject from './getVapsFromQuoteObject';

/*
 * The order of the properties defines the order of the rows in the quote card
 * NB: When conditionally adding properties to an object doesn't guarantee the order
 */
export const formatSortAndNameQuoteKeys = (quote: QuoteTypes, options: FormatQuoteOptionsProp): FormattedQuote => {
  const formattedQuote = {
    ...getProductName(quote, options),
    ...getCashPrice(quote),
    ...getVehiclePrice(quote),
    ...getPartExchange(quote, options),
    ...getSettlementFigure(quote, options),
    ...getCashback(quote, options),
    ...getNegativeEquity(quote),
    ...getCashDeposit(quote, options),
    ...getDepositContribution(quote),
    ...getTotalDeposit(quote),
    ...getVapsFromQuoteObject(quote?.ValueAddedProducts),
    ...getTotalAmountOfCredit(quote),
    ...getAgreementTerm(quote),
    ...getAnnualMileage(quote, options),
    ...getExcessPerMile(quote),
    ...getAdministrationFees(quote),
    ...getOptionToPurchaseFee(quote),
    ...getPaymentTableData(
      quote.PaymentSchedule,
      quote.FirstPayment,
      quote.FinalPayment,
      quote.FollowedBy,
      quote.FollowingPayments
    ),
    ...getInterestCharges(quote),
    ...getAnnualFixedInterestRate(quote),
    ...getApr(quote),
    ...getTotalAmountPayable(quote)
  };

  return formattedQuote;
};

export const getNegativeEquity = (quote: QuoteTypes) => ({
  ...(quote &&
    quote.LoanType === LoanType.negativeEquityLoan &&
    quote.NegativeEquity !== null && {
      NegativeEquity: quote.NegativeEquity
    })
});

/*
 * When NegativeEquityLoan exists, in the VehicleLoan quote, PartExchange and OutstandingSettlement become 0s
 * When NegativeEquityLoan doesn't exist, in the VehicleLoan quote, PartExchange and OutstandingSettlement become 0s or positives
 * This means that the VehicleLoan will never be able to figure out if Negative Equity is there based on its own quote information,
 * But if the PartExchange and OutstandingSettlement are not 0s, then we know there's no NegativeEquity
 */
export const shouldShowDepositFields = (quote: QuoteTypes, options: FormatQuoteOptionsProp): boolean => {
  return (
    ((quote.LoanType === LoanType.vehicleLoan && !options.hasNegativeEquityLoan) ||
      quote.LoanType === LoanType.negativeEquityLoan ||
      quote.LoanType === LoanType.standardLoan) &&
    canSeeCashback()
  );
};

export const isQuoteSummaryPage = (options: FormatQuoteOptionsProp) => {
  return Object.keys(QuoteSummaryVariantTypeEnum).includes(options.variant);
};

export const isVehicleOrStandardLoan = (quote: QuoteTypes) => {
  return quote.LoanType === LoanType.vehicleLoan || quote.LoanType === LoanType.standardLoan;
};

export const isPcpQuote = (quote: QuoteTypes) => {
  // On companion quotes, the aggregate quote's Finance Type is "Default"
  // but we still need to display some information on it if the vehicle loan it contains is "PCP"
  // That's why for the aggregate quote we want to use the Finance Type of the vehicle loan
  const vehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanType.vehicleLoan);
  if (quote.LoanType === LoanType.standardLoan && vehicleLoan) {
    return vehicleLoan && vehicleLoan.FinanceType === 'PCP';
  }
  return quote.FinanceType === 'PCP';
};

export const getCashPrice = (quote: QuoteTypes) => ({
  ...(quote.LoanType === LoanType.standardLoan &&
    quote.CashPrice !== null && {
      CashPrice: quote.CashPrice
    })
});

export const getProductName = (quote: QuoteTypes, options: FormatQuoteOptionsProp) => {
  // Standard loan - summary pages only
  if (quote.LoanType === LoanType.standardLoan && isQuoteSummaryPage(options) && quote.ProductName !== null) {
    return {
      Product: quote.ProductName
    };
  }

  // Vehicle loan - always
  if (quote.LoanType === LoanType.vehicleLoan && quote.ProductName !== null) {
    return {
      Product: quote.ProductName
    };
  }
  return {};
};

export const getVehiclePrice = (quote: QuoteTypes) => ({
  ...((quote.LoanType === LoanType.vehicleLoan || quote.LoanType === LoanType.standardLoan) &&
    quote.VehiclePrice !== null && {
      VehiclePrice: quote.VehiclePrice
    })
});

export const getCashback = (quote: QuoteTypes, options: FormatQuoteOptionsProp) => {
  const totalCashbackVariants: QuoteVariants[] = [
    QuoteCardVariantTypeEnum.FinanceQuote,
    QuoteCardVariantTypeEnum.FinanceNavigatorCheck,
    QuoteCardVariantTypeEnum.SendDeal
  ];
  return {
    ...(shouldShowDepositFields(quote, options) &&
      quote.LoanType !== LoanType.negativeEquityLoan &&
      !options.isBnpp &&
      quote.FunderCode !== 'CRE' && {
        Cashback: totalCashbackVariants.includes(options.variant) ? quote?.TotalCashback : quote?.Cashback
      })
  };
};

export const getCashDeposit = (quote: QuoteTypes, options: FormatQuoteOptionsProp) => {
  if (shouldShowDepositFields(quote, options)) {
    switch (options.variant) {
      /*
       * QuoteCards on Quote Results coming from the proxy /dealerplatform endpoint
       * are of types FinanceQuote and SendDeal where the CashDeposit property exists
       */
      case QuoteCardVariantTypeEnum.FinanceQuote:
      case QuoteCardVariantTypeEnum.FinanceNavigatorCheck:
      case QuoteSummaryVariantTypeEnum.ProductComparePrintSummary:
      case QuoteCardVariantTypeEnum.SendDeal:
        return {
          CashDeposit: quote.CashDeposit ? quote.CashDeposit : quote.RequestedDeposit
        };
      case QuoteCardVariantTypeEnum.FinanceApplication:
      case QuoteSummaryVariantTypeEnum.CarFinanceCheckSummary:
      case QuoteSummaryVariantTypeEnum.FinanceApplicationSummary:
        if (quote.LoanType === LoanType.standardLoan) {
          return {
            CashDeposit: quote.ActualCashDeposit
          };
        }
        return {};
      case QuoteCardVariantTypeEnum.SelfServiceDeals:
      case QuoteCardVariantTypeEnum.SavedQuote:
      case QuoteSummaryVariantTypeEnum.SelfServiceSummary:
        return {
          CashDeposit: quote.RequestedDeposit
        };
      default:
        return {};
    }
  }
};

export const getDepositContribution = (quote: QuoteTypes) => {
  return {
    ...(isVehicleOrStandardLoan(quote) &&
      quote.FinanceDepositAllowance &&
      quote.FinanceDepositAllowance > 0 && {
        DepositContribution: quote.FinanceDepositAllowance
      })
  };
};

export const getSettlementFigure = (quote: QuoteTypes, options: FormatQuoteOptionsProp) => ({
  ...(shouldShowDepositFields(quote, options) && {
    SettlementFigure: quote.OutstandingSettlement
  })
});

export const getPartExchange = (quote: QuoteTypes, options: FormatQuoteOptionsProp) => ({
  ...(shouldShowDepositFields(quote, options) && {
    PartExchange: quote.PartExchange
  })
});

export const getTotalDeposit = (quote: QuoteTypes) => ({
  ...(isVehicleOrStandardLoan(quote) &&
    quote.TotalDeposit !== null && {
      TotalDeposit: quote.TotalDeposit
    })
});

export const getInterestCharges = (quote: QuoteTypes) => ({
  InterestCharges: quote?.InterestCharges
});

export const getTotalAmountOfCredit = (quote: QuoteTypes) => ({
  TotalAmountOfCredit: quote.TotalAmountOfCredit || quote.Advance
});

export const getAgreementTerm = (quote: QuoteTypes) => ({
  AgreementTerm: quote.Term
});

export const getAnnualMileage = (quote: QuoteTypes, options: FormatQuoteOptionsProp) => {
  const allowedNonPcpVariants: QuoteVariants[] = [
    QuoteCardVariantTypeEnum.FinanceApplication,
    QuoteSummaryVariantTypeEnum.CarFinanceCheckSummary,
    QuoteSummaryVariantTypeEnum.FinanceApplicationSummary,
    QuoteSummaryVariantTypeEnum.ProductComparePrintSummary
  ];
  return {
    ...(isVehicleOrStandardLoan(quote) &&
      (isPcpQuote(quote) || allowedNonPcpVariants.includes(options.variant)) &&
      quote.AnnualDistanceQuoted !== null && {
        AnnualMileage: quote.AnnualDistanceQuoted
      })
  };
};

export const getAnnualFixedInterestRate = (quote: QuoteTypes) => ({
  AnnualFixedInterestRate: quote?.AnnualRate
});

export const getApr = (quote: QuoteTypes) => ({
  Apr: quote.RepresentativeApr
});

export const getTotalAmountPayable = (quote: QuoteTypes) => ({
  TotalAmountPayable: quote.TotalAmountPayable
});

export const getOptionToPurchaseFee = (quote: QuoteTypes) => ({
  ...(isVehicleOrStandardLoan(quote) &&
    quote.OptionToPurchaseFee &&
    quote.OptionToPurchaseFee > 0 && {
      // TotalFees or Fees were used previously to display OptionToPurchaseFee, but can we just use OptionToPurchaseFee?
      // OptionToPurchaseFee: quote.TotalFees || quote.Fees
      OptionToPurchaseFee: quote.OptionToPurchaseFee
    })
});

export const getAdministrationFees = (quote: QuoteTypes) => {
  let fees = {};

  if (isVehicleOrStandardLoan(quote)) {
    if (quote.AcceptanceFeeFirst && quote.AcceptanceFeeFirst > 0) {
      fees = { ...fees, AdministrationFeeA: quote.AcceptanceFeeFirst };
    }

    if (quote.AcceptanceFeeLast && quote.AcceptanceFeeLast > 0) {
      fees = { ...fees, AdministrationFeeB: quote.AcceptanceFeeLast };
    }
  }

  return fees;
};

export const getExcessPerMile = (quote: QuoteTypes) => ({
  ...(isVehicleOrStandardLoan(quote) &&
    isPcpQuote(quote) && {
      ExcessPerMile: quote.ChargePerOverDistanceUnit
    })
});

export const formatQuoteToDisplay = (quote: FormattedQuote): DisplayQuoteFormat => {
  const formattedQuote = (Object.keys(quote) as FormattedQuoteKey[]).reduce<DisplayQuoteFormat>(
    (accumulator, label) => {
      return {
        ...accumulator,
        [label]: getQuoteRowFormatType(label, quote[label])
      };
    },
    {} as DisplayQuoteFormat
  );

  return formattedQuote;
};
