import { useTranslation } from 'react-i18next';
import Button from '../../../Common/Button/Button';
import EligibilityModalGaugeSection from '../../components/EligibilityModalGaugeSection';
import Modal from '../../../Common/Modal/Modal';
import { FNEligibilityModalContentType } from './types';
import { getFNEligibilityModalContent } from './utils';
import { EligibilityOfferedType } from '~/components/QuoteCard/types';
import './financeNavigatorEligibilityModal.scss';

export const FinanceNavigatorEligibilityModalContents = (fnModalContents: FNEligibilityModalContentType) => {
  const { t } = useTranslation('Quoting');

  if (!fnModalContents) {
    return null;
  }

  const { fnEligibilityStatus, header, indicativeText, lenderMessages } = fnModalContents;

  return (
    <main className="financeNavigatorEligibilityModalContents" data-th="FinanceNavigatorEligibilityModalContents">
      <EligibilityModalGaugeSection
        fnEligibilityStatus={fnEligibilityStatus}
        header={header && t(`FinanceNavigatorEligibilityModal.${header}`)}
      />

      {lenderMessages && lenderMessages.length && (
        <section className="financeNavigatorEligibilityModalContents__lenderMessages" data-th="lender-messages">
          {lenderMessages && lenderMessages.length === 1 ? (
            <p>{lenderMessages[0]}</p>
          ) : (
            <ul>
              {fnModalContents.lenderMessages?.map((message, index) => (
                <li key={`fnLenderMessage-${index}`}>{message}</li>
              ))}
            </ul>
          )}
        </section>
      )}

      {indicativeText && (
        <p className="financeNavigatorEligibilityModalContents__indicative" data-th="indicative-text">
          {t(`FinanceNavigatorEligibilityModal.${indicativeText}`)}
        </p>
      )}
    </main>
  );
};

type FNEligibilityResultModalPropsType = {
  status: EligibilityOfferedType;
  lenderMessages: string[] | null;
  onClose: () => void;
  isOpen: boolean;
};

const FinanceNavigatorEligibilityModal = ({
  status,
  lenderMessages,
  isOpen,
  onClose
}: FNEligibilityResultModalPropsType) => {
  const { t } = useTranslation('Quoting');
  const fnModalContents = getFNEligibilityModalContent(status, lenderMessages);
  const modalTitle = fnModalContents?.title ? t(`FinanceNavigatorEligibilityModal.${fnModalContents?.title}`) : null;
  return (
    <Modal isOpen={isOpen} onClose={onClose} id="financeNavigatorEligibility" title={modalTitle}>
      {fnModalContents && <FinanceNavigatorEligibilityModalContents {...fnModalContents} />}
      <footer>
        <Button type="button" onClick={() => onClose()} buttonStyle="cancel">
          {t('FinanceNavigatorEligibilityModal.close')}
        </Button>
      </footer>
    </Modal>
  );
};

export default FinanceNavigatorEligibilityModal;
