import { StatusType } from '~/components/QuoteCard/components/Status/LenderApplicationStatus';
import { canSeeInterceptor } from '~/features';

export type LenderStatusNameType = StatusType | 'Prospect' | 'Refunded' | 'Overdue' | 'Reserved' | 'Cancelled';
type IconNameType = 'pending' | 'alert' | 'approved' | 'rejected' | null;
type LenderStatusColorType = 'low' | 'medium' | 'high' | 'blue' | null;

interface LenderStatusStyle {
  lenderStatusColor: LenderStatusColorType;
  iconName: IconNameType;
  customClass?: string | null;
}

export const getLenderStatusStyle = (status: LenderStatusNameType): LenderStatusStyle => {
  let lenderStatusColor: LenderStatusColorType = null;
  let iconName: IconNameType = null;
  let customClass = null;

  switch (status) {
    case 'NoDecisionYet':
    case 'ProblemPayout':
    case 'Error':
    case 'WaitingForFurtherInformation':
    case 'NotTakenUp':
    case 'Rejected':
    case 'NotProceeding':
    case 'Overdue':
    case 'Cancelled':
      lenderStatusColor = 'low';
      iconName = 'rejected';
      break;

    case 'Unknown':
    case 'Referred':
    case 'Pending':
    case 'Prospect':
    case 'Submitting':
    case 'SentForPayout':
    case 'Other':
      lenderStatusColor = 'medium';
      iconName = 'pending';
      customClass = status === 'Submitting' && canSeeInterceptor() ? 'progress' : null;
      break;

    case 'Intercepted':
      lenderStatusColor = 'medium';
      iconName = 'alert';
      break;

    case 'Accepted':
    case 'ConditionalAccept':
    case 'RateAdjustedAccepted':
    case 'ProposalAdjustedAccepted':
    case 'PaidOut':
    case 'Submitted':
    case 'Refunded':
      lenderStatusColor = 'high';
      iconName = 'approved';
      break;

    case 'Reserved':
      lenderStatusColor = 'blue';
      iconName = null;
      break;

    default:
      lenderStatusColor = null;
      iconName = null;
  }
  return {
    lenderStatusColor,
    iconName,
    ...(customClass && { customClass })
  };
};
