import _ from 'lodash';
import moment from 'moment';
import { parsePaymentSchedules } from './quoteParser';

function updateLatestApplicantInfoOnVehicles(consumer) {
  let VehiclesOfInterest = _.map(consumer.VehiclesOfInterest, (vehicle) => {
    const applicants = _.filter(consumer.FinanceApplications, { VehicleId: vehicle.VehicleId });

    return Object.assign({}, vehicle, {
      financeDeals: applicants.length,
      latestStatus: applicants[0] ? applicants[0].LenderStatus : null
    });
  });

  return Object.assign({}, consumer, { VehiclesOfInterest });
}

export function getFinanceUpdates(
  FinanceApplications,
  FinanceScans,
  CfcProspectResultSets,
  SavedQuotes,
  SelfServiceCodes,
  PushDeals
) {
  FinanceApplications = FinanceApplications.filter(
    (item) =>
      item.Quote.VehiclePrice !== 0 &&
      item.Quote &&
      item.Quote.ProductName &&
      item.Quote.ProductName.includes('VAP') === false &&
      item.Quote.ProductName.includes('Negative Equity') === false
  );

  return _.chain([])
    .concat(
      _.map(FinanceApplications, (item) => {
        return {
          Type: 'FinanceApplications',
          VehicleId: item.VehicleId,
          LastUpdated: moment(item.LastModifiedTimestamp).valueOf(),
          Price: _.get(item, 'Quote.VehiclePrice') || _.get(item, 'Quote.CashPrice')
        };
      }),
      _.map(FinanceScans, (item) => {
        return {
          Type: 'FinanceScans',
          VehicleId: item.VehicleId,
          LastUpdated: moment(item.CreatedDate).valueOf(),
          Price: _.get(item, 'Results[0].OfferedQuote.VehiclePrice') || _.get(item, 'Results[0].OfferedQuote.CashPrice')
        };
      }),
      _.map(CfcProspectResultSets, (item) => {
        return {
          Type: 'CfcProspects',
          VehicleId: item.VehicleId,
          LastUpdated: moment(item.Created).valueOf(),
          Price: _.get(item, 'Quotes[0].VehiclePrice') || _.get(item, 'Quotes[0].CashPrice')
        };
      }),
      _.map(SavedQuotes, (item) => {
        return {
          Type: 'SavedQuotes',
          VehicleId: item.VehicleId,
          LastUpdated: moment(item.SavedTime).valueOf(),
          Price: _.get(item, 'Quote.VehiclePrice') || _.get(item, 'Quote.CashPrice')
        };
      }),
      _.map(SelfServiceCodes, (item) => {
        return {
          Type: 'SentDeals',
          VehicleId: item.VehicleId,
          LastUpdated: moment(item.SavedDate).valueOf(),
          Price: _.get(item, 'Quotes[0].VehiclePrice') || _.get(item, 'Quotes[0].CashPrice')
        };
      }),
      _.map(PushDeals, (item) => {
        let price = JSON.parse(item.quotes[0].platformMeta).VehiclePrice;
        return {
          Type: 'PushDeals',
          VehicleId: item.dealerPlatformVehicleOfInterestId,
          LastUpdated: moment(item.addedAt).valueOf(),
          Price: price
        };
      })
    )
    .orderBy('LastUpdated', 'desc')
    .value();
}

export function parseConsumer(rawData) {
  let {
    ConsumerApplicants = [],
    CorporateApplicants = [],
    VehiclesOfInterest = [],
    FinanceScans = [],
    Address = {},
    DealerNotes,
    AssignedTo,
    SavedQuotes,
    ...consumerDetails
  } = rawData;

  let financeApplications = [];
  let financeScans = FinanceScans;

  if (consumerDetails.CustomerType === 'Corporate' && CorporateApplicants) {
    financeApplications = _.slice(CorporateApplicants, 0, 100);
  } else if (ConsumerApplicants) {
    financeApplications = _.slice(ConsumerApplicants, 0, 100);
  }

  _.each(financeApplications, (application) => {
    if (application.Quote) {
      application.Quote.PaymentSchedule = parsePaymentSchedules(application.Quote);
    }
    if (application.Tags) {
      application.Tags = JSON.parse(application.Tags);
    }
  });

  _.each(SavedQuotes, (quote) => {
    if (quote.Quote) {
      quote.Quote.PaymentSchedule = parsePaymentSchedules(quote.Quote);
    }
  });

  _.each(consumerDetails.CfcProspectResultSets, (resultSet) => {
    if (resultSet.Quotes) {
      _.each(resultSet.Quotes, (quote) => {
        if (quote) {
          quote.PaymentSchedule = parsePaymentSchedules(quote);
        }
      });
    }
  });

  _.each(financeScans, (resultSet) => {
    if (resultSet.Quotes) {
      _.each(resultSet.Quotes, (quote) => {
        if (quote) {
          quote.PaymentSchedule = parsePaymentSchedules(quote);
        }
      });
      _.each(resultSet.Results, (result) => {
        if (result.OfferedQuote) {
          result.OfferedQuote.PaymentSchedule = parsePaymentSchedules(result.OfferedQuote);
        }
      });
    }
  });

  _.each(consumerDetails.SelfServiceCodes, (resultSet) => {
    if (resultSet.Quotes) {
      _.each(resultSet.Quotes, (quote) => {
        if (quote) {
          quote.PaymentSchedule = parsePaymentSchedules(quote);
        }
      });
    }
  });

  let updates = getFinanceUpdates(
    financeApplications,
    financeScans,
    consumerDetails.CfcProspectResultSets,
    SavedQuotes,
    consumerDetails.SelfServiceCodes,
    consumerDetails.PushDeals
  );

  let grouped = _.groupBy(updates, 'VehicleId');

  let vehiclesOfInterest = _.chain(VehiclesOfInterest)
    .slice(0, 100)
    .orderBy('CreatedTimestamp', 'desc')
    .map((vehicle) => {
      let { Status, ...vehicleDetails } = vehicle;

      Status.DateOfHandover = Status.DateOfHandover ? moment(Status.DateOfHandover).format('DD/MM/YYYY') : undefined;
      Status.DateOfPurchase = Status.DateOfPurchase ? moment(Status.DateOfPurchase).format('DD/MM/YYYY') : undefined;
      Status.RemoveFromDashboard = vehicle.HiddenOnDashboard;
      Status.FunderCode = Status.OtherFunder ? 'Other' : Status.FunderCode;

      let isSold = vehicle.Status && vehicle.Status.Status ? true : false;

      if (vehicleDetails.CapId === -1) {
        vehicleDetails.CapId = null;
      }
      return {
        Status,
        LatestPrice:
          isSold && vehicle.Status.CashPrice
            ? vehicle.Status.CashPrice
            : _.get(grouped, vehicle.VehicleId + '.[0].Price'),
        LatestUpdateOn: _.get(grouped, vehicle.VehicleId + '.[0].Type'),
        LastUpdated: _.get(grouped, vehicle.VehicleId + '.[0].LastUpdated') || 0,
        isSold: isSold,
        ...vehicleDetails
      };
    })
    .value();

  AssignedTo = _.map(AssignedTo, (assignee) => ({
    Id: assignee.DealershipUserId,
    Firstname: assignee.Firstname,
    Surname: assignee.Surname,
    Avatar: {
      Colour: assignee.Avatar && assignee.Avatar.Colour
    }
  }));

  return updateLatestApplicantInfoOnVehicles(
    Object.assign({}, consumerDetails, {
      DealerNotes,
      AssignedTo,
      SavedQuotes,
      Address: Address || {},
      VehiclesOfInterest: vehiclesOfInterest || [],
      FinanceApplications: financeApplications,
      FinanceScans: financeScans,
      LatestVehicle: _.get(updates, '[0].VehicleId')
    })
  );
}
