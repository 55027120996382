import { Control, Controller, FormProvider, useFieldArray, useForm, UseFormSetValue } from 'react-hook-form';
import { FieldGroupRHF } from '~/components/Common/Form/FieldGroupRHF';
import FormFooter from '~/components/Common/Form/FormFooter';
import SelectInput from '~/components/Common/Form/SelectInput';
import { addressHistoryValidator, useRhfValidationResolver } from './validation';
import ApplicationCard from '~/components/Application/components/ApplicationCard';
import MiniPanel from '~/components/Common/MiniPanel';
import MiniPanelHeader from '~/components/Common/MiniPanelHeader';
import YearsMonthsField from '~/components/Common/Form/YearsMonthsField';
import { useState, useEffect, useCallback } from 'react';
import { FinanceNavigatorFormAddressHistory } from '../types/financeNavigatorForm';
import Input from '~/components/Common/Form/Input';
import ExclamationWarningWithButton from '~/components/Common/ExclamationWarningWithButton';
import { useTranslation } from 'react-i18next';
import { getYearsAndMonthsFromTotalMonths } from '~/utils/application';
import { FinanceNavigatorSubTitle, FinanceNavigatorTitle } from '../components/FinanceNavigatorTitle';
import Divider from '~/components/Common/Divider';
import PostcodeLookup from '~/components/Common/PostcodeLookup/PostcodeLookup';
import { LookupAddressResult } from '~/api/postcodeLookup/postcodeLookupApi';
import { getInitialFormData } from '../hooks/useFinanceNavigatorForm';

export interface FinanceNavigatorAddressHistoryProps {
  addressHistory: FinanceNavigatorFormAddressHistory;
  onSubmit: (addressHistory: FinanceNavigatorFormAddressHistory) => void;
  onCancel: () => void;
}

export const FinanceNavigatorAddressHistory = ({
  addressHistory,
  onSubmit,
  onCancel
}: FinanceNavigatorAddressHistoryProps) => {
  const { t } = useTranslation('FinanceNavigator');
  return (
    <div>
      <FinanceNavigatorTitle text={t('AddressHistoryForm.address_history')} />
      <FinanceNavigatorSubTitle text={t('AddressHistoryForm.please_enter_at_least_3_years_of_address_history')} />
      <AddressHistoryFormRhf onSubmit={onSubmit} onCancel={onCancel} defaultValues={addressHistory} />
    </div>
  );
};

type AddressHistoryFormRhfProps = {
  defaultValues: FinanceNavigatorFormAddressHistory;
  onSubmit: (data: FinanceNavigatorFormAddressHistory) => void;
  onCancel: () => void;
};

export const AddressHistoryFormRhf = ({ onSubmit, onCancel, defaultValues }: AddressHistoryFormRhfProps) => {
  const { t } = useTranslation('FinanceNavigator');
  const [editingAddress, setEditingAddress] = useState(-1);
  const resolver = useRhfValidationResolver(addressHistoryValidator);
  const methods = useForm<FinanceNavigatorFormAddressHistory>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver
  });
  const { handleSubmit, formState, getValues, control, setValue } = methods;
  const { fields, remove, append } = useFieldArray({ control, name: 'Items' });
  const [showTotalMonthsError, setShowTotalMonthsError] = useState(false);

  useEffect(() => {
    if (fields.length === 1) {
      setEditingAddress(0);
    }
  }, [fields]);

  const handleRemoveAddress = (index: number) => {
    remove(index);
  };

  const handleAddAddress = () => {
    const initialFormData = getInitialFormData();
    append(initialFormData.AddressHistory.Items[0]);
    setEditingAddress(fields.length);
    setShowTotalMonthsError(false);
  };

  const handleSubmitWithValidation = (data: FinanceNavigatorFormAddressHistory) => {
    const totalMonths = data.Items.reduce(
      (curr: number, next: any) => curr + (next.TimeAtAddress?.TotalMonths || 0),
      0
    );
    if (totalMonths >= 36) {
      onSubmit({ ...data, TotalMonths: totalMonths });
    } else {
      setShowTotalMonthsError(true);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitWithValidation)} data-th="addressHistoryForm">
        {fields &&
          fields.map((field, index) => {
            // @ts-ignore
            const errors = formState.errors.Items?.[index] || {};
            const errorsCount = Object.keys(errors).length;
            return (
              <MiniPanel key={`mini_${index}`}>
                <MiniPanelHeader title={`Address ${index + 1}`} />
                {/* Preview Mode */}
                {fields.length > 1 && editingAddress !== index ? (
                  <ApplicationCard
                    type="address"
                    data={getValues(`Items.${index}`)}
                    edit={() => setEditingAddress(index)}
                    remove={() => handleRemoveAddress(index)}
                    showRemove={fields.length > 1}
                    errorCount={errorsCount}
                    submitted={false}
                    key={`card_${field.id}`}
                  />
                ) : (
                  <EditAddressHistoryForm
                    key={`form_${field.id}`}
                    control={control}
                    setValue={setValue}
                    index={index}
                  />
                )}
              </MiniPanel>
            );
          })}
        {showTotalMonthsError && (
          <ExclamationWarningWithButton
            title={t('AddressHistoryForm.thank_you')}
            message={t('AddressHistoryForm.but_you_need_to_give_at_least_3_years_of_address_history')}
            buttonText={t('AddressHistoryForm.add_new_address')}
            onClick={handleAddAddress}
          />
        )}
        <FormFooter
          submitLabel={t('FinanceNavigator.next')}
          submitDisabled={!methods.formState.isValid}
          hideSubmit={false}
          submittingState=""
          cancelLabel={t('FinanceNavigator.back')}
          onCancel={onCancel}
        />
      </form>
    </FormProvider>
  );
};

const EditAddressHistoryForm = ({
  index,
  control,
  setValue
}: {
  index: number;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
}) => {
  const { t } = useTranslation('FinanceNavigator');
  const handleLookupResult = useCallback(
    (address: LookupAddressResult) => {
      setValue(`Items.${index}.BuildingName`, address.BuildingName, { shouldValidate: true });
      setValue(`Items.${index}.BuildingNumber`, address.BuildingNumber, { shouldValidate: true });
      setValue(`Items.${index}.SubBuilding`, address.SubBuilding, { shouldValidate: true });
      setValue(`Items.${index}.Street`, address.Street, { shouldValidate: true });
      setValue(`Items.${index}.District`, address.District, { shouldValidate: true });
      setValue(`Items.${index}.PostTown`, address.PostTown, { shouldValidate: true });
      setValue(`Items.${index}.Postcode`, address.Postcode, { shouldValidate: true });
    },
    [index, setValue]
  );
  return (
    <div className="financeNavigatorAddressHistory__editForm">
      <PostcodeLookup onSubmit={handleLookupResult} />
      <Divider />
      {/* Address Form */}
      <Controller
        name={`Items.${index}.SubBuilding`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.flat_room_floor')} name={field.name}>
            <Input
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              isInvalid={fieldState.invalid}
              placeholder={t('AddressHistoryForm.flat_room_floor_placeholder')}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.BuildingName`}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FieldGroupRHF label={t('AddressHistoryForm.building_name')} name={field.name}>
              <Input
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                onBlur={field.onBlur}
                isInvalid={fieldState.invalid}
              />
            </FieldGroupRHF>
          );
        }}
      />
      <Controller
        name={`Items.${index}.BuildingNumber`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.building_number')} name={field.name}>
            <Input
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.Street`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.street')} name={field.name}>
            <Input
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.District`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.district')} name={field.name}>
            <Input
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.PostTown`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.city_town')} name={field.name}>
            <Input
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.Postcode`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.postcode')} name={field.name}>
            <Input
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              onBlur={field.onBlur}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.Residency`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.residency')} name={field.name}>
            {/* @ts-ignore - missing properties */}
            <SelectInput
              options="Residency"
              id={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(_id: string, value: string) => field.onChange(value)}
              isInvalid={fieldState.invalid}
              dataThook={field.name}
            />
          </FieldGroupRHF>
        )}
      />
      <Controller
        name={`Items.${index}.TimeAtAddress.TotalMonths`}
        control={control}
        render={({ field, fieldState }) => (
          <FieldGroupRHF label={t('AddressHistoryForm.time_at_address')} name={field.name}>
            <YearsMonthsField
              id={field.name}
              name={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(totalMonths: number) => {
                const { years, months } = getYearsAndMonthsFromTotalMonths(totalMonths);
                setValue(`Items.${index}.TimeAtAddress.Months`, months);
                setValue(`Items.${index}.TimeAtAddress.Years`, years);
                field.onChange(totalMonths);
              }}
              isInvalid={fieldState.invalid}
            />
          </FieldGroupRHF>
        )}
      />
    </div>
  );
};
