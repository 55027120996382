import { useTranslation } from 'react-i18next';
import { getLenderUpdatesColor } from '~/core/lenderUpdatesColor';
import './quoteCardStatus.scss';

type LenderApplicationStatusProps = {
  status?: StatusType;
};

const LenderApplicationStatus = ({ status }: LenderApplicationStatusProps) => {
  const { t } = useTranslation('QuoteCard');
  return (
    <div
      className={`quoteCard__cell quoteCardStatus quoteCardStatus--${getLenderUpdatesColor(status)}`}
      data-th="application-eligibility-status"
    >
      {t(`ApplicationStatus.${status}`)}
    </div>
  );
};

export type StatusType =
  | 'Submitting'
  | 'Accepted'
  | 'ConditionalAccept'
  | 'Rejected'
  | 'NoDecisionYet'
  | 'Referred'
  | 'PaidOut'
  | 'Pending'
  | 'ProblemPayout'
  | 'Submitted'
  | 'NotTakenUp'
  | 'WaitingForFurtherInformation'
  | 'SentForPayout'
  | 'NotProceeding'
  | 'Other'
  | 'Error'
  | 'ProposalAdjustedAccepted'
  | 'ContactLender'
  | 'RateAdjustedAccepted'
  | 'NoChange'
  | 'Timeout'
  | 'Unknown'
  | 'NotSubmitted'
  | 'Medium'
  | 'Intercepted';

export default LenderApplicationStatus;
