import { hasCompanionQuotes } from '~/features';
import { QuoteTypes, QuoteCardVariantType, PreApprovalType, QuoteCardVariantTypeEnum } from './types';
import { isFinanceNavigatorEligibilityQuote } from './components/HeaderFigures/utils';
import { CustomerFinanceApplication } from '~/types/application';

type UseGetQuoteChecksProps = {
  quote: QuoteTypes;
  variant: QuoteCardVariantType;
  application?: CustomerFinanceApplication;
};

export const useGetQuoteChecks = ({ quote, variant, application }: UseGetQuoteChecksProps) => {
  const isRequote = quote?.isRequote === true;
  const isCombinedQuote = quote?.PersonalLoan !== undefined || quote?.NegativeEquityLoan !== undefined;
  const isFinanceQuote = variant === QuoteCardVariantTypeEnum.FinanceQuote;
  const isFinanceApplication = variant === QuoteCardVariantTypeEnum.FinanceApplication;
  const isSavedQuote = variant === QuoteCardVariantTypeEnum.SavedQuote;
  const isSendDeal = variant === QuoteCardVariantTypeEnum.SendDeal;

  const isFinanceNavigatorCheckResult = variant === QuoteCardVariantTypeEnum.FinanceNavigatorCheck;
  const isSavedFinanceNavigatorCheck = variant === QuoteCardVariantTypeEnum.SavedFinanceNavigatorCheck;
  const isFinanceNavigatorCheck = isFinanceNavigatorCheckResult || isSavedFinanceNavigatorCheck;

  const isCarFinanceCheck = variant === QuoteCardVariantTypeEnum.CarFinanceCheck;
  const isRiskBasedPricingQuote = quote?.CreditTier && quote.CreditTier !== 'None' && !isRequote;

  // Flags that support different types of journeys - R4R, Eligibility, PreApproval
  const isFnRateForRiskQuote = quote?.PreApprovalType === PreApprovalType.RateForRisk;
  const isFnEligibilityQuote = quote?.PreApprovalType === PreApprovalType.EligibilityCheck;
  const isFnEligibilityCheckAvailable = isFnRateForRiskQuote || isFnEligibilityQuote;
  const supportsPreApproval = quote?.SupportsPreApproval;

  const isPreApprovedFinanceApplication =
    isFinanceApplication && application?.Agreements?.[0]?.PreApprovalData?.Success === true;
  const canShowPreApprovalAvailable = supportsPreApproval && !isRequote && (isSavedQuote || isFinanceQuote);

  const canShowEligibilityResult =
    isFinanceNavigatorCheck &&
    isFinanceNavigatorEligibilityQuote(quote) &&
    quote?.Eligibility?.EligibilityOffered !== undefined;
  const canShowEligibilityAvailable =
    isFnEligibilityCheckAvailable && !isFinanceApplication && !canShowEligibilityResult;

  return {
    isRequote,
    isCombinedQuote,
    isFinanceQuote,
    isFinanceApplication,
    isSavedQuote,
    isSendDeal,
    isCarFinanceCheck,
    isFinanceNavigatorCheck,
    isFinanceNavigatorCheckResult,
    isSavedFinanceNavigatorCheck,
    isRiskBasedPricingQuote,
    hasCompanionQuotes: hasCompanionQuotes(quote),
    isFnRateForRiskQuote,
    isFnEligibilityQuote,
    isFnEligibilityCheckAvailable,
    supportsPreApproval,
    isPreApprovedFinanceApplication,
    canShowEligibilityResult,
    canShowEligibilityAvailable,
    canShowPreApprovalAvailable
  };
};
