import { useMutation, useQuery } from '@tanstack/react-query';
import { FinanceNavigatorForm } from '~/components/FinanceNavigator/types/financeNavigatorForm';
import { preFillFinanceScanForCustomer, startFinanceScan } from '.';
import { mapFormDataToStartFinanceScan } from './utils';
import { PreFillFinanceScanForCustomerResponse, StartFinanceScanResponse } from './types';

export const useStartFinanceScan = (
  dealershipId: string,
  quoteIds: string[],
  onSuccess?: (data: StartFinanceScanResponse) => void
) => {
  return useMutation({
    mutationFn: (formData: FinanceNavigatorForm) => {
      return startFinanceScan(dealershipId, mapFormDataToStartFinanceScan(formData, quoteIds));
    },
    onSuccess
  });
};

export const SERVICE_PREFIX = 'finance-scan';

export const usePreFillForCustomer = (
  dealershipId?: string,
  customerId?: string,
  onSuccess?: (formData: PreFillFinanceScanForCustomerResponse) => void
) => {
  return useQuery({
    queryKey: [SERVICE_PREFIX, dealershipId ?? '', customerId ?? ''],
    queryFn: () => {
      if (dealershipId && customerId) {
        return preFillFinanceScanForCustomer(dealershipId, customerId);
      }
    },
    onSuccess,
    enabled: !!dealershipId && !!customerId
  });
};
