import { FinanceNavigatorForm } from '~/components/FinanceNavigator/types/financeNavigatorForm';
import { StartFinanceScanRequest } from './types';
import moment from 'moment';

export const mapFormDataToStartFinanceScan = (
  { PersonalDetails, AddressHistory, EmploymentHistory, AffordabilityDetails }: FinanceNavigatorForm,
  quoteIds: string[]
): StartFinanceScanRequest => ({
  QuoteIds: quoteIds,
  Identity: {
    Names: {
      Title: PersonalDetails.Title,
      Firstname: PersonalDetails.FirstName,
      Middlenames: PersonalDetails.MiddleNames,
      Surname: PersonalDetails.LastName
    },
    Dob: moment(PersonalDetails.DateOfBirth, 'dd/MM/yyyy').toDate()
  },
  ContactInfo: {
    Email: { Address: PersonalDetails.Email },
    MobileTelephone: PersonalDetails.MobileTelephone
  },
  NoOfDependants: PersonalDetails.NoOfDependants,
  MaritalStatus: PersonalDetails.MaritalStatus,
  AddressHistory: AddressHistory.Items.map((address) => ({
    BuildingName: address.BuildingName,
    BuildingNumber: address.BuildingNumber,
    SubBuilding: address.SubBuilding,
    Street: address.Street,
    District: address.District,
    PostTown: address.PostTown,
    Postcode: address.Postcode,
    RawResidency: address.Residency,
    MonthsAtAddress: address.TimeAtAddress.TotalMonths
  })),
  EmploymentHistory: EmploymentHistory.Items.map((employment) => ({
    RawSkillLevel: employment.OccupationType,
    RawStatus: employment.OccupationBasis,
    MonthsInJob: employment.TimeAtEmployment.TotalMonths
  })),
  AffordabilityDetails: {
    GrossAnnualIncome: AffordabilityDetails.Income.GrossAnnual,
    HasOtherHouseholdIncome: AffordabilityDetails.Income.HasOtherHouseholdIncome!,
    OtherHouseholdIncome: AffordabilityDetails.Income.OtherHouseholdIncome,
    NetMonthlyIncome: AffordabilityDetails.Income.NetMonthly,
    MortgageOrRentExpenditure: AffordabilityDetails.MonthlyExpenditure.MortgageOrRental,
    OtherCommittedMonthlyOutgoings: AffordabilityDetails.MonthlyExpenditure.Other,
    ReplacementLoan: AffordabilityDetails.ReplacementLoan.IsReplacementLoan!,
    ReplacementLoanAmount: AffordabilityDetails.ReplacementLoan.DetailsOfReplacementLoan,
    ForeseeFutureDownturnInFinances: AffordabilityDetails.PersonalCircumstances.ForeseeFutureDownturnInFinances!,
    DownturnReason: AffordabilityDetails.PersonalCircumstances.DownturnReason,
    ForeseenYearlyIncome: AffordabilityDetails.PersonalCircumstances.ForeseenYearlyIncome
  }
});
