import { connect } from 'react-redux';
import * as quickQuoteActions from '../../../redux/quickQuote/quickQuoteActions';
import * as modalActions from '../../../redux/modal/modalActions';
import * as applicationActions from '~/redux/application/applicationActions';
import { observer, inject } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';
import { withLocation, withNavigate, withParams, withQuery } from 'hocs/router';
import { objectToSearchParams } from 'utils/objectToSearchParams';

class QuickQuoteContainer extends React.Component {
  componentDidMount() {
    const currentPath = this.props.location.pathname.split('/');
    const isVehicleDetails = currentPath[currentPath.length - 1] === 'quickquote';

    if (!isVehicleDetails && (!this.props.vehicle || !Object.keys(this.props.vehicle).length)) {
      // setTimeout workaround for getting react-router-dom 6.* to work with componentDiDMount
      // https://stackoverflow.com/questions/71728420/navigate-in-componentdidmount-react-router-dom-v6
      setTimeout(() => this.props.navigateToQuickQuote());
    }
  }

  render() {
    return <Outlet context={this.props} />;
  }
}

QuickQuoteContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  navigateToQuickQuote: PropTypes.func,
  children: PropTypes.node,
  vehicle: PropTypes.shape({
    CapId: PropTypes.string,
    Class: PropTypes.string,
    Condition: PropTypes.string,
    Derivative: PropTypes.string,
    DerivativeId: PropTypes.string,
    Make: PropTypes.string,
    MakeId: PropTypes.string,
    Mileage: PropTypes.string,
    Model: PropTypes.string,
    ModelId: PropTypes.string,
    RegistrationDate: PropTypes.string,
    Vrm: PropTypes.string
  })
};

function mapStateToProps(state, ownProps) {
  const canUseShowroomCfc = ownProps.appStore.uiState.canUseCfcIvendiv2;

  return {
    session: state.session,
    dealership: state.dealership,
    quotes: state.quotes,
    products: state.products,
    quickQuote: state.quickQuote,
    vehicle: state.quickQuote.vehicle,
    capVehicle: state.quickQuote.capVehicle,
    chosenQuote: state.quickQuote.quote,
    enableShowRoomCfc:
      canUseShowroomCfc && state.quotes.customerType && state.quotes.customerType?.toLowerCase() === 'consumer',
    enableSendDeal: ownProps.appStore.uiState.canUseSendDeal && state.quotes,
    customerType: state.quotes.customerType
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmitPersonalDetails: (formData) => dispatch(quickQuoteActions.createQuickQuoteApplication(formData)),
    onProceed: ({ quote, customerType, customerId, vehicleId }) => {
      if (customerId && vehicleId) {
        // customer already exists so create application directly using the customer id
        dispatch(applicationActions.createApplication({ VehicleId: vehicleId }, quote, customerId, customerType));
      } else {
        // customer may not exist so go to quick quote apply and collect customer details before creating application
        dispatch(quickQuoteActions.changeQuote(quote));
        if (customerType === 'consumer') {
          ownProps.navigate(`/d/${ownProps.params.dealershipId}/quickquote/apply`);
        } else {
          dispatch(modalActions.open('proceedWithCorporateQuickQuote'));
        }
      }
    },
    onVehicleChange: (vehicle) => {
      const { vehiclePrice, cashDeposit, term } = ownProps.query;
      dispatch(quickQuoteActions.changeQuickQuoteVehicle(vehicle));
      ownProps.navigate({
        pathname: `/d/${ownProps.params.dealershipId}/quickquote/results`,
        search: `?${objectToSearchParams({
          vehiclePrice,
          cashDeposit,
          term
        })}`
      });
    },
    onResetEditedVehicle: () => {
      dispatch(quickQuoteActions.resetQuickQuoteEditingVehicle());
    },
    openSaveQuoteModal: (quote, customerType) => {
      dispatch(quickQuoteActions.changeQuote(quote));
      if (customerType?.toLowerCase() === 'consumer') {
        dispatch(modalActions.open('saveQuickQuote'));
      } else {
        dispatch(modalActions.open('saveCorporateQuickQuote'));
      }
    },
    onSave: (chosenQuote, formData) => {
      dispatch(quickQuoteActions.createQuickQuoteApplication(formData, 1));
    },
    onSendDeal: (quotes, customerType) => {
      dispatch(
        modalActions.open('sendDeal', {
          quotes,
          ...ownProps.params,
          isQuickQuote: true,
          customerType: customerType
        })
      );
    },
    navigateToQuickQuote: () => {
      ownProps.navigate(`/d/${ownProps.params.dealershipId}/quickquote`, { replace: true });
    }
  };
}

export default compose(
  withParams,
  withLocation,
  withNavigate,
  withQuery,
  inject(['appStore']),
  connect(mapStateToProps, mapDispatchToProps),
  observer
)(QuickQuoteContainer);
