import { lowercaseFirstLetter } from '~/components/Common/utils';
import { EligibilityOffered, EligibilityOfferedType } from '~/components/QuoteCard/types';
import { EligibilityCategories, EligibilityCategoriesType, FNEligibilityModalContentType } from './types';

export const getFNEligibilityModalContent = (
  status: EligibilityOfferedType,
  lenderMessages: string[] | null
): FNEligibilityModalContentType => {
  const formattedEligibilityStatus = status?.length && lowercaseFirstLetter(status);

  return {
    title: `${formattedEligibilityStatus}_eligibility`,
    header: `${formattedEligibilityStatus}_explained`,
    fnEligibilityStatus: status,
    indicativeText: 'indicative_eligibility',
    lenderMessages: lenderMessages?.length ? lenderMessages : null
  };
};

export const getEligibilityCategoryFromFnStatus = (status: EligibilityOffered): EligibilityCategoriesType | null => {
  switch (status) {
    case EligibilityOffered.PreApproved:
    case EligibilityOffered.VeryHigh:
    case EligibilityOffered.High:
      return EligibilityCategories.high;
    case EligibilityOffered.Medium:
      return EligibilityCategories.medium;
    case EligibilityOffered.Low:
    case EligibilityOffered.Unknown:
    case EligibilityOffered.UnableToAssist:
      return EligibilityCategories.low;
    default:
      return null;
  }
};
