import moment from 'moment';
import platformApiUtils from '@ivendi/platform-api-utils';

export function setReservationSettings(applicationId) {
  return platformApiUtils.post(`v1/customerapplication/${applicationId}/consumer/cancel`);
}

export function getCloseEsign(url) {
  return platformApiUtils.post('v1/lender-services-proxy/close-esign', { CloseUrl: url });
}

export function getStellantisEsign(eSignDetails) {
  return platformApiUtils.post('v1/lender-services-proxy/stellantis-esign', {
    ...eSignDetails
  });
}

export function getTandemEsign(eSignDetails) {
  return platformApiUtils.post('v1/lender-services-proxy/tandem/esign', {
    ...eSignDetails
  });
}

export function getMarshEsign(eSignDetails) {
  return platformApiUtils.post('v1/lender-services-proxy/marsh/esign', {
    ...eSignDetails
  });
}

export const updateStellantisSubmissionInfo = (application, date) =>
  platformApiUtils.post('v1/lender-services-proxy/stellantis-submission-info', {
    DealershipId: application.DealershipId,
    FunderCode: 'STE',
    IvendiAgreementNumber: application?.Agreements[0]['iVendiAgreementNumber'],
    LenderAgreementNumber: application?.Agreements[0]['LenderAgreementNumber'],
    ApplicantId: application.Id,
    BusinessUse: application.CustomerType === 'Corporate' ? true : false, // assumed false for the moment
    QuoteVehicleCapCode: application.Quote.VehicleCapCode,
    QuoteVehicleClass: application.Quote.VehicleClass,
    QuoteVehicleVrm: application.Quote.VehicleVrm,
    QuoteVehicleMileage: application.Quote.VehicleMileage,
    QuoteCashPrice: application.Quote.CashPrice,
    QuoteVATQualifying: application.Quote.VatQualifying,
    QuoteVATIncluded: application.Quote.VatIncluded,
    QuoteCondition: application.Quote.Condition,
    QuoteVehiclePrice: application.Quote.VehiclePrice,
    MetaDataVehiclePrice: application.Quote.PlatformMeta.VehiclePrice,
    MetaDataCashPrice: application.Quote.PlatformMeta.CashPrice,
    ValueAddedProduct: application.Quote.ValueAddedProducts,
    DeliveryDate: moment(date, 'DD/MM/YYYY')
      .endOf('day')
      .toISOString(),
    IsDistanceSale: !!application?.Arranged?.DistanceSale
  });
