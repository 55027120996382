import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';
import ButtonWithIcon from '../Button/ButtonWithIcon';
import Icon from '../Icon/Icon';
import './modal.scss';

export const ModalContext = React.createContext(null);
class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: props.isOpen
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      if (nextProps.isOpen) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  }

  handleKey = (event) => {
    if (event.key === 'Escape') this.props.onClose();
  };

  showModal = () => {
    this.setState({ isOpen: true }, this.animatedModalIn);
    document.addEventListener('keyup', this.handleKey);
  };

  animatedModalIn() {
    setTimeout(() => this.setState({ isVisible: true }), 10);
  }

  hideModal = () => {
    this.setState({ isVisible: false });
    setTimeout(this.finishHidingModal, 300);
  };

  finishHidingModal = () => {
    this.setState({ isOpen: false });
    this.props.onHasClosed && this.props.onHasClosed();

    document.removeEventListener('keyup', this.handleKey);
  };

  handleModalClick(event) {
    event.stopPropagation();
  }

  handleConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    } else {
      this.props.onClose();
    }
  };

  render() {
    let {
      children,
      autoWidth,
      onClose,
      title,
      buttonText,
      onConfirm,
      hideClose,
      id,
      isLoading,
      hasError,
      buttonStyle,
      icon
    } = this.props;

    const overlayClasses = classNames('modal__overlay', this.state.isVisible && 'modal__isVisible');

    /*
     * Design includes a full width border above the content, but I have hidden it if there is no title passed in
     * to prevent an unnecessary floating line. There are several instances of this component where the title prop is not used
     * and titles are passed in along with the content as children which throws the spacing off.
     * Preferably, we should ensure titles are passed in via the prop to maintain visual consistency.
     */
    const contentClasses = classNames('modal__content', title && 'modal__content--withBorder');

    const windowClasses = classNames(
      'modal__dialog',
      this.state.isVisible && 'modal__isVisible',
      autoWidth && 'modal__dialog--width-auto'
    );
    const headerClasses = classNames('', !title && 'no-padding');

    if (!this.state.isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <ModalContext.Provider value={{ closeModal: onClose }}>
        <aside className={overlayClasses} onClick={onClose} role="dialog" aria-modal="true">
          <div className={windowClasses} ref="modal__dialog" onClick={this.handleModalClick} id={`${id}Modal`}>
            <header className={headerClasses}>
              {title && (
                <h1 className="modal__title">
                  {icon && <Icon name={icon} />}
                  {title}
                </h1>
              )}
              {!hideClose && (
                <span className="modal__close">
                  <ButtonWithIcon iconName="validation-cross" buttonStyle="square" onClick={onClose} />
                </span>
              )}
            </header>
            <div className={contentClasses} data-th={`ModalContent__${id}`}>
              {children}
              {onConfirm && (
                <div className="modal__button">
                  <Button
                    type="button"
                    buttonStyle={buttonStyle}
                    data-th={`ModalButton__${id}`}
                    onClick={this.handleConfirm}
                    isLoading={isLoading}
                    hasError={hasError}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </aside>
      </ModalContext.Provider>,
      document.getElementById('root')
    );
  }
}

Modal.defaultProps = {
  id: 'Modal'
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onHasClosed: PropTypes.func,
  onConfirm: PropTypes.func,
  autoWidth: PropTypes.bool,
  children: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonStyle: PropTypes.string,
  buttonText: PropTypes.string,
  hideClose: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool
};

export default Modal;
