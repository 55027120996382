import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../Common/Button/Button';
import {
  getCFCDecisionMessages,
  getEligibilityCategoryFromCfcMatchRate,
  hasCFCCreditPolicyRule
} from '../../../core/decisionMessages';
import EligibilityModalGaugeSection from '../components/EligibilityModalGaugeSection';
import EligibilityModalRuleList from '../components/EligibilityModalRuleList';
import Modal from '../../Common/Modal/Modal';
import './eligibilityResultModal.scss';

// for CFC only
const EligibilityResultModal = (props) => {
  const { t } = useTranslation('Quoting');
  const eligibilityCategory = getEligibilityCategoryFromCfcMatchRate(props.matchrate);
  const hasCreditPolicyMessage = hasCFCCreditPolicyRule(props.rules);
  const decisionMessages = getCFCDecisionMessages(props.rules);
  const modalTitle = t(`EligibilityResultModal.${eligibilityCategory}_eligibility_result`);

  return (
    <Modal isOpen={props.isOpen} onClose={props.close} title={modalTitle}>
      <div className="eligibilityResultModal__wrapper">
        {eligibilityCategory === 'high' && (
          <EligibilityModalGaugeSection
            cfcMatchRate={props.matchrate}
            header={t('EligibilityResultModal.it_looks_like_your_customer_would_be_approved')}
            paragraphOne={t(
              'EligibilityResultModal.the_enquiry_results_have_indicated_that_if_your_customer_were_to_apply_for_credit_against_this_quote_and_lender_there_is_a_high_chance_of_approval'
            )}
            paragraphThree={t(
              'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_is_subject_to_a_full_application_and_credit_check_with_the_respective_lender'
            )}
          />
        )}

        {eligibilityCategory === 'medium' && (
          <EligibilityModalGaugeSection
            cfcMatchRate={props.matchrate}
            header={t('EligibilityResultModal.it_looks_like_your_customer_would_be_approved')}
            paragraphOne={t(
              'EligibilityResultModal.the_enquiry_results_have_indicated_that_if_your_customer_were_to_apply_for_credit_against_this_quote_and_lender_there_is_a_medium_chance_of_approval'
            )}
            paragraphTwo={t(
              'EligibilityResultModal.there_may_be_a_few_aspects_that_we_are_uncertain_about_based_on_either_your_customers_profile_or_the_structure_of_the_deal'
            )}
            paragraphThree={t(
              'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_is_subject_to_a_full_application_and_credit_check_with_the_respective_lender'
            )}
          />
        )}

        {eligibilityCategory === 'low' && hasCreditPolicyMessage && (
          <EligibilityModalGaugeSection
            cfcMatchRate={props.matchrate}
            header={t('EligibilityResultModal.it_looks_unlikely_this_lender_can_help_with_this_quote')}
            paragraphOne={t(
              'EligibilityResultModal.your_customers_results_have_indicated_there_is_a_low_chance_of_approval_for_this_lender_and_quote'
            )}
            paragraphTwo={t(
              'EligibilityResultModal.this_may_be_based_on_either_the_customer_profile_or_the_structure_of_the_deal'
            )}
            paragraphThree={t(
              'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_a_full_application_to_the_lender_may_return_a_more_positive_outcome'
            )}
            paragraphFour={t('EligibilityResultModal.it_may_be_worth_considering_other_lenders_and_or_quotes')}
          />
        )}

        {eligibilityCategory === 'low' && !hasCreditPolicyMessage && (
          <EligibilityModalGaugeSection
            cfcMatchRate={props.matchrate}
            header={t(
              'EligibilityResultModal.based_on_the_structure_of_this_deal_it_looks_unlikely_this_lender_can_help'
            )}
            paragraphOne={t(
              'EligibilityResultModal.your_enquiry_results_have_indicated_that_there_is_a_low_chance_of_approval_for_this_lender_and_quote'
            )}
            paragraphTwo={t(
              'EligibilityResultModal.this_may_be_based_on_either_the_customer_profile_or_the_structure_of_the_deal'
            )}
            paragraphThree={t(
              'EligibilityResultModal.please_note_the_indication_of_credit_is_indicative_only_and_a_full_application_to_the_lender_may_return_a_more_positive_outcome'
            )}
            paragraphFour={t(
              'EligibilityResultModal.however_based_on_this_lenders_vehicle_policies_a_change_of_vehicle_may_help_to_achieve_an_improved_outcome'
            )}
          />
        )}

        <EligibilityModalRuleList decisionMessages={decisionMessages} />
        <footer>
          <Button type="button" onClick={() => props.close()} buttonStyle="cancel">
            {t('EligibilityResultModal.close')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

EligibilityResultModal.defaultProps = {
  rules: []
};
EligibilityResultModal.propTypes = {
  matchrate: PropTypes.number,
  rules: PropTypes.array,
  close: PropTypes.func,
  isOpen: PropTypes.bool
};
export default EligibilityResultModal;
