import { z } from 'zod';
import { EligibilityOffered, StandardLoanDataType } from '~/components/QuoteCard/types';

export enum FinanceNavigatorScanStatus {
  NotSubmitted = 'NotSubmitted',
  Submitted = 'Submitted',
  PartiallyComplete = 'PartiallyComplete',
  Complete = 'Complete'
}

export const FinanceNavigatorResultSchema = z.object({
  OriginalQuoteId: z.string(),
  OfferedQuote: z.custom<StandardLoanDataType>(),
  EligibilityOffered: z.nativeEnum(EligibilityOffered),
  EligibilityOfferedAsPercentage: z.number().optional(),
  OriginalValuesNowDifferent: z.record(z.string(), z.number()),
  LenderData: z
    .object({
      Messages: z.array(z.string()).optional(),
      ExpiryDate: z.string().optional(),
      LenderReferenceNumber: z.string(),
      MaxMonthlyBudget: z.number().optional(),
      MaxAdvance: z.number().optional()
    })
    .optional()
});

export const FinanceNavigatorResultMessageSchema = z.object({
  Id: z.string(),
  DealershipId: z.string(),
  ConsumerId: z.string().optional(),
  VehicleId: z.string().optional(),
  Status: z.nativeEnum(FinanceNavigatorScanStatus),
  Results: z.array(FinanceNavigatorResultSchema).optional()
});

export type FinanceNavigatorResultMessage = z.infer<typeof FinanceNavigatorResultMessageSchema>;

export type FinanceNavigatorResult = z.infer<typeof FinanceNavigatorResultSchema>;
