import cn from 'classnames';
import { QuoteRowLabel } from '../QuoteRowInfo/QuoteRowLabel';
import { QuoteRowValue } from '../QuoteRowInfo/QuoteRowValue';
import { useTranslation } from 'react-i18next';
import FinanceNavigatorQuoteInfoModal from '~/components/Quoting/modals/FinanceNavigatorQuoteInfoModal';
import { useGetHeaderFiguresContent } from './hooks';
import { HeaderFigureLabel, HeaderFigureLabelType, HeaderFiguresProps } from './types';
import { getHeaderFigures } from './utils';

/*
 * When a Finance Navigator Eligibility check has been performed, quotes return with an Eligibility obj
 * which may contain additional information from the Lender on how to increase eligibility.
 *
 * In a PreApprovalType.RateForRisk scenario:
 *    quote figures may have been adjusted in order to increase eligibility, e.g, the APR may have been increased.
 *
 * In a PreApprovalType.EligibilityCheck scenario:
 *    quote figures have not been adjusted but there may be recommendations for adjustments the dealer can make to improve eligibility.
 *
 * Both scenarios require the changes (or adjustment recommendations) to be telegraphed to the user.
 * We do this by adding a button to the relevant figure's cell which triggers a modal which outlines these changes or recommendations.
 * If a change has been made (as in the RateForRisk scenario) we also add an alert tag to the figure's cell.
 *
 */

export const HeaderFigures = ({ displayQuote, quote }: HeaderFiguresProps) => {
  const { t } = useTranslation('QuoteCard');
  const headerFigures = getHeaderFigures(quote, displayQuote);
  const {
    isModalOpen,
    selectedFigure,
    fnEligibilityContent,
    handleViewDetailsClick,
    closeModal,
    headerFiguresConfig
  } = useGetHeaderFiguresContent(quote, headerFigures);

  const formattedHeaderItems = (Object.keys(headerFigures) as HeaderFigureLabelType[]).map((label, index) => {
    const {
      canShowAlertTag,
      canShowIndicativeAprTag,
      canShowViewDetailsButton,
      hasRateForRiskContent
    } = headerFiguresConfig;
    const isAprCell = label === HeaderFigureLabel.Apr;
    const isMonthlyPaymentCell = label === HeaderFigureLabel.MonthlyPayment;

    const alertTagClassnames = cn('quoteCard__alertTag', {
      'quoteCard__alertTag--small': canShowIndicativeAprTag
    });

    return (
      <div
        className="quoteCard__cell"
        key={`quote-card-figures-item-${label}-${index}`}
        data-th={`headerFiguresCell-${label}`}
      >
        <dt className="quoteCard__cellValue">
          {/* @ts-ignore */}
          <QuoteRowValue label={label} value={headerFigures[label]} />
        </dt>
        <dd className="quoteCard__cellDescription">
          {/* @ts-ignore */}
          <QuoteRowLabel label={label} value={headerFigures[label]} translationModule="QuoteCardFigures" />
          {canShowAlertTag && isAprCell && (
            <>
              {canShowIndicativeAprTag && (
                <span className={alertTagClassnames} data-th={'indicativeOnly'}>
                  {t('QuoteCardFigures.indicative_only')}
                </span>
              )}
              {hasRateForRiskContent && (
                <span className={alertTagClassnames} data-th={'newRate'}>
                  {t('QuoteCardFigures.new_rate')}
                </span>
              )}
            </>
          )}
        </dd>
        {canShowViewDetailsButton && (isAprCell || isMonthlyPaymentCell) && (
          <button
            className="quoteCard__viewDetails"
            onClick={() => handleViewDetailsClick(label)}
            data-th="viewDetailsButton"
          >
            {t('QuoteCardFigures.view_details')}
          </button>
        )}
      </div>
    );
  });

  return (
    <>
      {formattedHeaderItems}
      {fnEligibilityContent && (
        <FinanceNavigatorQuoteInfoModal
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedFigure={selectedFigure}
          fnEligibilityContent={fnEligibilityContent}
        />
      )}
    </>
  );
};
