/* eslint-disable import/no-anonymous-default-export */
import { types } from '../schema';
import { QuoteSchema, FinanceNavigatorQuoteSchema, ConsumerCfcQuoteSchema } from './quoteSchema';
import { AgreementsApplicationSchema } from './applicationSchema';

export default {
  Address: {
    BuildingName: types.String,
    BuildingNumber: types.String,
    Country: types.String,
    County: types.String,
    District: types.String,
    Organisation: types.String,
    PostTown: types.String,
    Postcode: types.String,
    Street: types.String,
    SubBuilding: types.String
  },
  AliasCount: types.Number,
  Aliases: [
    {
      Firstname: types.String,
      Middlenames: types.String,
      Surname: types.String,
      Title: types.String
    }
  ],
  Archived: types.Boolean,
  AssignedTo: [
    {
      DealershipUserId: types.String,
      Firstname: types.String,
      Surname: types.String,
      Avatar: {
        Colour: types.Number
      }
    }
  ],
  CfcProspectResultSets: [
    {
      BestMatchRate: types.Number,
      CfcProspectId: types.String,
      ClientApp: types.String,
      ClientAppVersion: types.String,
      CraScorePercentage: types.Number,
      Created: types.String,
      CreatedSource: types.String,
      Deposit: types.Number,
      Firstname: types.String,
      Origin: types.String,
      PersonalScorePercentage: types.Number,
      Quotes: [ConsumerCfcQuoteSchema],
      Surname: types.String,
      Term: types.Number,
      VehicleId: types.String,
      Viewable: types.Boolean
    }
  ],
  FinanceScans: [
    {
      Id: types.String,
      VehicleId: types.String,
      CreatedDate: types.String,
      PaidOutApplicationId: types.String,
      Quotes: [FinanceNavigatorQuoteSchema],
      Identity: {
        Names: {
          Title: types.String,
          Firstname: types.String,
          Middlenames: types.String,
          Surname: types.String
        },
        Dob: types.String,
        Gender: types.String,
        CountryOfBirth: types.String,
        Nationality: types.String
      },
      Results: [
        {
          OriginalQuoteId: types.String,
          OfferedQuote: FinanceNavigatorQuoteSchema,
          EligibilityOffered: types.String,
          EligibilityOfferedAsPercentage: types.Number,
          OriginalValuesNowDifferent: {},
          ScanFrom: types.String,
          LenderData: {
            Messages: [types.String],
            ExpiryDate: types.String,
            LenderReferenceNumber: types.String,
            MaxMonthlyBudget: types.Number,
            MaxAdvance: types.Number
          }
        }
      ]
    }
  ],
  CreatedSource: types.String,
  DealerNotes: [
    {
      Created: types.String,
      Firstname: types.String,
      Initials: types.String,
      MessageBody: types.String,
      Surname: types.String,
      UserId: types.String,
      VehicleId: types.String
    }
  ],
  ConsumerApplicants: [
    {
      ApplicantId: types.String,
      ApplicationFormSubmitted: types.Boolean,
      ClientApp: types.String,
      ClientAppVersion: types.String,
      ConsumerApplicantId: types.String,
      CreatedUtc: types.String,
      LastModifiedTimestamp: types.Number,
      LenderStatus: types.String,
      Name: types.String,
      Quote: QuoteSchema,
      Tags: types.String,
      VehicleId: types.String,
      FinalDecisionsReceived: types.Boolean,
      Agreements: AgreementsApplicationSchema
    }
  ],
  CorporateApplicants: [
    {
      ApplicantId: types.String,
      ApplicationFormSubmitted: types.Boolean,
      ClientApp: types.String,
      ClientAppVersion: types.String,
      CorporateApplicantId: types.String,
      LastModifiedTimestamp: types.Number,
      LenderStatus: types.String,
      Name: types.String,
      Quote: QuoteSchema,
      VehicleId: types.String,
      Tags: types.String,
      FinalDecisionsReceived: types.Boolean,
      Agreements: AgreementsApplicationSchema
    }
  ],
  CreatedDate: types.String,
  CreatedDateTimestamp: types.Number,
  CustomerType: types.String,
  Dealerships: [types.String],
  DistanceToDealership: types.String,
  Email: types.String,
  FaxNumber: types.String,
  Firstname: types.String,
  IarProspects: [
    {
      IARProspectId: types.String,
      VehicleId: types.String,
      ApplicationFormSubmitted: types.Boolean,
      IarManualCode: types.String,
      ConsumerApplicantId: types.String,
      LenderStatus: types.String,
      CapId: types.Number,
      Class: types.String,
      CashDeposit: types.Number,
      CashPrice: types.Number,
      Cashback: types.Number,
      RequestedDeposit: types.Number,
      ChargePerOverDistanceUnit: types.Number,
      OutstandingSettlement: types.Number,
      PartExchange: types.Number
    }
  ],
  Id: types.String,
  Landline: types.String,
  LastModified: types.String,
  LastModifiedTimestamp: types.Number,
  CustomerOrigin: types.String,
  Middlename: types.String,
  Middlenames: types.String,
  Mobile: types.String,
  OriginalDealership: {
    Id: types.String,
    Name: types.String
  },
  Position: types.String,
  Read: types.Boolean,
  SavedQuotes: [
    {
      Quote: QuoteSchema,
      SavedTime: types.String,
      VehicleId: types.String
    }
  ],
  SelfServiceCodes: [
    {
      Code: types.String,
      DealershipUserId: types.String,
      OpenedDate: types.String,
      Quotes: [QuoteSchema],
      SavedDate: types.String,
      SubmittedDate: types.String,
      VehicleId: types.String
    }
  ],
  Surname: types.String,
  Title: types.String,
  TradingName: types.String,
  CompanyName: types.String,
  VehiclesOfInterest: [
    {
      CapId: types.Number,
      CapMakeId: types.Number,
      CapModelId: types.Number,
      GlassId: types.String,
      CdnVehicleImageUrl: types.String,
      Class: types.String,
      Closed: types.Boolean,
      Condition: types.String,
      CreatedTimestamp: types.Number,
      Derivative: types.String,
      HiddenOnDashboard: types.Boolean,
      Make: types.String,
      MakeId: types.Number,
      Mileage: types.Number,
      Model: types.String,
      ModelId: types.String,
      RegistrationDate: types.String,
      SanitizedVrm: types.String,
      Status: {
        Accessories: types.Number,
        AdditionalCommission: types.Number,
        AcceptanceFeeLast: types.Number,
        AccessoriesInsurance: types.Number,
        AccessoriesOther: types.Number,
        AccessoriesWarranty: types.Number,
        Advance: types.Number,
        AnnualDistanceQuoted: types.Number,
        BasicPrice: types.Number,
        CashPrice: types.Number,
        Cashback: types.Number,
        RequestedDeposit: types.Number,
        CashDeposit: types.Number,
        ChargePerOverDistanceUnit: types.Number,
        Commission: types.Number,
        DateOfHandover: types.String,
        DateOfPurchase: types.String,
        Deposit: types.Number,
        FunderCode: types.String,
        FinalPayment: types.Number,
        FinanceType: types.String,
        FirstPayment: types.Number,
        FlatRate: types.Number,
        FollowedBy: types.Number,
        FollowingPayments: types.Number,
        InterestCharges: types.Number,
        NonVatableItems: types.Number,
        Notes: types.String,
        OptionToPurchaseFee: types.Number,
        OtherFunder: types.String,
        OutstandingSettlement: types.Number,
        PartExchange: types.Number,
        RepresentativeApr: types.Number,
        Settlement: types.Number,
        StandInValue: types.Number,
        Status: types.String,
        Term: types.Number,
        TotalAmountPayable: types.Number,
        VAT: types.Number,
        VATAmount: types.Number
      },
      SuppliedBodyStyle: types.String,
      SuppliedDerivative: types.String,
      SuppliedDoors: types.String,
      SuppliedFuelType: types.String,
      SuppliedMake: types.String,
      SuppliedModel: types.String,
      VehicleId: types.String,
      Vrm: types.String,
      Vin: types.String
    }
  ],
  Version: types.Number,
  Settlement: {
    Value: types.Number,
    ExpiryDateTimeUtc: types.String,
    Expired: types.Boolean,
    Source: types.String,
    HaveLetter: types.Boolean,
    AgreementId: types.Number
  },
  PushDeals: types.Object
};
