import { useTranslation } from 'react-i18next';
import ErroredQuote from '~/components/QuotesList/components/ErroredQuote';
import FunderLogo from '~/components/Common/FunderLogo';
import { StandardLoanDataType } from '../../types';

type QuoteErrorsProps = {
  quote: StandardLoanDataType;
  trackingPage: string;
};
const QuoteErrors = ({ quote, trackingPage }: QuoteErrorsProps) => {
  const { t } = useTranslation('QuoteCard');
  return (
    <div className="quoteCard" data-th={trackingPage}>
      <div className="quoteCard__erroredQuote">
        <div className="quoteCard__errorLogoProduct">
          <div className="quoteCard__logo">
            <FunderLogo funder={quote.FunderCode} />
          </div>

          {quote.ProductName && (
            <div>
              <div className="quoteCard__infoTitle">{t('QuoteCardHeader.product')}</div>

              <div className="quoteCard__quoteInfo--erroredName quoteCard__quoteInfo--productName">
                <div data-th="ProductName">
                  <span className="quoteCard__productName">{quote.ProductName}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/*
          TODO: We need to bring the ErroredQuote under the QuoteCard components folder,
          once the old quote list is removed (currently only used on rep example quotes)
         */}
        <div className="quoteCard__errorList">
          {quote.Errors!.map((error: { Number: number; Message: string }, index: number) => (
            <ErroredQuote errorNumber={error.Number} errorMessage={error.Message} key={index} noBorder />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuoteErrors;
