import _ from 'lodash';
import {
  EligibilityCategories,
  EligibilityCategoriesType
} from '~/components/Quoting/modals/FinanceNavigatorEligibilityModal/types';

const CFCDecisionMessages = [
  {
    id: 1,
    message: 'Credit Policy - Advise customer to obtain copy of their credit file.'
  },
  {
    id: 2,
    message: 'Policy Rule - Certain criteria appears to not fit the requirements of the lender.'
  },
  {
    id: 3,
    message: 'Policy Rule - Please consider a loan product.'
  },
  {
    id: 4,
    message: 'Vehicle Rule - Applicant appears to be below minimum age for engine size.'
  },
  {
    id: 5,
    message: "Policy Rule - License type doesn't appear suitable."
  },
  {
    id: 6,
    message: 'Policy Rule - Time in employment may be too low.'
  },
  {
    id: 7,
    message: 'Vehicle Rule - Applicant may be below minimum age for the advance.'
  },
  {
    id: 8,
    message: 'Vehicle Rule - Above maximum advance for this product.'
  },
  {
    id: 9,
    message: 'Vehicle Rule - Please check cash price.'
  },
  {
    id: 10,
    message: 'Policy Rule - UK address history is too low.'
  },
  {
    id: 11,
    message: 'Vehicle Rule - License type & advance probably not acceptable.'
  },
  {
    id: 12,
    message: 'Policy Rule - Applicant age is probably too low.'
  },
  {
    id: 13,
    message: 'Vehicle Rule - Vehicle engine size may be too large. Changing vehicle may assist.'
  },
  {
    id: 14,
    message: 'Vehicle Rule - More deposit likely to be required.'
  },
  {
    id: 15,
    message: 'Vehicle Rule - Advance too high based on employment.'
  },
  {
    id: 16,
    message: 'Policy Rule - Vehicle can only be for personal use.'
  },
  {
    id: 17,
    message: 'Policy Rule - Employment type does not appear to be acceptable.'
  },
  {
    id: 18,
    message: 'Vehicle Rule - Insurance group not suitable.'
  },
  {
    id: 19,
    message: 'Vehicle Rule - Mileage too high at end of agreement.'
  },
  {
    id: 20,
    message: 'Credit Rule - A shorter term is typically required.'
  },
  {
    id: 21,
    message: 'Product Rule - The lender requires specific terms of agreement.'
  },
  {
    id: 22,
    message: 'Vehicle Rule - Deposit too high for this product.'
  },
  {
    id: 23,
    message: 'Vehicle Rule - Outside loan to value range.'
  },
  {
    id: 24,
    message: 'Current deposit will possibly enhance lenders decision.'
  },
  {
    id: 25,
    message: 'Vehicle Rule - Vehicle appears to not meet lender criteria - consider changing.'
  },
  {
    id: 26,
    message: 'Vehicle Rule - Vehicle age appears to be incorrect.'
  },
  {
    id: 27,
    message: 'Credit Rule - The Credit File suggests that the lender is more likely to approve this deal.'
  },
  {
    id: 28,
    message: 'Credit Rule - The Credit File suggests that the lender may not approve this deal.'
  }
];

export function getEligibilityCategoryFromCfcMatchRate(score: number): EligibilityCategoriesType {
  if (score >= 67) {
    return EligibilityCategories.high;
  } else if (score >= 34) {
    return EligibilityCategories.medium;
  } else {
    return EligibilityCategories.low;
  }
}

export const hasCFCCreditPolicyRule = (decisionMsgsIDs: number[]): boolean => {
  if (!decisionMsgsIDs || !decisionMsgsIDs.length) {
    return false;
  }

  return decisionMsgsIDs.includes(1);
};

export const getCFCDecisionMessages = (decisionMsgsIDs: number[]) => {
  return _.chain(decisionMsgsIDs)
    .map((id) => _.find(CFCDecisionMessages, { id: id }))
    .filter(_.identity)
    .uniq()
    .value();
};
