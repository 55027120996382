import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FieldGroupRHF } from '~/components/Common/Form/FieldGroupRHF';
import DateInput from '~/components/Common/Form/DateInput';
import FormFooter from '~/components/Common/Form/FormFooter';
import SelectInput from '~/components/Common/Form/SelectInput';
import Input from '~/components/Common/Form/Input';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';
import { personalDetailsValidator, useRhfValidationResolver } from './validation';
import { FinanceNavigatorFormPersonalDetails } from '../types/financeNavigatorForm';
import { useTranslation } from 'react-i18next';
import { FinanceNavigatorTitle } from '../components/FinanceNavigatorTitle';

export interface FinanceNavigatorPersonalDetailsProps {
  personalDetails: FinanceNavigatorFormPersonalDetails;
  onSubmit: (personalDetails: FinanceNavigatorFormPersonalDetails) => void;
  onCancel: () => void;
  isEmailReadOnly: boolean;
}

export const FinanceNavigatorPersonalDetails = ({
  personalDetails,
  onSubmit,
  onCancel,
  isEmailReadOnly
}: FinanceNavigatorPersonalDetailsProps) => {
  const { t } = useTranslation('FinanceNavigator');
  const resolver = useRhfValidationResolver(personalDetailsValidator);
  const methods = useForm<FinanceNavigatorFormPersonalDetails>({
    defaultValues: personalDetails,
    mode: 'all',
    resolver
  });

  return (
    <div>
      <FinanceNavigatorTitle text={t('PersonalDetailsForm.personal_details')} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} data-th="personalDetailsForm">
          <Controller
            name="Title"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.title')} name={field.name}>
                {/* @ts-ignore - missing properties */}
                <SelectInput
                  options="FinanceTitles"
                  id={field.name}
                  value={field.value}
                  onChange={(_id: string, value: string) => field.onChange(value)}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                  dataThook="personalDetailsForm__title"
                  excludedOptions={['Professor']}
                />
              </FieldGroupRHF>
            )}
          ></Controller>
          <Controller
            name="FirstName"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.first_name')} name={field.name}>
                <Input
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="MiddleNames"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.middle_name')} name={field.name}>
                <Input
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="LastName"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.last_name')} name={field.name}>
                <Input
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="Email"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.email')} name={field.name}>
                <Input
                  id={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                  disabled={isEmailReadOnly}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="MobileTelephone"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.mobile_telephone_number')} name={field.name}>
                {/* @ts-ignore - missing properties */}
                <PhoneNumberInput
                  id={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  isInvalid={fieldState.invalid}
                  onBlur={field.onBlur}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="DateOfBirth"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.date_of_birth')} name={field.name}>
                <DateInput
                  id={field.name}
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="MaritalStatus"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.marital_status')} name={field.name}>
                {/* @ts-ignore - missing properties */}
                <SelectInput
                  options="MaritalStatus"
                  id={field.name}
                  value={field.value}
                  onChange={(_id: string, value: string) => field.onChange(value)}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                  dataThook="personalDetailsForm__maritalStatus"
                />
              </FieldGroupRHF>
            )}
          />
          <Controller
            name="NoOfDependants"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FieldGroupRHF label={t('PersonalDetailsForm.no_of_dependants')} name={field.name}>
                {/* @ts-ignore - missing properties */}
                <SelectInput
                  options="Dependants"
                  id={field.name}
                  value={field.value}
                  onChange={(_id: string, value: string) => field.onChange(value)}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                  dataThook="personalDetailsForm__dependants"
                />
              </FieldGroupRHF>
            )}
          />
          <FormFooter
            submitLabel={t('FinanceNavigator.next')}
            submitDisabled={!methods.formState.isValid}
            hideSubmit={false}
            submittingState=""
            cancelLabel={t('FinanceNavigator.back')}
            onCancel={onCancel}
          />
        </form>
      </FormProvider>
    </div>
  );
};
