import {
  DisplayQuoteFormat,
  FinanceNavigatorEligibilityLenderDataType,
  FinanceNavigatorResultDataType,
  PreApprovalType,
  QuoteRowType,
  QuoteTypes
} from '../../types';
import { AdjustedFnQuoteFigureComparisonType } from './hooks';
import {
  FNEligibilityContentType,
  FormattedFnOriginalFiguresType,
  HeaderFigureLabel,
  HeaderFigureLabelType,
  HeaderFiguresConfigType,
  HeaderFiguresDataType
} from './types';

export const getHeaderFigures = (quote: QuoteTypes, displayQuote: DisplayQuoteFormat): HeaderFiguresDataType => ({
  [HeaderFigureLabel.MonthlyPayment]: {
    value: quote.FollowingPayments,
    type: QuoteRowType.money
  },
  [HeaderFigureLabel.Apr]: displayQuote.Apr,
  [HeaderFigureLabel.AgreementTerm]: displayQuote.AgreementTerm,
  [HeaderFigureLabel.TotalAmountPayable]: displayQuote.TotalAmountPayable
});

/*
 * Compares current fnQuote figures with original pre-Eligibility Check fnQuote figures
 * in case we need to inform the user that an adjustment has been made.
 * If the values differ, both are returned, otherwise only the current value is returned
 */

export const getFnOriginalFiguresComparisonData = (
  formattedOriginalValues: FormattedFnOriginalFiguresType,
  currentFigures: HeaderFiguresDataType
): AdjustedFnQuoteFigureComparisonType | null => {
  if (Object.values(formattedOriginalValues).every((value) => value === null)) {
    return null;
  }

  return (Object.keys(currentFigures) as HeaderFigureLabelType[]).reduce((result, key) => {
    result[key] = {
      originalValue: formattedOriginalValues[key] ?? null,
      currentValue: currentFigures[key]?.value ?? null
    };
    return result;
  }, {} as AdjustedFnQuoteFigureComparisonType);
};

/*
 * Formats the eligibility content for a Finance Navigator quote.
 *  fnOriginalFiguresComparisonData -- formatted obj to compare original and current quote figures
 *  fnLenderData -- eligibility data from the lender.
 *  quoteHasEligibilityContent -- flag indicating the presence of eligibility content.
 *
 * If the quote has no eligibility content, fnOriginalFiguresComparisonData and fnLenderData are null
 * and quoteHasEligibilityContent flag is set to false.
 */
export const getFormattedFnEligibilityContent = (
  fnQuote: FinanceNavigatorResultDataType,
  currentFigures: HeaderFiguresDataType | null
): FNEligibilityContentType | null => {
  const { Eligibility = null } = fnQuote;

  if (!Eligibility) {
    return {
      quoteHasEligibilityContent: false,
      fnOriginalFiguresComparisonData: null,
      fnLenderData: null
    };
  }

  const { OriginalValuesNowDifferent, LenderData } = Eligibility;

  const formattedOriginalFigures = {
    [HeaderFigureLabel.MonthlyPayment]: OriginalValuesNowDifferent?.FollowingPayments || null,
    [HeaderFigureLabel.Apr]: OriginalValuesNowDifferent?.RepresentativeApr || null,
    [HeaderFigureLabel.AgreementTerm]: OriginalValuesNowDifferent?.Term || null,
    [HeaderFigureLabel.TotalAmountPayable]: OriginalValuesNowDifferent?.TotalAmountPayable || null
  };

  const fnOriginalFiguresComparisonData =
    OriginalValuesNowDifferent && currentFigures
      ? getFnOriginalFiguresComparisonData(formattedOriginalFigures, currentFigures)
      : null;

  const fnLenderData: FinanceNavigatorEligibilityLenderDataType | null =
    LenderData && Object.keys(LenderData).length > 0 ? LenderData : null;

  return {
    quoteHasEligibilityContent: !!fnOriginalFiguresComparisonData || !!fnLenderData,
    fnOriginalFiguresComparisonData,
    fnLenderData
  };
};

/*
 * Generates the HeaderFigure config obj to help determine if alert Tags, 'View Details' buttons and modals
 * should be displayed depending on the quote type and provided eligibility content.
 */
export const getHeaderFiguresConfig = (
  quote: QuoteTypes,
  eligibilityContent: FNEligibilityContentType | null
): HeaderFiguresConfigType => {
  // most of the  below variable definitions can be gathered from useGetQuoteChecks when it is more reusable
  const canShowIndicativeAprTag = (!quote?.isRequote && quote?.CreditTier && quote.CreditTier !== 'None') || false;

  /* Specific to Finance Navigator Eligibility Check quotes */
  const { fnLenderData, fnOriginalFiguresComparisonData } = eligibilityContent || {};
  const hasMaxMonthlyBudgetContent = !!fnLenderData?.MaxMonthlyBudget;
  const hasRateForRiskContent = !!fnOriginalFiguresComparisonData?.[HeaderFigureLabel.Apr]?.originalValue;

  const canShowBtnAndModal = hasRateForRiskContent || hasMaxMonthlyBudgetContent;

  return {
    canShowEligibilityModal: canShowBtnAndModal,
    canShowViewDetailsButton: canShowBtnAndModal,
    canShowAlertTag: canShowIndicativeAprTag || hasRateForRiskContent,
    canShowIndicativeAprTag,
    hasMaxMonthlyBudgetContent,
    hasRateForRiskContent
  };
};

export const isFinanceNavigatorEligibilityQuote = (quote: QuoteTypes): quote is FinanceNavigatorResultDataType => {
  const validPreApprovalTypes = new Set([PreApprovalType.EligibilityCheck, PreApprovalType.RateForRisk]);
  return (
    (quote as FinanceNavigatorResultDataType).Eligibility !== undefined &&
    quote.PreApprovalType !== undefined &&
    validPreApprovalTypes.has(quote.PreApprovalType)
  );
};
