import { types } from '../schema';

const BaseQuote = {
  ProductId: types.String,
  Accessories: types.Number,
  AnnualDistanceQuoted: types.Number,
  AnnualRate: types.Number,
  BalancePayable: types.Number,
  CashPrice: types.Number,
  Cashback: types.Number,
  RequestedDeposit: types.Number,
  ChargePerOverDistanceUnit: types.Number,
  FinanceDepositAllowance: types.Number,
  Deposit: types.Number,
  Fees: types.Number,
  FinalPayment: types.Number,
  FunderProductUID: types.String,
  FinanceType: types.String,
  FirstPayment: types.Number,
  FollowedBy: types.Number,
  FollowingPayments: types.Number,
  FunderCode: types.String,
  FunderProductCode: types.String,
  Insurance: types.Number,
  InterestCharges: types.Number,
  NonVatableItems: types.Number,
  NegativeEquity: types.Number,
  OtherAccessories: types.Number,
  OutstandingSettlement: types.Number,
  PartExchange: types.Number,
  PaymentSchedule: [
    {
      Amount: types.Number,
      Number: types.Number,
      Period: types.Number
    }
  ],
  ProductName: types.String,
  QuoteId: types.String,
  RepresentativeApr: types.Number,
  SupportsPreApproval: types.Boolean,
  Term: types.Number,
  TotalAmountPayable: types.Number,
  TotalDeposit: types.Number,
  Warranty: types.Number,
  Vat: types.Number,
  VatQualifying: types.Boolean,
  VehiclePrice: types.Number,
  ValueAddedProducts: types.Array,
  WashoutNegativeEquityAmount: types.Number,
  PreApprovalType: types.String,
  Commissions: [
    {
      Amount: types.Number,
      Method: types.String,
      Type: types.String,
      Value: types.Number,
      VolumeBonus: types.Number
    }
  ]
};

const CompanionQuoteSchema = {
  ...BaseQuote,

  OptionToPurchaseFee: types.Number,
  SettlementSource: types.String,
  AcceptanceFeeFirst: types.Number,
  AcceptanceFeeLast: types.Number,

  LoanType: types.String,

  AcceptanceFeeSpread: types.Boolean,
  AcceptanceFee: types.Number,
  ActualCashDeposit: types.Number,
  Advance: types.Number,
  Balloon: types.Number,
  BaseRate: types.Number,
  BasicPrice: types.Number,
  CustomerRate: types.Number,
  FacilitatorCode: types.String,
  FunderName: types.String,
  OptionToPurchaseFeeSpread: types.Boolean,
  PlatformMeta: types.String,
  ProductCode: types.String,
  QuotewareDealershipId: types.String,
  RateType: types.String,
  Subsidy: types.Number,
  TermDistance: types.Number,
  QuoteeProductCode: types.String
};

// Consumer Self service
// Consumer Saved quotes
// Consumer Corporate Applicant Quote
// Consumer Customer Applicant Quote

// All of the base, but includes a few fields
// that are not part of the CFC quote
const QuoteSchema = {
  ...BaseQuote,

  LoanType: types.String,
  OptionToPurchaseFee: types.Number,
  SettlementSource: types.String,
  AcceptanceFeeFirst: types.Number,
  AcceptanceFeeLast: types.Number,
  TotalAmountOfCredit: types.Number,
  VehicleMileage: types.Number,
  CompanionQuotes: [CompanionQuoteSchema]
};

// Consumer CFC Quote Schema
const ConsumerCfcQuoteSchema = {
  ...BaseQuote,

  MatchRate: types.Number,
  TotalAmountOfCredit: types.Number,
  DecisionMessages: [types.Number],
  QuoteeProductCode: types.String,
  ProductCode: types.String,
  PrincipleQuoteId: types.String,
  CompanionQuotes: [CompanionQuoteSchema]
};

const FinanceNavigatorQuoteSchema = {
  ...QuoteSchema,
  PreApprovalType: types.String
};

// Application quote schema
// CFC Check details quote schema uses this too
const ApplicationQuoteSchema = {
  ...BaseQuote,

  OptionToPurchaseFee: types.Number,
  SettlementSource: types.String,
  AcceptanceFeeFirst: types.Number,
  AcceptanceFeeLast: types.Number,
  CompanionQuotes: [CompanionQuoteSchema],
  AcceptanceFeeSpread: types.Boolean,
  AcceptanceFee: types.Number,
  ActualCashDeposit: types.Number,
  Advance: types.Number,
  Balloon: types.Number,
  BaseRate: types.Number,
  BasicPrice: types.Number,
  CustomerRate: types.Number,
  FacilitatorCode: types.String,
  FunderName: types.String,
  MatchRate: types.Number,
  NonVatItems: types.Number,
  OptionToPurchaseFeeSpread: types.Boolean,
  PlatformMeta: types.String,
  PrincipleQuoteId: types.String,
  ProductCode: types.String,
  QuoteeProductCode: types.String,
  QuotewareDealershipId: types.String,
  RateType: types.String,
  ResidualValue: types.Number,
  Subsidy: types.Number,
  SupportsPreApproval: types.Boolean,
  TermDistance: types.Number,
  VAT: types.Number,
  Vat: types.Boolean,
  VatIncluded: types.Boolean,
  VehicleCapCode: types.String,
  VehicleCapId: types.String,
  VehicleClass: types.String,
  VehicleDateDiscontinued: types.String,
  VehicleDateIntroduced: types.String,
  VehicleDerivative: types.String,
  VehicleDoors: types.String,
  VehicleDriveTrain: types.String,
  VehicleFuelDelivery: types.String,
  VehicleFuelType: types.String,
  VehicleGlassModelCode: types.String,
  VehicleMake: types.String,
  VehicleMileage: types.Number,
  VehicleModel: types.String,
  VehicleRange: types.String,
  VehicleRegDate: types.String,
  VehicleSector: types.String,
  VehicleSource: types.String,
  VehicleStockIdentity: types.String,
  VehicleStockLocation: types.String,
  VehicleTransmission: types.String,
  VehicleTrim: types.String,
  VehicleVrm: types.String,
  Video1Url: types.String,
  DealershipQuotewareId: types.String,
  TermQuoted: types.Number,
  Condition: types.String
};

// CFC Check Details Quote Schema
const CfcCheckDetailsQuoteSchema = {
  ...ApplicationQuoteSchema,

  PlatformMeta: {
    Accessories: types.Number,
    BasicPrice: types.Number,
    CashPrice: types.Number,
    Insurance: types.Number,
    NonVatableItems: types.Number,
    Other: types.Number,
    VatAddedToDeposit: types.Boolean,
    VatAmount: types.Number,
    VatPercent: types.Number,
    Warranty: types.Number
  }
};

export {
  QuoteSchema,
  CfcCheckDetailsQuoteSchema,
  ConsumerCfcQuoteSchema,
  FinanceNavigatorQuoteSchema,
  ApplicationQuoteSchema
};
