import { useCallback } from 'react';
import { findAlgoliaVehicle } from '~/api/algolia';
import { fetchVehicleVrmLookupV2 } from '~/api/vrmLookup';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';

type UseVehicleLookupProps = {
  checkAlgolia: boolean;
  checkVrmLookup: boolean;
  dealershipId: string;
  shouldUseQuickQuoteLogic?: boolean;
};

// Business decision: LCVs or any Vehicles with score >=2 attempt to get Carweb data instead
const shouldUseCarwebDataInstead = (lookupName: 'findAlgoliaVehicle' | string, vehicle: AlgoliaVehicle): boolean => {
  if (lookupName !== 'findAlgoliaVehicle') {
    return false;
  }

  switch (true) {
    case vehicle?.Class?.toLowerCase() === 'lcv': // prefer carweb data for any LCV vehicle
    case vehicle?.AccuracyScore >= 2: // 2 stars and less (score and stars are inverted)
      return true;
    default:
      return false;
  }
};

export const useVehicleLookup = ({
  checkAlgolia,
  checkVrmLookup,
  dealershipId,
  shouldUseQuickQuoteLogic = false
}: UseVehicleLookupProps) => {
  // Call the lookups in order until one returns a result
  // and if there are no lookups, throw an error
  const lookupVehicle = useCallback(
    async (vrm: string) => {
      const lookups: (typeof findAlgoliaVehicle | typeof fetchVehicleVrmLookupV2)[] = [];
      let tempAlgoliaVehicle;

      if (checkAlgolia) {
        lookups.push(findAlgoliaVehicle);
      }

      if (checkVrmLookup) {
        lookups.push(fetchVehicleVrmLookupV2);
      }

      for (const lookup of lookups) {
        const result = await lookup(vrm, dealershipId).catch(() => null);

        if (shouldUseQuickQuoteLogic && result && shouldUseCarwebDataInstead(lookup?.name, result)) {
          tempAlgoliaVehicle = result; // stored in case next lookup(carweb) returns nothing
          continue; // do the next lookup
        }

        if (result) {
          return result;
        }

        // check if there was an algolia vehicle saved to return before throwing
        if (lookup?.name === 'fetchVehicleVrmLookupV2' && tempAlgoliaVehicle) {
          return tempAlgoliaVehicle;
        }
      }
      throw new Error('No vehicle found in any lookup');
    },
    [dealershipId, checkAlgolia, checkVrmLookup, shouldUseQuickQuoteLogic]
  );

  return {
    lookupVehicle
  };
};
