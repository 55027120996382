import { useState, useEffect, useMemo } from 'react';
import { FinanceNavigatorResultDataType, QuoteRowValue, QuoteTypes } from '../../types';

import { HeaderFigureLabelType, HeaderFiguresDataType } from './types';
import { getFormattedFnEligibilityContent, getHeaderFiguresConfig, isFinanceNavigatorEligibilityQuote } from './utils';

export type AdjustedFnQuoteFigureComparisonType = {
  [key in HeaderFigureLabelType]?: {
    originalValue: number | null;
    currentValue: QuoteRowValue | null;
  };
};

/*
 * Custom hook to manage the state and config of header figures content.
 * Determines if the quote is a Finance Navigator eligibility quote, formats the eligibility content,
 * and generates the header figures config. Also manages the state of the modal and selected figure.
 */
export const useGetHeaderFiguresContent = (quote: QuoteTypes, headerFigures: HeaderFiguresDataType) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFigure, setSelectedFigure] = useState<HeaderFigureLabelType | null>(null);

  const { eligibilityContent, headerFiguresConfig } = useMemo(() => {
    const isFnEligibilityQuote = isFinanceNavigatorEligibilityQuote(quote);
    const eligibilityContent = isFnEligibilityQuote
      ? getFormattedFnEligibilityContent(quote as FinanceNavigatorResultDataType, headerFigures)
      : null;
    const headerFiguresConfig = getHeaderFiguresConfig(quote, eligibilityContent);

    return { eligibilityContent, headerFiguresConfig };
  }, [quote, headerFigures]);

  const handleViewDetailsClick = (figure: HeaderFigureLabelType) => {
    setSelectedFigure(figure);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedFigure(null);
    }
  }, [isModalOpen]);

  return {
    isModalOpen,
    selectedFigure,
    handleViewDetailsClick,
    closeModal,
    fnEligibilityContent: eligibilityContent,
    headerFiguresConfig
  };
};
