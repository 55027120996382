import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import QuotesHeader from '../../QuotesList/components/QuotesHeader';
import QuoteCard from '~/components/QuoteCard/QuoteCard';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import AltSelectWithLabel from '../../Common/Form/AltSelectWithLabel';
import AlertCard from '~Common/AlertCard/AlertCard';
import observerForHooks from '~/hocs/observerForHooks';
import { canSeeCashback } from '~/features';
import { useParams } from 'react-router-dom';
import {
  trackExpandMultiScheduleQuote,
  formatExpandMultiScheduleQuote,
  trackFinanceQuotesApplySelected,
  formatFinanceQuotesApplySelected
} from '~/tracking/avoTracking';
import { QuoteCardVariantTypeJs } from '~/components/QuoteCard/types';
import './quotesListTableItem.scss';

const QuotesListTable = ({ t, ...props }) => {
  const { isBdk, isBnpp } = props.appStore.uiState;
  const params = useParams();

  const hasRequestedCashback = props.quotes.some((quote) => quote.RequestedCashback > 0);
  const hasCREQuoteResults = props.quotes.some((quote) => quote.FunderCode === 'CRE');
  const isRequotingPage = props.quotes.some((quote) => quote.isRequote);
  const isFinanceNavigatorScanResultsPage = props.quotes.some(
    (quote) => quote?.Eligibility?.OriginalValuesNowDifferent
  );
  const hasPreApprovalQuoteResults = props.quotes.some((quote) => quote.SupportsPreApproval);

  const handleSortQuotes = (event) => {
    props.onSortQuotes(event.currentTarget.value);
  };

  const handleExpandedMultiScheduleQuote = (quote, position, isExpanded) => {
    if (isExpanded) {
      const multiScheduleTrackingData = {
        financeQuotesSortField: props.sortField,
        financeQuotesCount: props.quotes.length,
        financeQuotePosition: position
      };

      const VehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, 'Vehicle');

      trackExpandMultiScheduleQuote(
        formatExpandMultiScheduleQuote({
          ...VehicleLoan,
          ...multiScheduleTrackingData,
          dealershipId: params.dealershipId
        })
      );
    }
  };

  const handleProceed = (data, index) => {
    if (props.onProceed) {
      props.onProceed(data);
    }
    trackFinanceQuotesApplySelected(
      formatFinanceQuotesApplySelected(
        { dealershipId: params.dealershipId, ...data },
        {
          financeQuotesSortField: props.sortField,
          financeQuotesCount: props.quotes.length,
          financeQuotePosition: index + 1,
          financeQuoteJourney: data.isRequote ? 'intercepted' : 'quick_quote',
          eventTrigger: 'onProceed',
          eventTriggerText: 'Proceed'
        }
      )
    );
  };

  // TODO: Write tests - See if we can use the URL to determine the quote card variant
  const getQuoteCardVariant = () => {
    if (isRequotingPage) {
      return QuoteCardVariantTypeJs.Requote;
    }
    if (isFinanceNavigatorScanResultsPage) {
      return QuoteCardVariantTypeJs.FinanceNavigatorCheck;
    }
    return QuoteCardVariantTypeJs.FinanceQuote;
  };
  const quoteVariant = getQuoteCardVariant();
  return (
    <div>
      {!isRequotingPage && (
        <>
          <QuotesHeader>
            <AltSelectWithLabel
              label={t('QuotesListTable.sort_by')}
              value={props.sortField}
              options={props.hasCheckedEligibility ? 'CarFinanceChecksSortOptions' : 'QuotesListSortOptions'}
              handleSort={handleSortQuotes}
            />
          </QuotesHeader>
          <div className="quotesListTableItem__alerts">
            {props.hasAdjustedBalloon && isBdk && (
              <AlertCard styles="alert" iconName="alert" paragraph={t('QuotesListTable.balloon_adjusted')} />
            )}
            {hasRequestedCashback && hasCREQuoteResults && canSeeCashback() && !isBnpp && (
              <AlertCard
                styles="information"
                iconName="information"
                paragraph={t('QuotesListTable.cashback_unavailable')}
              />
            )}
            {hasPreApprovalQuoteResults && (
              <AlertCard
                styles="information"
                iconName="information"
                title={t('QuotesListTable.pre_approval_available')}
                paragraph={t('QuotesListTable.pre_approval_available_details')}
              />
            )}
          </div>
        </>
      )}
      <div className="quotesListTableItem__outer">
        {props.quotes.map((quote, index) => {
          const newCarOffer =
            props.newCarOffers &&
            Array.isArray(props.newCarOffers) &&
            props.newCarOffers.find((offer) => offer.ProductUid === quote.ProductId);

          return (
            <QuoteCard
              variant={quoteVariant}
              quote={quote}
              showFullQuote={props.showFullQuote}
              schemesVisible={props.schemesVisible}
              actions={{
                onSave: props.onSave,
                onProceed: (data) => handleProceed(data, index),
                onProceedToBreakDown: props.proceedToBreakDown,
                onProductNameClick: props.onProductNameClick,
                onQuoteSelect: props.onQuoteSelect,
                onCombinedQuoteClick: props.showCombinedQuoteClick,
                onProceedToFinanceNavigator: props.onProceedToFinanceNavigator,
                onRequote: props.onRequote
              }}
              isQuickQuote={props.isQuickQuote}
              vehicleClass={props.vehicleClass}
              isCustomerQuote={props.isCustomerQuote}
              chosenQuoteId={props.chosenQuoteId}
              proceedingState={props.proceedingState}
              newCarOffer={newCarOffer}
              hasCheckedEligibility={props.hasCheckedEligibility}
              key={`${quoteVariant}-${quote.QuoteId}`}
              hasRequestedCashback={hasRequestedCashback}
              toggleMultiScheduleComponents={(isExpanded) => {
                const quoteListPosition = index + 1;
                handleExpandedMultiScheduleQuote(quote, quoteListPosition, isExpanded);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

QuotesListTable.propTypes = {
  quotes: PropTypes.array,
  hasAdjustedBalloon: PropTypes.bool,
  hasCheckedEligibility: PropTypes.bool,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  schemesVisible: PropTypes.bool,
  onProceed: PropTypes.func,
  onSave: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortField: PropTypes.string,
  onSortQuotes: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  proceedingState: PropTypes.string,
  chosenQuoteId: PropTypes.string,
  vehicleClass: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func,
  showFullQuote: PropTypes.bool,
  appStore: PropTypes.object,
  onProceedToFinanceNavigator: PropTypes.func,
  onRequote: PropTypes.func
};

export default withTranslation('Quoting')(inject(['appStore'])(observerForHooks(QuotesListTable)));
