import { Trans, useTranslation } from 'react-i18next';
import Modal from '~/components/Common/Modal/Modal';
import FormatAPR from '../components/FormatAPR';
import MoneyFormat from '~/components/Common/MoneyFormat';
import {
  FNEligibilityContentType,
  HeaderFigureLabel,
  HeaderFigureLabelType
} from '~/components/QuoteCard/components/HeaderFigures/types';
type FinanceNavigatorQuoteInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedFigure: HeaderFigureLabelType | null;
  fnEligibilityContent: FNEligibilityContentType;
};

/* Currently handles scenarios Rate For Risk and Max Monthly Budget*/
const FinanceNavigatorQuoteInfoModal = ({
  isOpen,
  onClose,
  selectedFigure,
  fnEligibilityContent
}: FinanceNavigatorQuoteInfoModalProps) => {
  const { t } = useTranslation('QuoteCard');
  const { fnLenderData, fnOriginalFiguresComparisonData } = fnEligibilityContent;
  let originalFigure = '';
  let adjustedFigure = '';
  let maxMonthly;

  const canDisplayAPRContent =
    selectedFigure === HeaderFigureLabel.Apr && fnOriginalFiguresComparisonData?.['Apr']?.originalValue;
  const canDisplayMaxMonthlyContent =
    selectedFigure === HeaderFigureLabel.MonthlyPayment && fnLenderData?.MaxMonthlyBudget;

  // Rate For Risk scenario - PreApprovalType: 'RateForRisk'
  if (canDisplayAPRContent) {
    const { originalValue = null, currentValue = null } = fnOriginalFiguresComparisonData['Apr'] || {};
    originalFigure = originalValue?.toString() ?? '';
    adjustedFigure = currentValue?.toString() ?? '';
  }

  // Max Monthly Budget scenario - PreApprovalType: 'EligibilityCheck'
  if (canDisplayMaxMonthlyContent) {
    maxMonthly = fnLenderData.MaxMonthlyBudget;
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      icon="information"
      id="fnInformationModal"
      title={
        canDisplayMaxMonthlyContent
          ? t('FinanceNavigatorQuoteInfoModal.alternate_monthly_payment')
          : canDisplayAPRContent
          ? t('FinanceNavigatorQuoteInfoModal.a_new_figure')
          : ''
      }
    >
      <>
        {canDisplayMaxMonthlyContent && (
          <Trans
            ns="QuoteCard"
            i18nKey={'FinanceNavigatorQuoteInfoModal.max_monthly_payment_text'}
            components={{
              span: <span key={`importantFigure-${selectedFigure}`} className="quoteCard__importantFigure" />,
              maxMonthlyPayment: <MoneyFormat key={`adjustedFigure-${selectedFigure}`} value={maxMonthly} />
            }}
          />
        )}

        {canDisplayAPRContent && (
          <Trans
            ns="QuoteCard"
            i18nKey={'FinanceNavigatorQuoteInfoModal.please_note_change'}
            components={{
              span: <span key={`importantFigure-${selectedFigure}`} className="quoteCard__importantFigure" />,
              original: <FormatAPR key={`originalFigure-${selectedFigure}`} value={originalFigure} />,
              adjusted: <FormatAPR key={`adjustedFigure-${selectedFigure}`} value={adjustedFigure} />
            }}
          />
        )}
      </>
    </Modal>
  );
};

export default FinanceNavigatorQuoteInfoModal;
