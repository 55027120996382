import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import classnames from 'classnames';
import appStore from '../../mobx-stores/appStore';
import TextInput from '../Common/Form/TextInput';
import Button from '../Common/Button/Button';
import observerForHooks from '~/hocs/observerForHooks';
import { useVehicleLookup } from '~/hooks/useVrmLookup';
import './vrmLookup.scss';

type AppStoreType = typeof appStore;

type VrmLookupInputProps = {
  id: string;
  value: string;
  onChange: (id: string, value: string) => void;
  onFetchVehicle: () => void;
  onFetchVehicleSuccess: (data: unknown) => void;
  onFetchVehicleError: () => void;
  onVrmBlur: (name: string, value: string) => void;
  onBlur?: (name: string, value: string) => void; // This is used by the MobxFieldGroup parent component
  triggerError?: () => void; // This is used by the MobxFieldGroup parent component
  shouldUseQuickQuoteLogic?: boolean;
  isValid?: boolean;
  showError?: boolean;
  lookupVrm?: boolean;
  disabled: boolean;
  dealershipId: string;
  showLookupButton: boolean;
  tryAlgoliaLookup: boolean;
  appStore?: AppStoreType;
  t: TFunction;
};

const VrmLookupInput = ({
  id,
  value,
  onChange = (_, value) => {},
  onFetchVehicle = () => {},
  onFetchVehicleSuccess = (data) => {},
  onFetchVehicleError = () => {},
  onVrmBlur = () => {},
  onBlur = () => {},
  triggerError = () => {},
  shouldUseQuickQuoteLogic = false,
  tryAlgoliaLookup = false,
  isValid,
  showError,
  disabled,
  lookupVrm,
  dealershipId,
  showLookupButton,
  appStore,
  t
}: VrmLookupInputProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { lookupVehicle } = useVehicleLookup({
    checkAlgolia: tryAlgoliaLookup,
    checkVrmLookup: appStore?.uiState.isUk ?? true,
    dealershipId,
    shouldUseQuickQuoteLogic
  });

  const fetchVehicleLookup = useCallback(async () => {
    onFetchVehicle();
    if (!value) {
      return;
    }
    setHasError(false);
    triggerError();
    setIsLoading(true);

    try {
      const vehicle = await lookupVehicle(value);
      onFetchVehicleSuccess(vehicle);
    } catch (e) {
      setHasError(true);
      onFetchVehicleError();
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setHasError(false);
      }, 500);
    }
  }, [lookupVehicle, onFetchVehicle, onFetchVehicleError, onFetchVehicleSuccess, triggerError, value]);

  useEffect(() => {
    if (lookupVrm) {
      fetchVehicleLookup();
    }
  }, [lookupVrm, fetchVehicleLookup]);

  const handleKeyDown = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (showLookupButton) {
        fetchVehicleLookup();
      }
    }
  };

  const lookupInputClasses = classnames('vrmLookup__input', !showLookupButton && 'vrmLookup__input--noButton');

  return (
    <div className="vrmLookup">
      <div className={lookupInputClasses}>
        <TextInput
          id={id}
          value={value}
          onChange={(id: string, value: string) => {
            onChange(id, value.toUpperCase());
          }}
          onKeyDown={handleKeyDown}
          onBlur={(fieldName: string, fieldValue: string) => {
            onVrmBlur(fieldName, value); // We need a second on blur handler to actually communicate with the parent component
            onBlur(fieldName, fieldValue); // This is used by the MobxFieldGroup parent component
          }}
          isValid={isValid}
          showError={showError}
          maxLength={17}
          disabled={disabled}
          dataThook={showLookupButton ? 'Vrm' : 'RegNumber'}
        />
      </div>
      {showLookupButton && (
        <div className="vrmLookup__button">
          <Button
            id="VrmLookup"
            type="button"
            isLoading={isLoading}
            hasError={hasError}
            onClick={fetchVehicleLookup}
            disabled={disabled || !(value && value.length)}
            dataThook="VrmLookup"
          >
            {t('AddVehicle.lookup')}
          </Button>
        </div>
      )}
    </div>
  );
};
export default withTranslation('CustomerList')(inject('appStore')(observerForHooks(VrmLookupInput)));
