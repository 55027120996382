import { useEffect, useRef, useState } from 'react';
import {
  FinanceNavigatorForm,
  FinanceNavigatorFormAddressHistory,
  FinanceNavigatorFormAffordabilityDetails,
  FinanceNavigatorFormEmploymentHistory,
  FinanceNavigatorFormPersonalDetails,
  FinanceNavigatorFormProgress
} from '../types/financeNavigatorForm';
import { countItemsInObject } from '~/core/helpers';
import {
  addressHistoryValidator,
  affordabilityDetailsValidator,
  employmentHistoryValidator,
  FinanceNavigatorValidator,
  personalDetailsValidator
} from '../forms/validation';
import objectValidation from '~/core/objectValidation';

export const getInitialFormData = (): FinanceNavigatorForm => ({
  PersonalDetails: {
    Title: '',
    FirstName: '',
    MiddleNames: '',
    LastName: '',
    Email: '',
    MobileTelephone: '',
    DateOfBirth: '',
    MaritalStatus: '',
    NoOfDependants: ''
  },
  AddressHistory: {
    Items: [
      {
        BuildingName: '',
        BuildingNumber: '',
        SubBuilding: '',
        Street: '',
        District: '',
        PostTown: '',
        Postcode: '',
        Residency: '',
        TimeAtAddress: { Months: 0, Years: 0, TotalMonths: 0 }
      }
    ],
    TotalMonths: 0
  },
  EmploymentHistory: {
    Items: [
      {
        OccupationBasis: '',
        OccupationType: '',
        TimeAtEmployment: {
          Months: 0,
          TotalMonths: 0,
          Years: 0
        }
      }
    ],
    TotalMonths: 0
  },
  AffordabilityDetails: {
    Income: {
      GrossAnnual: '',
      NetMonthly: '',
      OtherHouseholdIncome: ''
    },
    MonthlyExpenditure: {
      MortgageOrRental: '',
      Other: ''
    },
    PersonalCircumstances: {
      ForeseenYearlyIncome: '',
      DownturnReason: ''
    },
    ReplacementLoan: {
      DetailsOfReplacementLoan: ''
    }
  }
});

const initialFormProgress: FinanceNavigatorFormProgress = {
  PersonalDetails: 0,
  AddressHistory: 0,
  EmploymentHistory: 0,
  AffordabilityDetails: 0,
  Total: 0
};

function calculateProgress<T>(data: T, initialData: T, validators: FinanceNavigatorValidator<T>) {
  const requiredFields = objectValidation(initialData, validators);
  if (!requiredFields) return 100;

  const missingFields = objectValidation(data, validators);
  if (!missingFields) return 100;

  const missingFieldCount = countItemsInObject(missingFields);
  const requiredFieldCount = countItemsInObject(requiredFields);

  return Math.max(Math.floor(((requiredFieldCount - missingFieldCount) / requiredFieldCount) * 100), 0);
}

function calculateTotalProgress(progress: FinanceNavigatorFormProgress) {
  const { Total, ...sections } = progress;
  const numberOfSections = Object.keys(sections).length;
  const sumOfAllProgress = Object.values(sections).reduce((curr, next) => curr + next, 0);
  return Math.floor(sumOfAllProgress / numberOfSections);
}

export const useFinanceNavigatorForm = () => {
  const initialFormData = useRef(getInitialFormData());
  const [formData, setFormData] = useState(initialFormData.current);
  const [progress, setProgress] = useState(initialFormProgress);
  const { PersonalDetails, AddressHistory, EmploymentHistory, AffordabilityDetails } = formData;

  useEffect(() => {
    setProgress((prevState: FinanceNavigatorFormProgress) => {
      const newState = {
        ...prevState,
        PersonalDetails: calculateProgress(
          PersonalDetails,
          initialFormData.current.PersonalDetails,
          personalDetailsValidator
        )
      };
      newState.Total = calculateTotalProgress(newState);
      return newState;
    });
  }, [PersonalDetails, initialFormData]);

  useEffect(() => {
    setProgress((prevState: FinanceNavigatorFormProgress) => {
      const newState = {
        ...prevState,
        AddressHistory: calculateProgress(
          AddressHistory,
          initialFormData.current.AddressHistory,
          addressHistoryValidator
        )
      };
      newState.Total = calculateTotalProgress(newState);
      return newState;
    });
  }, [AddressHistory, initialFormData]);

  useEffect(() => {
    setProgress((prevState: FinanceNavigatorFormProgress) => {
      const newState = {
        ...prevState,
        EmploymentHistory: calculateProgress(
          EmploymentHistory,
          initialFormData.current.EmploymentHistory,
          employmentHistoryValidator
        )
      };
      newState.Total = calculateTotalProgress(newState);
      return newState;
    });
  }, [EmploymentHistory, initialFormData]);

  useEffect(() => {
    setProgress((prevState: FinanceNavigatorFormProgress) => {
      const newState = {
        ...prevState,
        AffordabilityDetails: calculateProgress(
          AffordabilityDetails,
          initialFormData.current.AffordabilityDetails,
          affordabilityDetailsValidator
        )
      };
      newState.Total = calculateTotalProgress(newState);
      return newState;
    });
  }, [AffordabilityDetails, initialFormData]);

  const setPersonalDetails = (personalDetails: FinanceNavigatorFormPersonalDetails) => {
    setFormData((prevState: FinanceNavigatorForm) => ({ ...prevState, PersonalDetails: personalDetails }));
  };

  const setAddressHistory = (addressHistory: FinanceNavigatorFormAddressHistory) => {
    setFormData((prevState: FinanceNavigatorForm) => ({ ...prevState, AddressHistory: addressHistory }));
  };

  const setEmploymentHistory = (employmentHistory: FinanceNavigatorFormEmploymentHistory) => {
    setFormData((prevState: FinanceNavigatorForm) => ({ ...prevState, EmploymentHistory: employmentHistory }));
  };

  const setAffordabilityDetails = (affordabilityDetails: FinanceNavigatorFormAffordabilityDetails) => {
    setFormData((prevState: FinanceNavigatorForm) => ({ ...prevState, AffordabilityDetails: affordabilityDetails }));
  };

  return { formData, progress, setPersonalDetails, setAddressHistory, setEmploymentHistory, setAffordabilityDetails };
};
