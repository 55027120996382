import TabContent from '~/components/Common/Tabs/TabContent';
import TabList from '~/components/Common/Tabs/TabList';
import Tabs from '~/components/Common/Tabs/Tabs';
import QuoteCard from '~/components/QuoteCard/QuoteCard';
import { FixLater } from '~/types/basic';
import { QuoteCardVariantTypeEnum } from '~/components/QuoteCard/types';
import { useState } from 'react';
import FinanceNavigatorResultTab from './FinanceNavigatorResultTab';
import { useParams } from 'react-router-dom';
import { useNavigate } from '~/hooks/useNavigate';

type FinanceNavigatorResultsProps = {
  financeScans: FixLater;
  vehicle: FixLater;
  customerType: FixLater;
  proceedingState: string;
  handleProceedToApplication: FixLater;
};
const FinanceNavigatorResults = ({
  financeScans,
  vehicle,
  customerType,
  proceedingState,
  handleProceedToApplication
}: FinanceNavigatorResultsProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { dealershipId, consumerId } = useParams();
  const navigate = useNavigate();

  const handleRequote = () => {
    const reQuoteUrl = `/d/${dealershipId}/consumers/${consumerId}/vehicle/${vehicle.VehicleId}/quote`;
    navigate(reQuoteUrl);
  };

  const handleProceed = (quote: FixLater) => {
    handleProceedToApplication(vehicle, quote, customerType?.toLowerCase());
  };

  return financeScans && financeScans.length > 0 ? (
    <div>
      <Tabs currentTab={currentTab} setActiveTab={(index) => setCurrentTab(index)}>
        <TabList tabStyle="largeCfc">
          {financeScans.map((financeScan: FixLater, index: number) => (
            <FinanceNavigatorResultTab financeScan={financeScan} key={`fnsti${index}`} />
          ))}
        </TabList>
        <TabContent border={false}>
          {financeScans?.map((financeScan: FixLater, index: number) => {
            return (
              <div className="carFinanceChecks" key={financeScan.Id}>
                <div key={`checkDiv${index}`}>
                  <div className="carFinanceChecks" data-th="finance-navigator-scan-tab-results">
                    {financeScan?.Results?.map((quote: FixLater) => (
                      <QuoteCard
                        variant={QuoteCardVariantTypeEnum.SavedFinanceNavigatorCheck}
                        quote={quote}
                        chosenQuoteId={quote.QuoteId}
                        vehicleClass={vehicle.VehicleClass}
                        actions={{
                          onProceed: () => handleProceed(quote),
                          onRequote: handleRequote
                        }}
                        proceedingState={proceedingState}
                        isQuickQuote={false}
                        isCustomerQuote={false}
                        trackingPage={'CustomerFinanceNavigatorResults'}
                        key={`FNScanResult-${quote.QuoteId}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </TabContent>
      </Tabs>
    </div>
  ) : null;
};

export default FinanceNavigatorResults;
