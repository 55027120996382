import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Common/Breadcrumbs';
import Page from '~/components/Common/Page';
import QuoteModuleContainer from '~/components/Quoting/containers/QuoteModuleContainer';
import { withRouter } from '~/hocs/router';
import { useNavigate } from '~/hooks/useNavigate';
import { FixLater } from '~/types/basic';

const ApplicationRequote: React.FC<{
  application: any;
  vehicle: Record<string, unknown>;
  changeApplicationQuotePreSubmission: any;
  consumer: Record<string, unknown>;
}> = ({ consumer, application, changeApplicationQuotePreSubmission }) => {
  const { dealershipId, consumerId, applicantId } = useParams() as {
    dealershipId: string;
    consumerId: string;
    applicantId: string;
  };
  const { t } = useTranslation('Application');
  const navigate = useNavigate();

  const quoteRequest = {
    ...application.QuoteRequest,
    CustomerType: application.CustomerType,
    isRequote: true
  };

  const preApprovalData = application?.Agreements[0]?.PreApprovalData;

  const handleProceed = ({ quote, quoteRequest }: { quote: FixLater; quoteRequest: FixLater }) => {
    changeApplicationQuotePreSubmission({ quote, quoteRequest });
  };

  const handleCancel = () => {
    navigate({
      pathname: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`,
      query: {
        vehicleId: application.Vehicle.VehicleId
      }
    });
  };

  const handleEditExistingApplication = () => {
    navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
  };

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('ApplicationRequote.home'),
            path: `/d/${dealershipId}`
          },
          {
            name: t('ApplicationRequote.customer_list'),
            path: `/d/${dealershipId}/consumers`
          },
          {
            name: t('ApplicationRequote.consumer'),
            path: `/d/${dealershipId}/consumers/${consumerId}`
          },
          {
            name: t('ApplicationRequote.application_status'),
            path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
          },
          {
            name: t('ApplicationRequote.requote_application')
          }
        ]}
        consumer={consumer}
      />

      <QuoteModuleContainer
        vehicle={application.Vehicle}
        initialCosts={quoteRequest}
        onCancel={handleCancel}
        onProceed={handleProceed}
        proceedingState={application.applicationCloneState}
        onEditExistingApplication={handleEditExistingApplication}
        customerType={application.CustomerType}
        customerId={consumerId}
        currentProductUid={application.Quote.ProductId}
        newAPR={preApprovalData?.NewAPR}
        preApprovalData={preApprovalData}
      />
    </Page>
  );
};

export default withRouter(ApplicationRequote);
