import { useTranslation } from 'react-i18next';
import Modal from '~/components/Common/Modal/Modal';

const ModalOtherCommittedOutgoings = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t } = useTranslation('Application');
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('ModalOtherOutgoings.other_committed_monthly_outgoings_which_are_not_debt_related')}
    >
      <ModalOtherCommittedOutgoingsContent />
    </Modal>
  );
};

export const ModalOtherCommittedOutgoingsContent = () => {
  const { t } = useTranslation('Application');
  return (
    <div>
      <p>
        {t('ModalOtherOutgoings.your_monthly_share_of_childcare_costs_spousal_and_child_maintenance_and_school_fees')}
      </p>
      <div>{t('ModalOtherOutgoings.the_main_items_to_include_are')}</div>
      <ul>
        <li>{t('ModalOtherOutgoings.childcare_payments_for_the_regular_care_of_your_child_or_children')}</li>
        <li>{t('ModalOtherOutgoings.school_college_or_university_tuition_fees_that_youve_committed_to_pay')}</li>
        <li>
          {t(
            'ModalOtherOutgoings.child_maintenance_money_you_may_pay_to_support_children_you_have_with_a_previous_partner'
          )}
        </li>
        <li>{t('ModalOtherOutgoings.spousal_maintenance_money_you_may_pay_to_support_a_previous_partner')}</li>
      </ul>
      <div>{t('ModalOtherOutgoings.you_dont_need_to_include')}</div>
      <ul>
        <li>{t('ModalOtherOutgoings.occasional_childcare_costs_such_as_babysitting')}</li>
        <li>{t('ModalOtherOutgoings.any_other_childcare_expenditure_for_example_entertainment_food_clothes')}</li>
        <li>{t('ModalOtherOutgoings.any_student_loan_repayments')}</li>
      </ul>
    </div>
  );
};

export default ModalOtherCommittedOutgoings;
