import { useTranslation } from 'react-i18next';
import { lowercaseFirstLetter } from '~/components/Common/utils';
import { EligibilityOfferedType } from '../../types';
import { useState } from 'react';
import FinanceNavigatorEligibilityModal from '~/components/Quoting/modals/FinanceNavigatorEligibilityModal/FinanceNavigatorEligibilityModal';
import './quoteCardStatus.scss';

export interface FinanceNavigatorStatusProps {
  lenderMessages: string[] | null;
  status: EligibilityOfferedType;
}

const FinanceNavigatorStatus = ({ status, lenderMessages = null }: FinanceNavigatorStatusProps) => {
  const [isFNEligibilityModalOpen, setIsFNEligibilityModalOpen] = useState(false);

  const handleOpenFNEligibilityModal = () => {
    setIsFNEligibilityModalOpen(true);
  };

  const handleCloseFNEligibilityModal = () => {
    setIsFNEligibilityModalOpen(false);
  };
  const { t } = useTranslation('QuoteCard');
  return (
    <div
      className={`quoteCard__cell quoteCardStatus quoteCardStatus--${lowercaseFirstLetter(status)}`}
      data-th="finance-navigator-status"
    >
      <span className="quoteCardStatus__statusText">{t(`FinanceNavigatorStatus.${lowercaseFirstLetter(status)}`)}</span>

      <button
        className="quoteCardStatus__viewDetails"
        onClick={handleOpenFNEligibilityModal}
        data-th="financeNavigatorStatus__viewDetailsButton"
      >
        {t('ApplicationStatus.view_details')}
      </button>

      <FinanceNavigatorEligibilityModal
        isOpen={isFNEligibilityModalOpen}
        onClose={handleCloseFNEligibilityModal}
        status={status}
        lenderMessages={lenderMessages}
      />
    </div>
  );
};

export default FinanceNavigatorStatus;
