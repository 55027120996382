import * as actionTypes from '../actionTypes';
import _ from 'lodash';

export const defaultState = {
  Id: null,
  isLoading: false,
  error: false,
  VehicleSortFilters: {},
  createApplicantLoadingState: '',
  selectedVehicleId: null,
  selectedFinanceTab: null,
  selectedCfcCheckIndex: 0,
  selectedSentDealIndex: 0,
  lastModifiedTimestamp: null
};

export default function customerUiReducer(state = defaultState, action) {
  let newState;
  let selectedVehicleId;
  let selectedFinanceTab;

  switch (action.type) {
    case actionTypes.INIT_CUSTOMER_PAGE:
      newState = _.cloneDeep(state);
      newState.Id = action.customer.Id;

      const uiStateIsStale = state.lastModifiedTimestamp !== action.customer.LastModifiedTimestamp;

      if (action.query.selectLatestVehicle) {
        selectedVehicleId = action.customer.LatestVehicle;
      } else if (action.query.vehicleId) {
        selectedVehicleId = action.query.vehicleId;
      } else if (state.Id === action.customer.Id) {
        if (uiStateIsStale) {
          selectedVehicleId = action.customer.LatestVehicle;
        } else {
          selectedVehicleId = state.selectedVehicleId;
        }
      } else {
        selectedVehicleId = action.customer.LatestVehicle;
      }

      if (action.query.selectedFinanceTab && selectedVehicleId) {
        //LatestVehicle is undefined when customer has no vehicles
        selectedFinanceTab = action.query.selectedFinanceTab;
      } else if (state.Id === action.customer.Id) {
        if (uiStateIsStale) {
          selectedFinanceTab = _.chain(action.customer.VehiclesOfInterest)
            .find({ VehicleId: selectedVehicleId })
            .get('LatestUpdateOn')
            .value();
        } else {
          selectedFinanceTab = state.selectedFinanceTab;
        }
      } else {
        selectedFinanceTab = _.chain(action.customer.VehiclesOfInterest)
          .find({ VehicleId: selectedVehicleId })
          .get('LatestUpdateOn')
          .value();
      }

      newState.selectedVehicleId = selectedVehicleId;
      newState.selectedFinanceTab = selectedFinanceTab || 'FinanceApplications';
      newState.lastModifiedTimestamp = action.customer.LastModifiedTimestamp;

      _.each(action.customer.VehiclesOfInterest, (vehicle) => {
        newState.VehicleSortFilters[vehicle.VehicleId] = {
          FinanceApplications: {
            sortDirection: 'desc',
            sortField: 'LastModifiedTimestamp'
          },
          FinanceScans: {
            sortDirection: 'desc',
            sortField: 'CreatedDate'
          },
          CfcProspects: {
            sortDirection: 'desc',
            sortField: 'MatchRate'
          },
          SavedQuotes: {
            sortDirection: 'desc',
            sortField: 'SavedTime'
          },
          PushDeals: {
            sortDirection: 'desc',
            sortField: 'addedAt'
          }
        };
      });

      return newState;

    case actionTypes.SELECT_CUSTOMER_FINANCE_TAB:
      return Object.assign({}, state, { selectedFinanceTab: action.name, selectedCfcCheckIndex: 0 });

    case actionTypes.SELECT_CUSTOMER_VEHICLE:
      newState = _.cloneDeep(state);
      newState.selectedFinanceTab = action.vehicle.LatestUpdateOn;
      newState.selectedVehicleId = action.vehicle.VehicleId;
      newState.selectedCfcCheckIndex = 0;
      return newState;

    case actionTypes.SELECT_CUSTOMER_CFC_CHECK:
      newState = _.cloneDeep(state);
      newState.selectedCfcCheckIndex = action.selectedCfcCheckIndex;
      return newState;

    case actionTypes.SELECT_CUSTOMER_SENT_DEAL:
      newState = _.cloneDeep(state);
      newState.selectedSentDealIndex = action.selectedSentDealIndex;
      return newState;

    case actionTypes.CREATE_APPLICANT_FROM_CFC:
      return Object.assign({}, state, { createApplicantLoadingState: 'loading' });

    case actionTypes.CREATE_APPLICANT_FROM_CFC_SUCCESS:
      return Object.assign({}, state, { createApplicantLoadingState: 'done' });

    case actionTypes.CREATE_APPLICANT_FROM_CFC_ERROR:
      return Object.assign({}, state, { createApplicantLoadingState: 'error' });

    case actionTypes.ADD_COMMENT_TO_CONSUMER:
      return Object.assign({}, state, { addCommentState: 'loading' });

    case actionTypes.ADD_COMMENT_TO_CONSUMER_SUCCESS:
      return Object.assign({}, state, { addCommentState: 'done' });

    case actionTypes.ADD_COMMENT_TO_CONSUMER_ERROR:
      return Object.assign({}, state, { addCommentState: 'error' });

    case actionTypes.SORT_CUSTOMER_SECTION:
      newState = _.cloneDeep(state);

      let sortFilter = _.get(newState, `VehicleSortFilters[${action.vehicleId}][${action.section}]`, {});
      let direction;

      if (sortFilter.sortField === action.field) {
        direction = sortFilter.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        direction = 'asc';
      }

      _.set(newState, `VehicleSortFilters[${action.vehicleId}][${action.section}]`, {
        sortField: action.field,
        sortDirection: direction
      });

      return newState;

    case actionTypes.ADD_CONSUMER_VEHICLE_SUCCESS:
      newState = _.cloneDeep(state);

      _.set(newState, `VehicleSortFilters[${action.vehicle.VehicleId}]`, {
        FinanceApplications: {
          sortDirection: 'asc',
          sortField: 'Quote.FollowingPayments'
        },
        CfcProspects: {
          sortDirection: 'desc',
          sortField: 'MatchRate'
        },
        SavedQuotes: {
          sortDirection: 'asc',
          sortField: 'Quote.TotalAmountPayable'
        }
      });
      return newState;

    default:
      return state;
  }
}
