import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import QuoteModule from '../../Quoting/containers/QuoteModuleContainer';
import { inject, observer } from 'mobx-react';
import { compose } from 'redux';
import i18n from '../../../i18n';
import { withNavigate, withOutletContextProps, withParams, withQuery } from '../../../hocs/router';
class QuickQuoteResults extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      defaultValues: this.getDefaultValues(this.props.vehicle)
    });
  }

  getDefaultValues(vehicle) {
    let query = this.props.query;

    if (!vehicle) {
      return {};
    }

    let settings =
      _.chain(this.props.session.Dealerships)
        .find({ Id: this.props.params.dealershipId })
        .get('Settings')
        .value() || {};

    const defaultVaps = this.props.appStore.vapStore.getVisibleDefaultProductsClassFiltered(vehicle.Class);

    return {
      CashDeposit: !query.cashDeposit || query.cashDeposit === '0' ? settings.DepositValue : query.cashDeposit,
      Term: query.term || settings.PreferredTerm || 48,
      VehiclePrice: query.vehiclePrice || vehicle.VehiclePrice,
      AnnualDistance: query.AnnualDistance || settings.DefaultAnnualMileage || 10000,
      ValueAddedProducts: defaultVaps
    };
  }

  saveQuote = (quote) => {
    this.props.openSaveQuoteModal(quote, this.props.customerType);
  };

  handleProceed = ({ quote, quoteRequest, customerId, vehicleId }) => {
    const { customerType } = this.props;
    this.props.onProceed({ quote, quoteRequest, customerId, vehicleId, customerType });
  };

  handleCancel = () => {
    this.props.navigate(-1);
  };

  render() {
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: 'Home',
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: i18n.t('QuickQuoteBreadcrumb.quick_quote', {
                ns: 'QuickQuote'
              })
            }
          ]}
        />
        <QuoteModule
          vehicle={this.props.vehicle}
          initialCosts={this.state.defaultValues}
          onCancel={this.handleCancel}
          onProceed={this.handleProceed}
          enableShowroomCfc={this.props.enableShowRoomCfc}
          enableSendDeal={this.props.enableSendDeal}
          onSave={this.saveQuote}
          isQuickQuote={true}
          onSendDeal={this.props.onSendDeal}
          customerType={this.props.customerType}
        />
      </Page>
    );
  }
}

QuickQuoteResults.propTypes = {
  params: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  appStore: PropTypes.object,
  vehicle: PropTypes.object,
  onProceed: PropTypes.func,
  session: PropTypes.object,
  enableShowRoomCfc: PropTypes.bool,
  openSaveQuoteModal: PropTypes.func,
  onSendDeal: PropTypes.func,
  enableSendDeal: PropTypes.bool,
  customerType: PropTypes.string
};

export default compose(
  withNavigate,
  withParams,
  withQuery,
  withOutletContextProps,
  inject(['appStore']),
  observer
)(QuickQuoteResults);
